import React from "react";

// Customizable Area Start
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import { styled } from '@material-ui/core/styles';
import { DocLibrary, FilledDocLibrary, lawfirm, lawyers, logo,project,secondlogo,services,taskChat,userpic} from "./assets";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Box, Typography} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import { PayoutIcon } from "../../collecttransactionfees/src/components/Sidebar.web";

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawer=()=>( 
    
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' , width: this.state.openCollapse ? "252px" : "89px", transition: "width 0.3s ease", borderRadius:'0px 22px 22px 0px',  boxShadow: "0px 2px 8px 0px #01175F14" }}>
        <div className="toolbar" />
        <Divider />
        <div style={{display:'flex', justifyContent:'end', margin:"10px 10px 0px 0px "}}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-test-id="collapseTest" onClick={() => {
          this.setState(prevState => ({
            openCollapse: !prevState.openCollapse
          }));
        }}
        >
          <path d="M2.33056 7L7.51415 0.376953C7.59423 0.275391 7.52197 0.125 7.39111 0.125H5.88134C5.78564 0.125 5.69384 0.169922 5.63525 0.244141L0.648918 6.61523C0.562561 6.72527 0.515625 6.8611 0.515625 7.00098C0.515625 7.14085 0.562561 7.27668 0.648918 7.38672L5.63525 13.7559C5.69384 13.832 5.78564 13.875 5.88134 13.875H7.39111C7.52197 13.875 7.59423 13.7246 7.51415 13.623L2.33056 7ZM8.26806 7L13.4517 0.376953C13.5317 0.275391 13.4595 0.125 13.3286 0.125H11.8188C11.7231 0.125 11.6313 0.169922 11.5727 0.244141L6.58642 6.61523C6.50006 6.72527 6.45313 6.8611 6.45313 7.00098C6.45313 7.14085 6.50006 7.27668 6.58642 7.38672L11.5727 13.7559C11.6313 13.832 11.7231 13.875 11.8188 13.875H13.3286C13.4595 13.875 13.5317 13.7246 13.4517 13.623L8.26806 7Z" fill="#BFBFBF" />
        </svg>

        </div>
        {this.logoBox()}
           {this.state.mobileOpen && <CloseIcon style={{position:'fixed',top:'30px',left:'210px',cursor:'pointer'}} data-test-id="closeIcon" onClick={this.handleclosesidebar}/>}
           {this.actionSection()}
        
        {this.managementDrawer()}
       {this.profileSection()}
        
      </DrawerBox>
  )

  actionSection =()=>{
    return(
      <>
      <Typography style={{marginLeft:"22px", color:'#BFBFBF' , fontFamily:'DM Sans', marginBottom:"10px", marginTop:'12px'}}>Actions</Typography>
        <List className="MainListBox">
          <ListItem  className={
  this.state.currentPath !== "/payment"&&this.state.currentPath !== "/documentation"   &&this.state.currentPath !== "/lawfirm-management" && this.state.currentPath !== "/admin-lawyer-management"
  &&this.state.currentPath !== "/services-management" &&this.state.currentPath !== "/invoicing-payouts" && this.state.currentPath !== "/task-list" && this.state.currentPath !== "/tasklist-lawfirm"&&  this.state.currentPath !== "/Task" && this.state.currentPath !== "/live-chat"
    ? "listbox1"
    : "listbox"
}
 data-test-id="dashbordIntest" onClick={this.handleDashboardIn} >
          <ListItemIcon style={{ minWidth: 0 ,marginBottom:"5px"}}>
  {this.state.currentPath !== "/payment"   &&this.state.currentPath !== "/invoicing-payouts"&&this.state.currentPath !== "/documentation" 
  &&this.state.currentPath !== "/lawfirm-management" && this.state.currentPath !== "/admin-lawyer-management"
  &&this.state.currentPath !== "/services-management"  &&  this.state.currentPath !== "/task-list" && this.state.currentPath !== "/tasklist-lawfirm"  && this.state.currentPath !== "/Task" && this.state.currentPath !== "/live-chat" ? ( <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1828 10.8363L11.5289 1.18943C11.4594 1.11979 11.3768 1.06453 11.2859 1.02683C11.1951 0.989132 11.0976 0.969727 10.9992 0.969727C10.9008 0.969727 10.8034 0.989132 10.7125 1.02683C10.6216 1.06453 10.539 1.11979 10.4695 1.18943L0.815625 10.8363C0.534375 11.1176 0.375 11.4996 0.375 11.898C0.375 12.7254 1.04766 13.398 1.875 13.398H2.89219V20.2816C2.89219 20.6965 3.22734 21.0316 3.64219 21.0316H9.49922V15.7816H12.1242V21.0316H18.3563C18.7711 21.0316 19.1063 20.6965 19.1063 20.2816V13.398H20.1234C20.5219 13.398 20.9039 13.241 21.1852 12.9574C21.7687 12.3715 21.7687 11.4222 21.1828 10.8363Z" fill="#355EE7"/>
    </svg>
  ) : (
    <svg width="22" height="22" viewBox="0 0 22 22" className="icondrawer2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M21.1839 10.836L12.1276 1.78443L11.5206 1.1774C11.3823 1.04 11.1952 0.962891 11.0003 0.962891C10.8053 0.962891 10.6183 1.04 10.48 1.1774L0.816699 10.836C0.674975 10.9772 0.562968 11.1453 0.48729 11.3305C0.411611 11.5156 0.373795 11.7141 0.376074 11.9141C0.385449 12.7391 1.07217 13.3977 1.89717 13.3977H2.89326V21.0313H19.1073V13.3977H20.1245C20.5253 13.3977 20.9026 13.2407 21.1862 12.9571C21.3259 12.8179 21.4365 12.6524 21.5117 12.4701C21.587 12.2879 21.6253 12.0925 21.6245 11.8954C21.6245 11.4969 21.4675 11.1196 21.1839 10.836ZM12.3128 19.3438H9.68779V14.5626H12.3128V19.3438ZM17.4198 11.7102V19.3438H13.8128V14.0001C13.8128 13.4821 13.3933 13.0626 12.8753 13.0626H9.12529C8.60732 13.0626 8.18779 13.4821 8.18779 14.0001V19.3438H4.58076V11.7102H2.33076L11.0026 3.04537L11.544 3.58678L19.6722 11.7102H17.4198Z" />
</svg>

  )}
</ListItemIcon>


            {this.state.openCollapse && (<Typography className={
              this.state.currentPath !== "/payment"&&
              this.state.currentPath !== "/documentation"  &&this.state.currentPath !== "/lawfirm-management" && this.state.currentPath !== "/admin-lawyer-management"
              &&this.state.currentPath !== "/services-management" 

               && this.state.currentPath !== "/invoicing-payouts" && this.state.currentPath !== "/tasklist-lawfirm"   && this.state.currentPath !== "/task-list" && this.state.currentPath !== "/Task" && this.state.currentPath !== "/live-chat" ? "listtile1" : "listtile"
              } data-test-id="Dashboard">
              Dashboard
            </Typography>)}
          </ListItem>
          <ListItem className = {this.state.currentPath !== "/task-list"    &&  this.state.currentPath !== "/Task"&& this.state.currentPath !== "/tasklist-lawfirm"
    ? "listbox"
    : "listbox1"
} data-test-id="taskClickTest" onClick={this.handleTaskIn}>
            <ListItemIcon style={{ minWidth: 0 }} >
              {this.state.currentPath !== "/task-list" && this.state.currentPath !== "/Task" ? (
              <svg width="18" className="icondrawer2"
              height="22" viewBox="0 0 18 22" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0297 5.76406L11.9859 0.720313C11.8453 0.579688 11.6555 0.5 11.4562 0.5H1.5C1.08516 0.5 0.75 0.835156 0.75 1.25V20.75C0.75 21.1648 1.08516 21.5 1.5 21.5H16.5C16.9148 21.5 17.25 21.1648 17.25 20.75V6.29609C17.25 6.09687 17.1703 5.90469 17.0297 5.76406ZM15.5203 6.64062H11.1094V2.22969L15.5203 6.64062ZM15.5625 19.8125H2.4375V2.1875H9.51562V7.25C9.51562 7.51107 9.61934 7.76145 9.80394 7.94606C9.98855 8.13066 10.2389 8.23438 10.5 8.23438H15.5625V19.8125ZM8.8125 13.4844H4.5C4.39687 13.4844 4.3125 13.5688 4.3125 13.6719V14.7969C4.3125 14.9 4.39687 14.9844 4.5 14.9844H8.8125C8.91562 14.9844 9 14.9 9 14.7969V13.6719C9 13.5688 8.91562 13.4844 8.8125 13.4844ZM4.3125 10.4844V11.6094C4.3125 11.7125 4.39687 11.7969 4.5 11.7969H13.5C13.6031 11.7969 13.6875 11.7125 13.6875 11.6094V10.4844C13.6875 10.3813 13.6031 10.2969 13.5 10.2969H4.5C4.39687 10.2969 4.3125 10.3813 4.3125 10.4844Z"
              />
            </svg>
                
              ) : (
              <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z" fill="#355EE7"/>
              </svg>)}          

            </ListItemIcon>
            {this.state.openCollapse && ( 
              <Typography className={this.state.currentPath !== "/task-list"  && this.state.currentPath !== "/tasklist-lawfirm" && this.state.currentPath !== "/Task" ? "listtile" : "listtile1"} data-test-id="task">
              Tasks
            </Typography>)}
          </ListItem>
          {this.actionSection1()}
          {this.actionSection2()}
         
        </List>
      </>
    )
  }

  actionSection1 =()=>{
    return(
      <ListItem className= {this.state.currentPath !== "/live-chat"
        ? "listbox"
        : "listbox1"
    } onClick={this.handleRedirectLiveChat} >
                <ListItemIcon  style={{ minWidth: 0 }}>
                  {this.state.currentPath !== "/live-chat"?(
                       <svg width="24" height="24"  className="icondrawer2"  viewBox="0 0 24 24"fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0653 14.2502C13.0653 14.5642 13.19 14.8653 13.4121 15.0874C13.6341 15.3094 13.9352 15.4341 14.2492 15.4341C14.5632 15.4341 14.8643 15.3094 15.0864 15.0874C15.3084 14.8653 15.4331 14.5642 15.4331 14.2502C15.4331 13.9362 15.3084 13.6351 15.0864 13.413C14.8643 13.191 14.5632 13.0663 14.2492 13.0663C13.9352 13.0663 13.6341 13.191 13.4121 13.413C13.19 13.6351 13.0653 13.9362 13.0653 14.2502ZM16.7483 14.2502C16.7483 14.5642 16.873 14.8653 17.0951 15.0874C17.3171 15.3094 17.6182 15.4341 17.9322 15.4341C18.2462 15.4341 18.5473 15.3094 18.7694 15.0874C18.9914 14.8653 19.1161 14.5642 19.1161 14.2502C19.1161 13.9362 18.9914 13.6351 18.7694 13.413C18.5473 13.191 18.2462 13.0663 17.9322 13.0663C17.6182 13.0663 17.3171 13.191 17.0951 13.413C16.873 13.6351 16.7483 13.9362 16.7483 14.2502ZM9.38231 14.2502C9.38231 14.5642 9.50705 14.8653 9.72907 15.0874C9.9511 15.3094 10.2522 15.4341 10.5662 15.4341C10.8802 15.4341 11.1814 15.3094 11.4034 15.0874C11.6254 14.8653 11.7501 14.5642 11.7501 14.2502C11.7501 13.9362 11.6254 13.6351 11.4034 13.413C11.1814 13.191 10.8802 13.0663 10.5662 13.0663C10.2522 13.0663 9.9511 13.191 9.72907 13.413C9.50705 13.6351 9.38231 13.9362 9.38231 14.2502Z" />
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7991 14.2885C22.8048 13.1267 22.5796 11.9984 22.135 10.9374C21.7037 9.91269 21.0851 8.99228 20.2974 8.20265C19.5146 7.417 18.5853 6.79249 17.5622 6.36445C16.5122 5.92346 15.3973 5.7002 14.2492 5.7002L14.2124 5.7002L14.2109 5.7002C13.0558 5.70592 11.9352 5.93479 10.8812 6.38539C9.86681 6.81779 8.94623 7.44342 8.17077 8.2274C7.39075 9.01509 6.77776 9.93153 6.35386 10.9531C5.91494 12.0103 5.69361 13.1346 5.69933 14.2904C5.70568 15.5916 6.00809 16.8741 6.58324 18.0407V20.7692C6.58324 21.0734 6.70409 21.3652 6.91921 21.5803C7.13433 21.7954 7.4261 21.9163 7.73033 21.9163H10.4606C11.6272 22.4914 12.9097 22.7938 14.2109 22.8002H14.2511C15.3934 22.8002 16.5025 22.5788 17.5464 22.1456C18.5644 21.7227 19.4902 21.1056 20.2721 20.3286C21.0601 19.5482 21.6807 18.6355 22.114 17.618C22.5646 16.5641 22.7934 15.4436 22.7991 14.2885ZM11.1714 20.0349C12.1018 20.5307 13.1569 20.7953 14.2188 20.8006H14.2492C15.9935 20.8006 17.6301 20.1282 18.8646 18.906C20.1042 17.6769 20.7926 16.0349 20.8033 14.2799C20.8067 13.388 20.6358 12.5256 20.2944 11.7126C19.9652 10.9275 19.4901 10.2216 18.8857 9.6173C18.2796 9.01116 17.5754 8.53772 16.7903 8.20853C15.986 7.8706 15.1324 7.69975 14.2511 7.69975H14.2204C12.4638 7.70695 10.8222 8.39364 9.59335 9.6348C8.36263 10.8761 7.69186 12.5231 7.69886 14.2801C7.70416 15.3422 7.96868 16.3975 8.46464 17.328L8.58278 17.5486V19.9167H10.9509L11.1714 20.0349Z"/>
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0653 14.2502C13.0653 14.5642 13.19 14.8653 13.4121 15.0874C13.6341 15.3094 13.9352 15.4341 14.2492 15.4341C14.5632 15.4341 14.8643 15.3094 15.0864 15.0874C15.3084 14.8653 15.4331 14.5642 15.4331 14.2502C15.4331 13.9362 15.3084 13.6351 15.0864 13.413C14.8643 13.191 14.5632 13.0663 14.2492 13.0663C13.9352 13.0663 13.6341 13.191 13.4121 13.413C13.19 13.6351 13.0653 13.9362 13.0653 14.2502ZM16.7483 14.2502C16.7483 14.5642 16.873 14.8653 17.0951 15.0874C17.3171 15.3094 17.6182 15.4341 17.9322 15.4341C18.2462 15.4341 18.5473 15.3094 18.7694 15.0874C18.9914 14.8653 19.1161 14.5642 19.1161 14.2502C19.1161 13.9362 18.9914 13.6351 18.7694 13.413C18.5473 13.191 18.2462 13.0663 17.9322 13.0663C17.6182 13.0663 17.3171 13.191 17.0951 13.413C16.873 13.6351 16.7483 13.9362 16.7483 14.2502ZM9.38231 14.2502C9.38231 14.5642 9.50705 14.8653 9.72907 15.0874C9.9511 15.3094 10.2522 15.4341 10.5662 15.4341C10.8802 15.4341 11.1814 15.3094 11.4034 15.0874C11.6254 14.8653 11.7501 14.5642 11.7501 14.2502C11.7501 13.9362 11.6254 13.6351 11.4034 13.413C11.1814 13.191 10.8802 13.0663 10.5662 13.0663C10.2522 13.0663 9.9511 13.191 9.72907 13.413C9.50705 13.6351 9.38231 13.9362 9.38231 14.2502Z" />
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76114 3.80066C2.97658 4.58805 2.36015 5.50397 1.93385 6.52503L3.76114 3.80066Z" />
                       <path d="M6.48627 1.96004C5.46647 2.39208 4.54088 3.01721 3.76114 3.80066C2.97658 4.58805 2.36015 5.50397 1.93385 6.52503C1.49238 7.58188 1.26974 8.70569 1.2755 9.86126C1.28192 11.1681 1.58886 12.4559 2.1725 13.6263V16.396C2.1725 16.6827 2.28711 16.9574 2.49066 17.1597C2.69417 17.362 2.9699 17.4754 3.25712 17.4754H5.25024C4.87524 16.8754 4.75562 16.1254 4.74531 15.6138H4.04274V13.167L3.93183 12.9612C3.42245 12.0114 3.15117 10.9351 3.14574 9.8516C3.13857 8.05957 3.82721 6.37869 5.09092 5.11193C6.35279 3.8452 8.039 3.14426 9.84304 3.13695H9.87435C10.7794 3.13695 11.6563 3.31141 12.4825 3.6564C12.8975 3.82932 13.3609 4.02475 13.8752 4.44323C14.9406 4.36472 15.471 4.39757 16.4608 4.50043C16.3749 4.3898 16.2751 4.27348 16.166 4.15489C15.9231 3.89097 15.6217 3.60267 15.2931 3.32117C14.64 2.7616 13.8605 2.21239 13.2033 1.9391C12.1477 1.49847 11.0269 1.27539 9.87249 1.27539L9.83511 1.27539L9.834 1.2754C8.67233 1.28111 7.54582 1.50983 6.48627 1.96004Z" />
                       </svg>
                  ):(
                    <img src={taskChat} alt="" />
                  )}
               
    
    
    
                </ListItemIcon>
                {this.state.openCollapse && (   <Typography className={this.state.currentPath !== "/live-chat"? "listtile":"listtile1"} data-test-id="task">
                Messages
                </Typography>)}
              </ListItem>
    )
  }

  actionSection2 =()=>{
    return(
      <>
      {this.state.clientToken && (
          <ListItem className="listbox" data-test-id="catalogRoutetest" onClick={this.handleCatalogRoute} >
            <ListItemIcon style={{ minWidth: 0 }}>
              <svg width="22" height="20" className="icondrawer2" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5 8.98913C21.5 8.95652 21.4674 8.82609 21.4348 8.72826L18.5978 2.76087C18.5326 2.56522 18.3043 2.40217 18.0761 2.40217H11.6522V0.934783C11.6522 0.543478 11.3587 0.25 10.9674 0.25C10.5761 0.25 10.2826 0.51087 10.2826 0.869565V2.36957H3.95652C3.76087 2.36957 3.53261 2.56522 3.43478 2.76087L0.565217 8.69565C0.532609 8.79348 0.5 8.95652 0.5 8.95652C0.5 10.913 2.06522 12.4783 4.02174 12.4783C5.97826 12.4783 7.54348 10.913 7.54348 8.95652C7.54348 8.92391 7.54348 8.79348 7.47826 8.69565L5.09783 3.70652H10.2826V18.1522H3.95652C3.95652 18.1522 3.95652 18.4457 3.95652 18.837C3.95652 19.2283 3.95652 19.5217 3.95652 19.5217H17.9783V18.837V18.1522H11.6522V3.70652H16.837L14.4565 8.69565C14.3913 8.79348 14.3913 8.95652 14.3913 8.95652C14.3913 10.913 15.9565 12.4783 17.913 12.4783C19.8696 12.5109 21.5 10.9457 21.5 8.98913ZM4.02174 4.61957L5.78261 8.30435H2.26087L4.02174 4.61957ZM4.02174 11.1413C3.07609 11.1413 2.26087 10.5217 1.96739 9.67391H6.04348C5.78261 10.5217 4.96739 11.1413 4.02174 11.1413ZM19.7391 8.30435H16.1848L17.9457 4.61957L19.7391 8.30435ZM17.9457 11.1413C17 11.1413 16.1848 10.5217 15.8913 9.67391H19.9674C19.7391 10.5217 18.8913 11.1413 17.9457 11.1413Z" />
              </svg>


            </ListItemIcon>
            {this.state.openCollapse && (  <Typography className="listtile" data-test-id="task">
              Catalog
            </Typography>)}
          </ListItem>
        )}
          <ListItem data-test-id="document_library"   onMouseEnter={() => this.setIsHovered(true)}
                onMouseLeave={() => this.setIsHovered(false)}  className="listbox22" onClick={this.handleDocumentFolderMenu}>
            <ListItemIcon style={{ minWidth: 0 }}>
               <img src={
                this.state.isLibraryHover ? FilledDocLibrary :DocLibrary
              } 
              className={this.state.currentPath !== "/documentation"? "listtile3":"listtile2"} 
                style={{ width: '24px', height: '24px' }} />
              </ListItemIcon>
              {this.state.openCollapse && (   <Typography className={this.state.currentPath !== "/documentation"? "listtile3":"listtile2"} data-test-id="Library">
            Documents
            </Typography>)}
          </ListItem>
      </>
    )
  }
  profileSection = () => {
    return (
      this.state.openCollapse && (
        <Box className="userProfiles">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '80%',
              marginBottom: "18px"
            }}
          >
            <Box
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
            >
              {this.state.userdata?.profile_image?.url ? (
                <img
                  src={this.state.userdata?.profile_image?.url}
                  style={{
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                    border: '1px solid #355EE7',
                  }}
                  alt="Profile"
                  data-test-id="apidata"
                />
              ) : (
                <img
                  src={userpic}
                  style={{
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                    border: '1px solid #355EE7',
                  }}
                  alt="Default profile"
                />
              )}
              <Typography className="userName">
                {this.state.userdata?.first_name || "User"} {this.state.userdata?.last_name || "name"}
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    );
  };
  
  logoBox=()=>{
    return(
    <div className="logobox">
        {this.state.openCollapse ? (<img src={logo.default} alt="logo7" className="logoimag"
         data-test-id="logoImg" />) : (
          <img src={secondlogo} alt="logo5" className="logoimag" data-test-id="logoImg" />
        )}
    </div>)
  }
  renderListItem = (
    testId: string,
    iconSrc: string,
    label: string,
    onClickHandler: () => void,
    isActive: boolean
  ) => {
    const activeClass = isActive ? "listtile2" : "listtile3";
  
    return (
      <ListItem data-test-id={testId} className="listbox22" onClick={onClickHandler}>
        <ListItemIcon style={{ minWidth: 0 }}>
          <div className={activeClass} >
            <img className={activeClass} src={iconSrc} alt="" />
          </div>
        </ListItemIcon>
        {this.state.openCollapse && (
          <Typography className={activeClass} data-test-id={testId}>
            {label}
          </Typography>
        )}
      </ListItem>
    );
  };
  
  managementDrawer =()=>{
  return(
    <>
     <Typography style={{marginLeft:"25px", color:'#BFBFBF' , fontFamily:'DM Sans', marginBottom:"10px"}}>
      {this.state.openCollapse?(<span>Management</span>) :(<span>Manage</span>)}</Typography>
        <List className="MainListBox">
        {this.state.isClientRole && (
          <ListItem data-test-id="PaymentBilling"  onClick={this.handleRedirectPayment} className={this.state.currentPath === "/payment" ? "listbox1" : "listbox"} >
            <ListItemIcon style={{ minWidth: 0 }}>
              {
                this.state.currentPath !== "/payment" ? (
                <svg width="22" height="23" viewBox="0 0 22 23" data-test-id="paymentsvg"
                  className= "icondrawer2" 
                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.6251 6.31249V5.18749C16.6251 5.08437 16.5407 4.99999 16.4376 4.99999H7.4376C7.33448 4.99999 7.2501 5.08437 7.2501 5.18749V6.31249C7.2501 6.41562 7.33448 6.49999 7.4376 6.49999H16.4376C16.5407 6.49999 16.6251 6.41562 16.6251 6.31249ZM7.4376 8.37499C7.33448 8.37499 7.2501 8.45937 7.2501 8.56249V9.68749C7.2501 9.79062 7.33448 9.87499 7.4376 9.87499H11.7501C11.8532 9.87499 11.9376 9.79062 11.9376 9.68749V8.56249C11.9376 8.45937 11.8532 8.37499 11.7501 8.37499H7.4376Z" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8132 12.125C14.0171 12.125 11.7507 14.3914 11.7507 17.1875C11.7507 19.9836 14.0171 22.25 16.8132 22.25C19.6093 22.25 21.8757 19.9836 21.8757 17.1875C21.8757 14.3914 19.6093 12.125 16.8132 12.125ZM16.8132 20.75C17.7648 20.75 18.6601 20.3797 19.3328 19.707C20.0054 19.0344 20.3757 18.1391 20.3757 17.1875C20.3757 16.2359 20.0054 15.3406 19.3328 14.668C18.6601 13.9953 17.7648 13.625 16.8132 13.625C15.8617 13.625 14.9664 13.9953 14.2937 14.668C13.621 15.3406 13.2507 16.2359 13.2507 17.1875C13.2507 18.1391 13.621 19.0344 14.2937 19.707C14.9664 20.3797 15.8617 20.75 16.8132 20.75Z" />
                  <path d="M16.5627 20.3056V14.3724H17.0014V20.3056H16.5627ZM16.8394 19.7994C16.5019 19.7994 16.1959 19.7431 15.9214 19.6306C15.6514 19.5136 15.4377 19.3426 15.2802 19.1176C15.1227 18.8926 15.0372 18.6159 15.0237 18.2874H16.1037C16.1082 18.4179 16.1419 18.5326 16.2049 18.6316C16.2679 18.7306 16.3534 18.8094 16.4614 18.8679C16.5694 18.9219 16.6954 18.9489 16.8394 18.9489C16.9609 18.9489 17.0667 18.9309 17.1567 18.8949C17.2512 18.8544 17.3254 18.7981 17.3794 18.7261C17.4334 18.6496 17.4604 18.5551 17.4604 18.4426C17.4604 18.3256 17.4289 18.2266 17.3659 18.1456C17.3029 18.0601 17.2174 17.9881 17.1094 17.9296C17.0014 17.8666 16.8754 17.8104 16.7314 17.7609C16.5919 17.7069 16.4412 17.6551 16.2792 17.6056C15.9057 17.4841 15.6177 17.3199 15.4152 17.1129C15.2172 16.9059 15.1182 16.6291 15.1182 16.2826C15.1182 15.9946 15.1879 15.7494 15.3274 15.5469C15.4714 15.3399 15.6672 15.1824 15.9147 15.0744C16.1622 14.9664 16.4434 14.9124 16.7584 14.9124C17.0824 14.9124 17.3659 14.9686 17.6089 15.0811C17.8564 15.1891 18.0522 15.3489 18.1962 15.5604C18.3447 15.7674 18.4234 16.0149 18.4324 16.3029H17.3457C17.3412 16.2039 17.3119 16.1139 17.2579 16.0329C17.2084 15.9519 17.1387 15.8866 17.0487 15.8371C16.9632 15.7876 16.8619 15.7629 16.7449 15.7629C16.6414 15.7584 16.5469 15.7741 16.4614 15.8101C16.3759 15.8416 16.3084 15.8934 16.2589 15.9654C16.2094 16.0329 16.1847 16.1184 16.1847 16.2219C16.1847 16.3209 16.2094 16.4086 16.2589 16.4851C16.3129 16.5571 16.3849 16.6201 16.4749 16.6741C16.5694 16.7236 16.6774 16.7709 16.7989 16.8159C16.9249 16.8609 17.0622 16.9059 17.2107 16.9509C17.4492 17.0319 17.6674 17.1286 17.8654 17.2411C18.0679 17.3491 18.2299 17.4931 18.3514 17.6731C18.4774 17.8486 18.5404 18.0826 18.5404 18.3751C18.5404 18.6316 18.4729 18.8679 18.3379 19.0839C18.2074 19.2999 18.0162 19.4731 17.7642 19.6036C17.5167 19.7341 17.2084 19.7994 16.8394 19.7994Z" />
                  <path d="M5.75046 18.875V2.46874H17.188C17.6022 2.46874 17.938 2.80453 17.938 3.21874V10.7187C18.612 10.8912 19.0341 11.0216 19.6255 11.375V3.0313C19.6255 1.78866 18.6182 0.781297 17.3755 0.781293L3.50009 0.78125C1.84326 0.781245 0.500121 2.12435 0.500079 3.78117L0.5 6.87497C0.499989 7.28919 0.835779 7.62499 1.25 7.62499H2.37532H4.06296V5.74999H2.37532V3.84678C2.37532 2.97825 3.26927 2.39727 4.06296 2.74999L-nan -nanL4.06296 2.74999V3.49999V5.74999V7.62499V21.5008C4.06296 21.6136 4.18276 21.686 4.28264 21.6336L5.42297 21.0354C5.46661 21.0125 5.5187 21.0125 5.56234 21.0354L6.8452 21.7084C6.8929 21.7334 6.95036 21.7309 6.99574 21.7019L8.01339 21.0506C8.06268 21.0191 8.12581 21.0191 8.1751 21.0506L9.18528 21.6971C9.23457 21.7287 9.2977 21.7287 9.34699 21.6971L10.3616 21.0478C10.4086 21.0177 10.4684 21.0162 10.5168 21.0439L11.7507 21.7489C11.1241 21.0436 10.8651 19.8822 10.6257 18.9696L10.5093 18.9109C10.4647 18.8885 10.4119 18.8896 10.3684 18.914L9.33943 19.4902C9.29389 19.5157 9.23838 19.5157 9.19285 19.4902L8.16753 18.916C8.122 18.8905 8.06649 18.8905 8.02095 18.916L6.99564 19.4902C6.95011 19.5157 6.8946 19.5157 6.84906 19.4902L5.75046 18.875Z" />
                </svg>):( <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.75 0.75H1.25C0.835156 0.75 0.5 1.08516 0.5 1.5V16.5C0.5 16.9148 0.835156 17.25 1.25 17.25H11.75C11.2962 16.6489 11.1345 16.2785 11 15.5625H2.1875V7.3125H19.8125V7.875C20.6932 8.14603 20.7035 8.16454 21.5 8.82177V1.5C21.5 1.08516 21.1648 0.75 20.75 0.75ZM19.8125 2.4375H2.1875V5.25H19.8125V2.4375Z" fill="#355EE7"/>
<path d="M2.1875 2.4375H19.8125V5.25H2.1875V2.4375Z" fill="#355EE7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.5625 9C14.7664 9 12.5 11.2664 12.5 14.0625C12.5 16.8586 14.7664 19.125 17.5625 19.125C20.3586 19.125 22.625 16.8586 22.625 14.0625C22.625 11.2664 20.3586 9 17.5625 9ZM17.5625 17.625C18.5141 17.625 19.4094 17.2547 20.082 16.582C20.7547 15.9094 21.125 15.0141 21.125 14.0625C21.125 13.1109 20.7547 12.2156 20.082 11.543C19.4094 10.8703 18.5141 10.5 17.5625 10.5C16.6109 10.5 15.7156 10.8703 15.043 11.543C14.3703 12.2156 14 13.1109 14 14.0625C14 15.0141 14.3703 15.9094 15.043 16.582C15.7156 17.2547 16.6109 17.625 17.5625 17.625Z" fill="#355EE7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.082 16.582C19.4094 17.2547 18.5141 17.625 17.5625 17.625C16.6109 17.625 15.7156 17.2547 15.043 16.582C14.3703 15.9094 14 15.0141 14 14.0625C14 13.1109 14.3703 12.2156 15.043 11.543C15.7156 10.8703 16.6109 10.5 17.5625 10.5C18.5141 10.5 19.4094 10.8703 20.082 11.543C20.7547 12.2156 21.125 13.1109 21.125 14.0625C21.125 15.0141 20.7547 15.9094 20.082 16.582ZM17.3119 17.1806V16.6611C17.0819 16.6384 16.8681 16.5866 16.6707 16.5056C16.4007 16.3886 16.1869 16.2176 16.0294 15.9926C15.8719 15.7676 15.7864 15.4909 15.7729 15.1624H16.8529C16.8574 15.2929 16.8912 15.4076 16.9542 15.5066C17.0172 15.6056 17.1027 15.6844 17.2107 15.7429C17.2428 15.759 17.2766 15.7726 17.3119 15.7839V14.5736C17.2216 14.5418 17.1271 14.5108 17.0284 14.4806C16.6549 14.3591 16.3669 14.1949 16.1644 13.9879C15.9664 13.7809 15.8674 13.5041 15.8674 13.1576C15.8674 12.8696 15.9372 12.6244 16.0767 12.4219C16.2207 12.2149 16.4164 12.0574 16.6639 11.9494C16.859 11.8643 17.075 11.8127 17.3119 11.7947V11.2474H17.7507V11.7987C17.9755 11.8202 18.178 11.8727 18.3582 11.9561C18.6057 12.0641 18.8014 12.2239 18.9454 12.4354C19.0939 12.6424 19.1727 12.8899 19.1817 13.1779H18.0949C18.0904 13.0789 18.0612 12.9889 18.0072 12.9079C17.9577 12.8269 17.8879 12.7616 17.7979 12.7121C17.7827 12.7033 17.7669 12.6953 17.7507 12.688V13.76C17.8177 13.782 17.8875 13.8039 17.9599 13.8259C18.1984 13.9069 18.4167 14.0036 18.6147 14.1161C18.8172 14.2241 18.9792 14.3681 19.1007 14.5481C19.2267 14.7236 19.2897 14.9576 19.2897 15.2501C19.2897 15.5066 19.2222 15.7429 19.0872 15.9589C18.9567 16.1749 18.7654 16.3481 18.5134 16.4786C18.3031 16.5896 18.0488 16.6533 17.7507 16.67V17.1806H17.3119Z" fill="#355EE7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.7507 15.8118V14.7468C17.6691 14.7067 17.5791 14.6697 17.4807 14.6359C17.4261 14.6148 17.3699 14.594 17.3119 14.5736V15.7839C17.3953 15.8106 17.4875 15.8239 17.5887 15.8239C17.6462 15.8239 17.7002 15.8199 17.7507 15.8118ZM17.5482 13.6909C17.4628 13.6593 17.384 13.6265 17.3119 13.5926V12.6529C17.3692 12.6401 17.43 12.6351 17.4942 12.6379C17.5903 12.6379 17.6758 12.6546 17.7507 12.688V13.76C17.6802 13.737 17.6127 13.7139 17.5482 13.6909Z" fill="#355EE7"/>
<path d="M17.2107 12.6851C17.1252 12.7166 17.0577 12.7684 17.0082 12.8404C16.9587 12.9079 16.9339 12.9934 16.9339 13.0969C16.9339 13.1959 16.9587 13.2836 17.0082 13.3601C17.0622 13.4321 17.1342 13.4951 17.2242 13.5491C17.2522 13.5638 17.2815 13.5783 17.3119 13.5926V12.6529C17.2769 12.6607 17.2431 12.6715 17.2107 12.6851Z" fill="#355EE7"/>
<path d="M17.9059 15.7699C18.0004 15.7294 18.0747 15.6731 18.1287 15.6011C18.1827 15.5246 18.2097 15.4301 18.2097 15.3176C18.2097 15.2006 18.1782 15.1016 18.1152 15.0206C18.0522 14.9351 17.9667 14.8631 17.8587 14.8046C17.8245 14.7847 17.7885 14.7654 17.7507 14.7468V15.8118C17.8068 15.8028 17.8585 15.7888 17.9059 15.7699Z" fill="#355EE7"/>
</svg>
                )
              }
            </ListItemIcon>
            {this.state.openCollapse && (  
              <Typography className={this.state.currentPath !== "/payment" ? "listtile" : "listtile1"} data-test-id="task">
            Billing
            </Typography>)}
          </ListItem>
        )}
      {this.state.isLawfirmAdmin && <ListItem data-test-id="invoice_payout" className="listbox22" onClick={this.handleCollectionFees}>
            <ListItemIcon style={{ minWidth: 0 }}>
                <div className={this.state.currentPath !== "/invoicing-payouts"? "listtile3":"listtile2"} style={{ width: '24px', height: '24px' }}>
                 <PayoutIcon/>
                 </div>
              </ListItemIcon>
              {this.state.openCollapse && (  <Typography  className={this.state.currentPath !== "/invoicing-payouts"? "listtile3":"listtile2"} data-test-id="Invoicing">
           Invoicing/Payouts
            </Typography>)}
          </ListItem>}
          {this.state.isLawfirmAdmin && 
  this.renderListItem(
    "lawfirm_management",
    lawfirm.default,
    "Law Firm",
    this.handleLawFirmManagement,
    this.state.currentPath === "/lawfirm-management"
  )
}
{this.state.isLawfirmAdmin && 
  this.renderListItem(
    "admin_lawyer_management",
    lawyers.default,
    "Lawyers",
    this.handleAdminLawyer,
    this.state.currentPath === "/admin-lawyer-management"
  )
}
{this.state.isLawfirmAdmin && 
  this.renderListItem(
    "services_management",
    services.default,
    "Services",
    this.handleService,
    this.state.currentPath === "/services-management"
  )
}
{this.state.isLawfirmAdmin && 
  this.renderListItem(
    "project_reporting",
    project.default,
    "Project Reporting",
    this.handleProjectManagement,
    this.state.currentPath === "/project-reporting"
  )
}
          <ListItem className="listbox" data-test-id="settingtestIn" onClick={this.handleSettingIn}>
          <ListItemIcon style={{ minWidth: 0 }}><SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
          {this.state.openCollapse && (  <Typography className="listtile" data-test-id="Setting">
            Settings
          </Typography>)}
        </ListItem>
        </List></>
  )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const buttonMenu = (
      <ButtonBox className="buttonbox">
        <IconButton
          className="iconbtn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={this.handleDrawerToggle}
          style={{ marginRight: '24px', color: '#000' }}
        >
          <MenuIcon />
  
        </IconButton>
      </ButtonBox>
    )
    return (
      <MainDiv data-test-id="NavigationMenu"
          style={{
            maxHeight: this.state.mobileOpen ? '100vh' : '',
            overflow: this.state.mobileOpen ? "hidden" : ""
          }}>
          <Box className="sidebar">
            {this.drawer()}
            {buttonMenu}
            {this.state.mobileOpen &&
              <>
                <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                  {this.drawer()}
                </div>
              </>
            }
          </Box>
          {this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>}
        </MainDiv>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DrawerBox = styled('div')({
  minHeight: '100vh',
  borderRight:'1px solid #E2E8F0',
  "& .logobox": {
    display: 'flex',justifyContent: "start",
    padding:'10px 117px 25px 23px',
    borderBottom:'2px solid #F5F5F5'
  },
  "& .logoimag": 
  {
    height: '42px',
    width: '118px',
  },
  "& .settingbox": 
  {
    display: 'flex',
    height: "65px",
  },
  "& .MainListBox":{
    padding:'0px 16px 8px 16px',
    position:'relative'
  },
  "& .listbox": {
    height: '56px',
    borderRadius:'8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2": 
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox22": {
    height: '56px',
    gap:"6px",
    borderRadius:'8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2": 
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '56px',
    backgroundColor: '#ECF1FF',
    display:'flex',
    textAlign:"center",
    borderRadius:'8px',
    marginBottom:'10px',
    color: "#355EE7",
  },
  "& .icondrawer1": {
    fontWeight:700,
    color:'#355EE7'
  },
  "& .icondrawer2": {
    marginBottom: '2px',
  },
  "& .listtile1": {
    fontFamily:"DM Sans",
    fontSize: "16px",
    color: '#355EE7',
    fontWeight: 700,
    cursor:'pointer',
    margin:'10px',
    marginRight:'38px'
  },
  "& .listtile2": {
    fontFamily:"DM Sans",
    fontSize: "16px",
    color: '#355EE7',
    fontWeight: 700,
    cursor:'pointer',
  },
  "& .listtile": 
  {
    fontFamily:"DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    cursor:'pointer',
    marginLeft:'10px'
  },
  "& .listtile3": 
  {
    fontFamily:"DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    cursor:'pointer',
    display:"flex",
gap:"6px"
  },
  "& .userProfiles":{
    display: "flex",
    width:'252px',
    position:'absolute',
    bottom:'10px',
    left:'32px'
  },
  "& .imageBox":{
    width:'32px',
    height:'32px',
    borderRadius:'50%',
    border:'1px solid #F0E5FF',
    marginRight:'10px',
  },
  "& .userName":{
    fontFamily:'DM Sans',
    fontSize:'14px',
    fontWeight:700,
    color:'#0F172A'
  },
  "& .userContent":{
    fontFamily:'DM Sans',
    fontSize:'11px',
    fontWeight:400,
    color:'#8C8C8C'
  },
  "@media (max-width: 1100px)": 
  {
    zIndex: 999,
    width: '252px',
  },
  "@media (max-width: 991px)":
   {
    zIndex: 999,
    width: '252px',
    display: 'none',
  },
});
const ButtonBox = styled('div')(
  {
  display: 'none',
  "@media (max-width: 991px)": 
  {
    width: '100%',
    display: 'block',minHeight: '70px',
    "& .buttonbox": 
    {
      height: '70px',width: '100vw'
    },

    "& .iconbtn": 
    {
      marginTop: '10px',marginLeft: '10px'
    }
  }
})
const MainDiv = styled('div')({
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '252px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '252px',
    width: 'calc(100% - 252px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },  
})
// Customizable Area End
