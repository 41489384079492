// Customizable Area Start
import React from "react";

import { 
  Container, 
  Box, 
  Input,  
  Button,
  TextField,
  InputLabel,
  Typography, 
  InputAdornment, 
  IconButton,
  styled,
  CircularProgress,
} from '@material-ui/core';
import { NotificationsNoneRounded } from "@material-ui/icons";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';


import CollectTransactionFeesController, {
  Props,
  configJSON
} from "./CollectTransactionFeesController";
import Sidebar from "./components/Sidebar.web";
import PayoutStateTabGroup from "./components/PayoutStateTabGroup.web";
import PayoutSearchAndFilter from "./components/PayoutSearchAndFilter.web";
import PayoutOverview from "./components/PayoutOverview.web";
import PayoutDetail from "./components/PayoutDetail.web";
import Task from "./components/Task.web";
import { DateObject } from "react-multi-date-picker";


const CollectTransactionFeesContainer = styled("div")({
  display: "flex",
  width: "100%",
  backgroundColor: "#FAFAFA",
  overflowX: "hidden",
  '& .inner-container': {
    display: "flex",
    flexDirection: "column",
    gap: 22,
    padding: "24px 35px 32px",
    boxSizing: "border-box",
    '& .header': {
      display: "flex",
      gap: 6,
      alignItems: "center",
      '& > h1': {
        margin: 0,
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: 24,
        lineHeight: "31.25px",
        color: "#0F172A",
      },
    },
    '& .main-content-container': {
      paddingTop: 24,
      backgroundColor: "#FFFFFF",
      borderRadius: 12,
      flex: 1,
    },
    '& .main-content-header': {
      padding: "0px 24px",
      borderBottom: "1px solid #F0F0F0",
      '& > div': {
        display: "flex",
        gap: 6,
        padding: "6px 4px",
        width: "max-content",
        boxSizing: "border-box",
        alignItems: "center",
        position: "relative",
        '& > span': {
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: 12,
          lineheight: "22px",
          color: "#355EE7",
        },
        '& .active': {
          position: "absolute",
          width: "100%",
          height: "2px",
          bottom: -1,
          left: 0,
          backgroundColor: "#355EE7",
          zIndex: 2,
        },
      },
    },
    '& .flex': {
      display: "flex"
    },
    '& .items-center': {
      alignItems: "center",
    },
    '& .flex-column': {
      flexDirection: "column",
    },
    '& .space-between': {
      justifyContent: "space-between",
    },
    '& .flex-nowrap': {
      flexWrap: "nowrap",
    },
  },
  '&:has([aria-expanded="false"]) > .inner-container': {
    width: "calc(100% - 90px)"
  },
})

const TaskContainer = styled("div")({
  border: "1px solid #F0F0F0",
  borderRadius: 8,
  padding: 12,
  marginTop: "20px",
  '& .inner-task-list-container': {
    paddingRight: "24px",
    maxHeight: 576,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    "&::-webkit-scrollbar": {
      width: "6px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#F5F5F5",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#91AAFF",
      borderRadius: 10,
    },
  }
})

const PayoutContainer =  styled("div")(({ theme }) => ({
  display: 'flex',
  height: 'calc(100% - 33px)',
  boxSizing: 'border-box',
  '& .payouts': {
    boxSizing: 'border-box',
    padding: 24,
    borderRight: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    width: 'calc(100% - 404px)',
  },
  '& .payout-overview': {
    boxSizing: 'border-box',
    padding: 24,
    width: 404,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  '& .task-scroll': {
    gap: 12,
    width: 624,
    paddingBottom: 12,
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#F5F5F5',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#91AAFF',
      borderRadius: 10,
    },
  },
  '& .task-grid': {
    display: 'grid',
    gap: 12,
    gridTemplateColumns: '63fr 152fr 65fr 67fr 122fr',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& .payouts': {
      width: '100%',
      borderRight: 'none',
    },
    '& .payout-overview': {
      width: '100%',
    },
    '& .task-scroll': {
      width: '100%',
      overflowX: 'visible',
    },
  },
}));

const StyleTypography = styled(Typography)({
  backgroundColor:"#DCECFF",
  borderRadius:"30px",
  fontSize:"14px",
  color:"#355EE7",
  padding:"5px",
  display:"flex",
  alignItems:"center",
  gap:"5px",
  paddingLeft:"10px"
})

export default class CollectTransactionFees extends CollectTransactionFeesController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <MainContainer style={{
        overflow:"hidden"
      }}>
        <ThemeProvider theme={theme} >
          <CollectTransactionFeesContainer>
        <Box style={{display:'flex', justifyContent:'start', alignItems:'start', width:'100%'}}>
                                <Box>
                                    <NavigationMenu navigation={this.props.navigation} id={""} />
                                </Box>
                                <Box className="responsive">

            <div className="inner-container" >
              <header className="header" >
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="44" height="44" rx="22" fill="#C3D1FF"/>
                  <rect width="24" height="24" transform="translate(10 10)" fill="#C3D1FF"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M31.75 13.75H12.25C11.8352 13.75 11.5 14.0852 11.5 14.5V29.5C11.5 29.9148 11.8352 30.25 12.25 30.25H22.75C22.2962 29.6489 22.1345 29.2785 22 28.5625H13.1875V20.3125H30.8125V20.875C31.6932 21.146 31.7035 21.1645 32.5 21.8218V14.5C32.5 14.0852 32.1648 13.75 31.75 13.75ZM30.8125 15.4375H13.1875V18.25H30.8125V15.4375Z" fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M28.5625 22C25.7664 22 23.5 24.2664 23.5 27.0625C23.5 29.8586 25.7664 32.125 28.5625 32.125C31.3586 32.125 33.625 29.8586 33.625 27.0625C33.625 24.2664 31.3586 22 28.5625 22ZM28.5625 30.625C29.5141 30.625 30.4094 30.2547 31.082 29.582C31.7547 28.9094 32.125 28.0141 32.125 27.0625C32.125 26.1109 31.7547 25.2156 31.082 24.543C30.4094 23.8703 29.5141 23.5 28.5625 23.5C27.6109 23.5 26.7156 23.8703 26.043 24.543C25.3703 25.2156 25 26.1109 25 27.0625C25 28.0141 25.3703 28.9094 26.043 29.582C26.7156 30.2547 27.6109 30.625 28.5625 30.625Z" fill="white"/>
                  <path d="M28.3119 30.1806V24.2474H28.7507V30.1806H28.3119ZM28.5887 29.6744C28.2512 29.6744 27.9452 29.6181 27.6707 29.5056C27.4007 29.3886 27.1869 29.2176 27.0294 28.9926C26.8719 28.7676 26.7864 28.4909 26.7729 28.1624H27.8529C27.8574 28.2929 27.8912 28.4076 27.9542 28.5066C28.0172 28.6056 28.1027 28.6844 28.2107 28.7429C28.3187 28.7969 28.4447 28.8239 28.5887 28.8239C28.7102 28.8239 28.8159 28.8059 28.9059 28.7699C29.0004 28.7294 29.0747 28.6731 29.1287 28.6011C29.1827 28.5246 29.2097 28.4301 29.2097 28.3176C29.2097 28.2006 29.1782 28.1016 29.1152 28.0206C29.0522 27.9351 28.9667 27.8631 28.8587 27.8046C28.7507 27.7416 28.6247 27.6854 28.4807 27.6359C28.3412 27.5819 28.1904 27.5301 28.0284 27.4806C27.6549 27.3591 27.3669 27.1949 27.1644 26.9879C26.9664 26.7809 26.8674 26.5041 26.8674 26.1576C26.8674 25.8696 26.9372 25.6244 27.0767 25.4219C27.2207 25.2149 27.4164 25.0574 27.6639 24.9494C27.9114 24.8414 28.1927 24.7874 28.5077 24.7874C28.8317 24.7874 29.1152 24.8436 29.3582 24.9561C29.6057 25.0641 29.8014 25.2239 29.9454 25.4354C30.0939 25.6424 30.1727 25.8899 30.1817 26.1779H29.0949C29.0904 26.0789 29.0612 25.9889 29.0072 25.9079C28.9577 25.8269 28.8879 25.7616 28.7979 25.7121C28.7124 25.6626 28.6112 25.6379 28.4942 25.6379C28.3907 25.6334 28.2962 25.6491 28.2107 25.6851C28.1252 25.7166 28.0577 25.7684 28.0082 25.8404C27.9587 25.9079 27.9339 25.9934 27.9339 26.0969C27.9339 26.1959 27.9587 26.2836 28.0082 26.3601C28.0622 26.4321 28.1342 26.4951 28.2242 26.5491C28.3187 26.5986 28.4267 26.6459 28.5482 26.6909C28.6742 26.7359 28.8114 26.7809 28.9599 26.8259C29.1984 26.9069 29.4167 27.0036 29.6147 27.1161C29.8172 27.2241 29.9792 27.3681 30.1007 27.5481C30.2267 27.7236 30.2897 27.9576 30.2897 28.2501C30.2897 28.5066 30.2222 28.7429 30.0872 28.9589C29.9567 29.1749 29.7654 29.3481 29.5134 29.4786C29.2659 29.6091 28.9577 29.6744 28.5887 29.6744Z" fill="white"/>
                </svg>
                <h1>Payouts</h1>
                <IconButton style={{padding: 0, marginLeft: "auto"}} >
                  <NotificationsNoneRounded htmlColor="#BFBFBF" />
                </IconButton>
              </header>
              <div className="main-content-container">
                <div className="main-content-header" >
                    <div>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6876 8.28125V7.34375C14.6876 7.25781 14.6173 7.1875 14.5313 7.1875H7.03133C6.9454 7.1875 6.87508 7.25781 6.87508 7.34375V8.28125C6.87508 8.36719 6.9454 8.4375 7.03133 8.4375H14.5313C14.6173 8.4375 14.6876 8.36719 14.6876 8.28125ZM7.03133 10C6.9454 10 6.87508 10.0703 6.87508 10.1562V11.0938C6.87508 11.1797 6.9454 11.25 7.03133 11.25H10.6251C10.711 11.25 10.7813 11.1797 10.7813 11.0938V10.1562C10.7813 10.0703 10.711 10 10.6251 10H7.03133Z" fill="#355EE7"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.1879 18.3628V3.35941C17.1879 2.32388 16.3485 1.48441 15.313 1.48441L3.75008 1.48438C2.36938 1.48437 1.2501 2.60362 1.25007 3.98431L1.25 6.56248C1.24999 6.90766 1.52982 7.18749 1.875 7.18749H2.81277H4.21913V5.62499H2.81277V4.03898C2.81277 3.3152 3.55773 2.83106 4.21913 3.12499V3.74999V5.62499V7.18749V18.7507C4.21913 18.8446 4.31897 18.905 4.4022 18.8613L5.35248 18.3628C5.38884 18.3438 5.43225 18.3438 5.46861 18.3628L6.53766 18.9237C6.57742 18.9445 6.6253 18.9424 6.66311 18.9182L7.51116 18.3755C7.55223 18.3492 7.60484 18.3492 7.64592 18.3755L8.48773 18.9143C8.52881 18.9405 8.58142 18.9405 8.62249 18.9143L9.55872 18.3732C9.59789 18.3481 9.64773 18.3468 9.68811 18.3699L10.5648 18.9193C10.6025 18.9429 10.6498 18.9447 10.6891 18.9241L11.6859 18.4026C11.7247 18.3823 11.7714 18.3838 11.8088 18.4066L12.6527 18.9193C12.6914 18.9428 12.7399 18.9435 12.7793 18.9211L13.6857 18.4067C13.7257 18.384 13.7749 18.3851 13.8138 18.4095L14.6262 18.9186C14.6645 18.9426 14.7126 18.944 14.7523 18.9225L15.7288 18.3916C15.7622 18.3734 15.8021 18.3715 15.8372 18.3863L17.0143 18.884C17.0967 18.9188 17.1879 18.8583 17.1879 18.7688V18.3628ZM5.62538 2.89062V16.5625L6.54088 17.0752C6.57883 17.0964 6.62509 17.0964 6.66303 17.0752L7.51746 16.5967C7.55541 16.5755 7.60167 16.5755 7.63961 16.5967L8.49404 17.0752C8.53198 17.0964 8.57824 17.0964 8.61619 17.0752L9.56443 16.595C9.60069 16.5747 9.64468 16.5737 9.68179 16.5924L9.77884 16.6414L10.5733 17.0484C10.6064 17.0653 10.6454 17.0667 10.6796 17.0519L11.6952 16.6153C11.729 16.6008 11.7675 16.6019 11.8004 16.6183L12.6617 17.0483C12.6957 17.0653 12.7355 17.0659 12.77 17.05L13.689 16.6252C13.7274 16.6074 13.7722 16.6103 13.8081 16.6329L14.6261 17.1484C14.6644 17.1726 14.7127 17.1741 14.7525 17.1525L15.7164 16.6279C15.7567 16.606 15.7817 16.5639 15.7817 16.5181V3.51562C15.7817 3.17044 15.5019 2.89062 15.1567 2.89062H5.62538Z" fill="#355EE7"/>
                        <path d="M14.6876 5.625V4.6875C14.6876 4.60156 14.6173 4.53125 14.5313 4.53125H7.03133C6.9454 4.53125 6.87508 4.60156 6.87508 4.6875V5.625C6.87508 5.71094 6.9454 5.78125 7.03133 5.78125H14.5313C14.6173 5.78125 14.6876 5.71094 14.6876 5.625Z" fill="#355EE7"/>
                      </svg>
                      <span>Payouts</span>
                      <div className="active" />
                    </div>
                </div>
                <PayoutContainer>
                  <div className="payouts">
                    <div className="flex flex-column" style={{gap: 8}} >
                      <Typography variant="h2" component="h2" >Invoicing & Payouts</Typography>
                      <Typography variant="h3" component="h3" >Manage your invoices and pending payouts.</Typography>
                    </div>
                    <div>
                      <div className="flex" style={{gap: 12}}>
                          <PayoutStateTabGroup data-test-id="payout_state_tab" onTabChange={this.handleTabChange}/>
                          <PayoutSearchAndFilter 
                            data-test-id="filter_parent"
                            onInputChange={this.handleInputChange} 
                            legalServiceTypeList={this.state.legalServiceTypeList} 
                            onServiceTypeChange = {this.onChangeServiceType}
                            onDateChange = {(date) => this.onDateChange(date as DateObject)}
                            serviceQuery = {this.state.selectedService}
                            dateObjectState =  {this.state.dateObjectState}
                            dateObjectQuery =  {this.state.dateQuery}
                          />
                      </div>
                      <div style={{display:"flex", gap:"10px",marginTop:"15px",height:"30px"}}>
                        {this.state.serviceTypeQuery &&<StyleTypography variant="body1" >
                          {this.state.serviceTypeNameQuery}
                          <span data-test-id="clear_service" onClick={() => this.handleClearService()} style={{cursor:"pointer"}}>
                            <CloseIcon fontSize="small"/>
                          </span>
                        </StyleTypography>}
                       {this.state.dateQuery && <StyleTypography variant="body1" >
                            {this.state.dateQuery}
                            <span data-test-id="clear_date" onClick={() => this.handleClearDate()} style={{cursor:"pointer"}}>
                              <CloseIcon fontSize="small"/>
                           </span>
                        </StyleTypography>}
                      </div>
                    </div>
                    <div className="flex flex-column">
                      <div className="task-grid">
                        <div className="flex itemscenter" style={{gap: 12,alignItems:"center" }} id="task-id" >
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6876 8.28125V7.34375C14.6876 7.25781 14.6173 7.1875 14.5313 7.1875H7.03133C6.9454 7.1875 6.87508 7.25781 6.87508 7.34375V8.28125C6.87508 8.36719 6.9454 8.4375 7.03133 8.4375H14.5313C14.6173 8.4375 14.6876 8.36719 14.6876 8.28125ZM7.03133 10C6.9454 10 6.87508 10.0703 6.87508 10.1562V11.0938C6.87508 11.1797 6.9454 11.25 7.03133 11.25H10.6251C10.711 11.25 10.7813 11.1797 10.7813 11.0938V10.1562C10.7813 10.0703 10.711 10 10.6251 10H7.03133Z" fill="#BFBFBF"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.1879 18.3628V3.35941C17.1879 2.32388 16.3485 1.48441 15.313 1.48441L3.75008 1.48438C2.36938 1.48437 1.2501 2.60362 1.25007 3.98431L1.25 6.56248C1.24999 6.90766 1.52982 7.18749 1.875 7.18749H2.81277H4.21913V5.62499H2.81277V4.03898C2.81277 3.3152 3.55773 2.83106 4.21913 3.12499V3.74999V5.62499V7.18749V18.7507C4.21913 18.8446 4.31897 18.905 4.4022 18.8613L5.35248 18.3628C5.38884 18.3438 5.43225 18.3438 5.46861 18.3628L6.53766 18.9237C6.57742 18.9445 6.6253 18.9424 6.66311 18.9182L7.51116 18.3755C7.55223 18.3492 7.60484 18.3492 7.64592 18.3755L8.48773 18.9143C8.52881 18.9405 8.58142 18.9405 8.62249 18.9143L9.55872 18.3732C9.59789 18.3481 9.64773 18.3468 9.68811 18.3699L10.5648 18.9193C10.6025 18.9429 10.6498 18.9447 10.6891 18.9241L11.6859 18.4026C11.7247 18.3823 11.7714 18.3838 11.8088 18.4066L12.6527 18.9193C12.6914 18.9428 12.7399 18.9435 12.7793 18.9211L13.6857 18.4067C13.7257 18.384 13.7749 18.3851 13.8138 18.4095L14.6262 18.9186C14.6645 18.9426 14.7126 18.944 14.7523 18.9225L15.7288 18.3916C15.7622 18.3734 15.8021 18.3715 15.8372 18.3863L17.0143 18.884C17.0967 18.9188 17.1879 18.8583 17.1879 18.7688V18.3628ZM5.62538 2.89062V16.5625L6.54088 17.0752C6.57883 17.0964 6.62509 17.0964 6.66303 17.0752L7.51746 16.5967C7.55541 16.5755 7.60167 16.5755 7.63961 16.5967L8.49404 17.0752C8.53198 17.0964 8.57824 17.0964 8.61619 17.0752L9.56443 16.595C9.60069 16.5747 9.64468 16.5737 9.68179 16.5924L9.77884 16.6414L10.5733 17.0484C10.6064 17.0653 10.6454 17.0667 10.6796 17.0519L11.6952 16.6153C11.729 16.6008 11.7675 16.6019 11.8004 16.6183L12.6617 17.0483C12.6957 17.0653 12.7355 17.0659 12.77 17.05L13.689 16.6252C13.7274 16.6074 13.7722 16.6103 13.8081 16.6329L14.6261 17.1484C14.6644 17.1726 14.7127 17.1741 14.7525 17.1525L15.7164 16.6279C15.7567 16.606 15.7817 16.5639 15.7817 16.5181V3.51562C15.7817 3.17044 15.5019 2.89062 15.1567 2.89062H5.62538Z" fill="#BFBFBF"/>
                            <path d="M14.6876 5.625V4.6875C14.6876 4.60156 14.6173 4.53125 14.5313 4.53125H7.03133C6.9454 4.53125 6.87508 4.60156 6.87508 4.6875V5.625C6.87508 5.71094 6.9454 5.78125 7.03133 5.78125H14.5313C14.6173 5.78125 14.6876 5.71094 14.6876 5.625Z" fill="#BFBFBF"/>
                          </svg>
                          <Typography variant="body2" component="span" style={{fontWeight: 500}} >ID</Typography>
                        </div>
                        <Typography variant="body2" component="span" id="task-name" style={{fontWeight: 500}} >Task Name</Typography>
                        <Typography variant="body2" component="span" id="task-amount" style={{fontWeight: 500}} >Amount</Typography>
                        <Typography variant="body2" component="span" id="task-date" style={{fontWeight: 500}} >Date</Typography>
                        <Typography variant="body2" component="span" id="task-status" style={{fontWeight: 500}} >Status</Typography>
                      </div>
                      <TaskContainer>
                      {this.state.IsTaskLoading &&
                        <div style={{textAlign:"center", padding:"30px"}}>
                          <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }}  />
                        </div>
                      }
                       {!this.state.IsTaskLoading && <div className="inner-task-list-container">
                          {this.state.invoiceTasks?.map((tasks)=>(
                            <Task data-test-id="row-parent" tasks={tasks} onClickTask={this.getInvoiceTaskDetails}/>
                          ))
                         }
                        </div>}
                      </TaskContainer>
                    </div>
                  </div>
                  <div className="payout-overview">
                    <PayoutOverview data-test-id="payout_overview" payoutOverviewDetails={this.state.payoutOverview}/>
                    <PayoutDetail data-test-id="payout_details" invoiceTaskDetails={this.state.invoiceTaskDetails} IsTaskDetailsLoading={this.state.IsTaskDetailsLoading} digipCommission= {this.state.feePercentValue?.fee_percent as string} />
                  </div>
                </PayoutContainer>
              </div>
            </div>
             </Box>
                                            </Box>
          </CollectTransactionFeesContainer>
        </ThemeProvider>
        </MainContainer>
    );
  }
}

const MainContainer = styled('div')({
  "& .menubar": {
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    "@media (max-width: 1100px)": {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    "@media (max-width: 451px)": {
        display:'flex',
        flexDirection:'column',
    }
  },
  "& .responsive":{padding:'30px',
    width:'100%',
    height:'100vh',
    overflow:"auto",
  
      "@media (max-width: 1280px)": {
      overflowX:'hidden',
      height:'100vh',
      padding:'50px',overflowY:'scroll',
      },
      "@media (max-width: 1366px)":
       {padding:'50px',
     
      height:'100vh',
      overflowX:'hidden',
      overflowY:'scroll',
      }},})
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  
  typography: {
    fontFamily: "DM Sans",
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    },
    h2: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "23.44px",
      color: "#0F172A"
    },
    h3: {
      color: "#8C8C8C",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "18.23px",
    },
    h4: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "18.23px",
      color: "#262626",
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "15.62px",
      color: "#8C8C8C",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '20px',
    marginBottom: '20px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  },
  boxStyle: {
    display: 'flex', 
    alignItems: 'center',
    padding: '5px',
    justifyContent: 'center'
},
};

// Customizable Area End
