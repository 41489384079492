import React from "react";

// Customizable Area Start
import {
  Box,
  IconButton,
  styled,
  Divider,
  LinearProgress,
  CircularProgress,
  ThemeProvider,
  createTheme,
  DialogActions,
  Drawer,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  InputAdornment,
  Chip,
  DialogContent,
  TextareaAutosize,
  MenuItem,
  Menu,
  Badge,
} from "@material-ui/core";
import VpnLockIcon from '@material-ui/icons/VpnLock';
import SendIcon from "@material-ui/icons/Send";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import {group,group1,group2, errorIcon,newgroup,chatG, pdfI, userProfile, filelogo, secondLogo, filetab, chattab, weighttab, threefolder, arrowpng, rightarrow, paymenttab, settingtab, hometab, logo, archivepng, downlaodIcon, docxx, refresh, doc } from "./assets";
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
const renameTheme = createTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 580,
        width: 580,
        '&.width-600': {
          width: 600,
          maxWidth: 600
        },
      },
      paper: {
        boxSizing: "border-box",
      },
    },
    MuiPaper: {
      root: {
        padding: "32px 24px",
        gap: 32,
        '& .inner-paper': {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 24,
          padding: 32,
          border: "1px solid #FEE2E2",
          borderRadius: 8,
          '& > p': {
            margin: 0,
            color: "#000000",
            fontFamily: "DM Sans",
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "23.44px",
          },
        },
        '& .heading': {
          color: "#262626",
          fontFamily: "DM Sans",
          fontSize: 22,
          fontWeight: "500",
          lineHeight: "28.64px",
          margin: 0,
        },
        '& .searchBox': {
          border: "1px solid #C3D1FF",
          display: "flex",
          gap: 8,
          padding: "10px 8px",
          borderRadius: 12,
          '& > input': {
            border: 0,
            outline: "none",
            width: "100%",
            color: "#262626",
            fontFamily: "DM Sans",
            fontSize: 16,
            fontWeight: "400",
            lineHeight: "24px",
            '&::placeholder': {
              color: "#BFBFBF"
            },
          },
        },
        '& .folderOuterContainer': {
          border: "1px solid #F0F0F0",
          borderRadius: 8,
          padding: "12px 12px 0px 12px",
        },
        '& .folderInnerContainer': {
          maxHeight: 312,
          overflowY: "auto",
          paddingRight: 12,
          scrollbarColor: "#91AAFF #FFFFFF",
          scrollbarWidth: "thin",
          '& .ul': {
            display: "flex",
            flexDirection: "column",
            '& div': {
              width: "100%",
            },
            '& .li': {
              '& > div': {
                boxSizing: "border-box",
                padding: "12px",
                borderStyle: "solid",
                borderColor: "#D9D9D9",
                borderWidth: 0,
                display: "flex",
                gap: 6,
                alignItems: "center",
                fontFamily: "DM Sans",
                color: "#262626",
                fontSize: 16,
                fontWeight: "400",
                lineHeight: "20.83px",
                '& > .radio': {
                  position: "relative",
                  top: 0,
                  right: 0,
                  padding: 0,
                  marginLeft: "auto",
                },
              },
            },
          },
        },
        '& .errorText': {
          fontFamily: "DM Sans",
          color: "#000000",
          fontSize: 18,
          fontWeight: 400,
          lineHeight: "23.44px",
          margin: 0,
          textAlign: "center",
        },
        '&.gap-24': {
          gap: 24,
        },
        '&.items-center': {
          alignItems: "center",
        },
        '&.padding-44': {
          padding: 44
        },
      },
      rounded: {
        borderRadius: 16,
        '&.radius-12': {
          borderRadius: 12,
        },
      },
      elevation24: {
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#00000033",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        position: "absolute",
        top: 12,
        right: 12,
        '&:hover': {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiFormControl: {
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 32,
        '& > label': {
          fontFamily: "DM Sans",
          fontWeight: "500",
          fontSize: 22,
          lineHeight: "28.64px",
          color: "#262626"
        },
        '& > input': {
          padding: "10px 8px",
          color: "#262626",
          fontFamily: "DM Sans",
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "20.83px",
          border: "1px solid #C3D1FF",
          borderRadius: 12,
          '&:focus': {
            outline: 'none'
          },
          '&::placeholder': {
            color: "#BFBFBF"
          },
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
        gap: 12,
        '& > button': {
          all: "unset",
          cursor: "pointer",
          textAlign: "center",
          boxSizing: "border-box",
          borderRadius: 8,
          padding: "10px 16px",
          border: "1px solid #8C8C8C",
          width: 150,
          color: "#8C8C8C",
          fontFamily: "DM Sans",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "24px",
        },
        '& .create-btn': {
          backgroundColor: "#355EE7",
          borderWidth: 0,
          color: "#FFFFFF",
        },
        '& .delete': {
          borderWidth: 0,
          backgroundColor: "#F35F5F",
          color: "#FFFFFF",
          flex: 1,
        },
        '& .font-700': {
          fontWeight: 700
        },
        '& .font-500': {
          fontWeight: 500
        },
      },
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: 0,
        },
      },
    },
  },
})

// Customizable Area End

import ChatController, {Props,DataResponse,MessageData, ChatRoom, DateInput, Attachment } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  leftDrawerSearch = () => {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          background: "white",
          border: 'solid 1px #E6EBFF',
          borderRadius: '10px',
          padding: "9px 10px",
          marginTop: 2,
          width:'88.2%'
        }}
      >
        <SearchOutlinedIcon style={{ color: "gray" }} />
        <input
          type="text"
          placeholder="Search by Lawyer name"
          style={{
            border: "none",
            outline: "none",
            background: "transparent",
            marginLeft: 8,
            flexGrow: 1,
          }}
          value={this.state.searchLawyer} 
          onChange={this.handleSearchChange2} 
    data-test-id='searchFieldTest'
        />
      </Box>
    )
  }
  truncateMessage(fullText: string) {
    const maxLength = 31;
    return fullText?.length > maxLength ? `${fullText.substring(0, maxLength)}..` : fullText;
  }

  getTimeDifference = (timestamp: string) => {
    if (!timestamp) return "N/A";
  
    const now = new Date();
    const messageDate = new Date(timestamp);
  
    if (isNaN(messageDate.getTime())) return "N/A"; 
  
    const diffInMs = now.getTime() - messageDate.getTime(); 
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
  
    if (diffInMinutes < 5) {
      return "Now"; 
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}m`; 
    } else if (diffInHours < 24) {
      return `${diffInHours}h`; 
    } else if (diffInDays < 7) {
      return `${diffInDays}d`; 
    } else {
      return `${diffInWeeks}d`; 
    }
  };
  
  renderHeaderSection = () => {
    const { archived } = this.state; 
    let tokenData = JSON.parse(String(localStorage.getItem("token")))
    const userRole = tokenData?.user_role;
    const lawfirm = this.state.taskView.attributes.lawfirm_as_lawyer
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: userRole === "lawyer" ||lawfirm  ? "73px" : "0px", 
          width: archived ? "94%" : "75%",
        }}
      >
        <Typography style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <img
            style={{ width: "44px", height: "44px" }}
            src={archived ? archivepng : group2}
            alt=""
          />
          {archived ? "Archived Messages" : "Messages"}
        </Typography>
        
        {archived && (
          <CloseIcon
            onClick={this.toggleArchive}
            style={{
              color: "#4872FF",
              width: "20px",
              height: "20px",
            }}
          />
        )}
      </Box>
    );
  };
  
  getAvatarContent2 = (lawyer: ChatRoom) => {
    if (lawyer.user_profile) {
      return lawyer.user_profile; 
    }

    return lawyer.user_first_name ? lawyer.user_first_name.charAt(0).toUpperCase() : "N/A";
  };
  renderUserActions = () => {
    const { archived } = this.state;
    let tokenData = JSON.parse(String(localStorage.getItem("token")))
    const userRole = tokenData?.user_role;
    if (userRole === "lawyer" && userRole===  "lawfirm_admin") return null; 
    
  
    return (
      <Box style={{ display: "flex" }}>
        {!archived && (
          <Typography style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <img onClick={this.handleOpenChatBox} src={group} alt="" />
            <img onClick={this.toggleArchive} src={group1} alt="" />
          </Typography>
        )}
      </Box>
    );
  };
  renderLawyerProfile = (lawyer: any) => {
    if (lawyer.user_profile) {
      return (
        <img
          style={{
            width: "44px",
            height: "44px",
            objectFit: "cover",
          }}
          src={lawyer.user_profile?.url ?? undefined}
          alt="User Avatar"
        />
      );
    }
    return this.getAvatarContent2(lawyer);
  };
  renderNoChatsMessage = () => {
    const { searchLawyer } = this.state;
    let tokenData = JSON.parse(String(localStorage.getItem("token")))
    const userRole = tokenData?.user_role;
  
    return (
      <Box
        style={{
          background: "white",
          display: "flex",
          gap: "6px",
          justifyContent: "center",
          alignItems: "center",
          height: "79%",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        {!searchLawyer &&userRole !== "lawyer" && userRole !==  "lawfirm_admin"&& ( <img src={group} alt="" />)}
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: "DM Sans",
            color: "#595959",
          }}
        >
          {searchLawyer  ? "No Chats Found" : "No Chats Started"}
        </Typography>
        {!searchLawyer && userRole !== "lawyer" && userRole !==  "lawfirm_admin"&& (
          <>
            <span
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "DM Sans",
                color: "#BFBFBF",
              }}>
              Please start a chat by clicking
            </span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "DM Sans",
                color: "#BFBFBF",
              }}
            >
              on the icon over the search bar
            </span>
          </>
        )}
      </Box>
    );
  };
  getBadgeClass = (latestMessageTime: Date | string) => {
    if (!latestMessageTime) return "badge-grey"; 
  
    const now = new Date();
    const messageDate = new Date(latestMessageTime);
  
    if (isNaN(messageDate.getTime())) return "badge-grey"; 
  
    const diffInMs = now.getTime() - messageDate.getTime();
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  
    return diffInMinutes <= 15 ? "badge-blue" : "badge-grey"; 
  };
  leftDrawerBottom = () => { 
    
    const { loading} = this.state; 
       const chatRoomId = sessionStorage.getItem("chat_room_id")
   const parsedChatRoomId = chatRoomId ? Number(chatRoomId) : 0;
   const latestMessageTime = this.getLatestMessageTime(parsedChatRoomId) ?? new Date()
    const badgeClass = this.getBadgeClass(latestMessageTime);
    return (
      <Box style={{ backgroundColor: "#fff", borderRadius: 2, padding: 2, boxShadow: "1px" }}>
       <Box style={{ display: "flex", justifyContent: "center" }}>
       {this.renderHeaderSection()} 
       {this.renderUserActions()}

    
      </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', gap: '5px' , width:'100%'}}>
        {this.leftDrawerSearch()}</Box>
        <Box
          style={{
            maxHeight: "535px",
            border: "2px solid #F0F0F0",
            borderRadius: "8px",
            margin: "10px",
            height: "auto",
            overflowY: "auto",
            marginTop: 2,
            overflowX:'hidden'
          }}
        >
        <Box
          style={{
            maxHeight: "500px",
            borderRadius: "8px",
            margin: "9px 10px 10px",
            height: "500px",
            overflowY: "auto",
            overflowX:'hidden'
          }}
        >
          {loading ? (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <CircularProgress 
              data-test-id="circular-loader" 
              style={{ color: "#355EE7", width: 40, height: 40 }} 
              />
            </Box>
          ) : 
      
          <Box>
            
<List style={{ maxHeight: "400px", overflowY: "auto" }}>
  {this.state.userList && this.state.userList.length > 0 ? (
    this.state.userList.map((lawyer, index) => (
      <ListItem
      key={lawyer.chat_room_id || index}
        style={{ height: "13%",gap:"40px",marginBottom:'4px',marginTop:"2px",
        }}
        button
        data-test-id="clickButton"
        onClick={() => this.handleLawyerSelect2(lawyer.chat_room_id)}
      >
        
        <Box
          style={{
            width: "99%",
            display: "flex",
            borderBottom: "1px solid #C3D1FF",
            marginTop:"9px",
            gap: "6px",
          }}
        >
          <ListItemAvatar>
             <StyledBadge
                 className={badgeClass}
                                        overlap="circular"
                                        variant="dot"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                      >
                                    <Avatar
              style={{
                marginBottom: "3%",
                width: "44px",
                height: "44px",
                marginLeft: "3px",
                textTransform:"capitalize",objectFit:"cover"
              }}
              >   {this.renderLawyerProfile(lawyer) as any}
            </Avatar>
                                      </StyledBadge>
          
          </ListItemAvatar>
          <Box style={{ display: "flex", flexDirection: "column" ,width:"76%"}}>
            <Box style={{ display: "flex", gap: "15px", fontFamily: "Dm sans"}}>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Dm sans",
                  justifyContent:"space-between",
                  display:'flex',
                  width:'100%',
                  gap:"3px",
                  textTransform:"capitalize"
                }}
              >
                {lawyer.user_first_name} {lawyer.user_last_name}
                <Typography
            style={{
              fontSize:"12px",
             borderRadius:"100px",
             display:"flex",
             justifyContent:"center",
             alignItems:"center",width:"20px",
             height:"20px",
             color:'#8C8C8C'
            }}><span style={{
              display:"flex",
              alignItems:"center"
            }}>  {this.getTimeDifference(lawyer.last_message_at)}</span></Typography>
              </Typography>
            </Box>
            <Typography
  style={{
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    display: "flex",
    justifyContent: "space-between",
    gap: "6px",
    marginBottom: "11px",
    textOverflow: "ellipsis",
  }}
>
  <span style={{ color: lawyer.unread_count > 0 ? "#355EE7" : "inherit" }}>
    {this.truncateMessage(lawyer.last_message)}
  </span>

  {lawyer.unread_count > 0 && ( 
    <Typography
      style={{
        fontSize: "10px",
        border: "1px solid #355EE7",
        background: "#355EE7",
        borderRadius: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "20px",
        height: "20px",
        color: "white",
      }}
    >
      {lawyer.unread_count}
    </Typography>
  )}
</Typography>

          </Box>
        </Box>
      </ListItem>
    ))
  ) : (
    this.renderNoChatsMessage()
  )}
</List>
          </Box>
  }
        </Box>
        </Box>
      </Box>
    );
  };
  leftDrawer =()=>{
    return (
      <Box style={{ width: '368px' }}>
        <Box style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Button data-test-id="sidetoggle" onClick={() => this.toggleSidebar('openSecondCollapse')}>
          </Button>
        </Box>
        <Box><Typography>{this.state.taskView.attributes.state === 'in_progress' && (
                      <Chip
                        label='IN PROGRESS'
                        className={'chipInProgress'}
                        style={{
                          width: '98px',
                          height: '22px',
                          borderRadius: '40px',
                          fontFamily: "Dm sans",
                         marginLeft:"9px" }} />
                    )}</Typography></Box>
        <Box style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '6px', padding: '10px', backgroundColor: 'white' }}>
          <img src={filelogo} alt="logo" style={{ width: '44px', height: '44px' }} />
          <Typography style={{
            fontFamily:"DM Sans",
            fontWeight:400,
            display:"flex",
            flexDirection:"column",
            textTransform:"capitalize"
          }}>{this.state.taskView?.attributes?.task_name} <Typography style={{fontSize:"10px",
            fontWeight:400,
            fontFamily:"DM Sans",
            background:"#F5F5F5",
            color:"black",
            borderRadius:"10px",
            width:"50px"
          }}> {this.state.taskView.attributes.status}</Typography></Typography>
     
        </Box>
        <Box style={{ display: 'flex', flexDirection:"column", justifyContent: 'start', gap: '6px', padding: '10px', backgroundColor: 'white' }}>
          <Box style={{
            display:"flex",
            flexDirection:"column",
            gap:"6px"
          }}>
            <span style={{
              fontSize:"12px",
              fontWeight:400,
              fontStyle:"DM Sans",
              color:"#8C8C8C"
            }}>Trademark name</span>
          <Typography style={{
              fontSize:"16px",
              fontWeight:500,
              fontStyle:"DM Sans"
            }}>{this.state.taskView.attributes.trademark_name|| "N/A"}</Typography>
          </Box>
          <Box style={{
            display:"flex",
            flexDirection:"column",
             gap:"6px"
          }}>
            <span style={{
              fontSize:"12px",
              fontWeight:400,
              fontStyle:"DM Sans",
              color:"#8C8C8C"
            }}>Trademark Application Number</span>
          <Typography style={{
              fontSize:"16px",
              fontWeight:500,
              fontStyle:"DM Sans"
            }}>{this.state.taskView.attributes.trademark_application_number || "N/A"}</Typography>
          </Box>
        <Divider style={{
          marginTop:"9px",
          width:"113.5%",
          marginLeft:"-6%"
        }}/>
     
          </Box>
        {this.leftDrawerBottom()}
      
      </Box>
    )
  }
  drawerDocument = () =>  (  <Drawer variant="permanent" open={this.state.mobileOpen}>
      <Box style={{ display: "flex", flexDirection: "column", height: "100%", width: "15.75rem", backgroundColor: "white", padding: "1rem", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
        <Box style={{ display: "flex", justifyContent: "start", paddingLeft: '10px', marginBottom: '35px', marginTop: '30px' }}>
          <img src={logo.default} alt="Logossss" style={{ height: "3rem" }} />
        </Box>
        <Typography style={{ color: "gray", fontSize: "0.875rem", padding: "0.5rem 1rem" }}>Actions</Typography>
        <Box>
          <Box
            data-test-id="settingid"
            onClick={this.handleBackNavigationOrDashboardNavigation("dashboard")}
            style={{
          display: "flex" ,
          alignItems: "center",
          padding: "0.5rem 1rem",
          borderRadius : "5px" ,
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#ECF1FF"} 
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", height: "48px" }}>
          <HomeOutlinedIcon style={{ width: "1.5rem", height: "1.5rem" }} />
          <Typography style={{ width: "12.75rem" }}>Dashboard</Typography>
        </div>
      </Box>
    </Box>
  </Box>
</Drawer>


  )

  drawer = () => (
    <Drawer variant="permanent">
      <Box style={{ display: "flex", 
        justifyContent: 'start', height: "100%", 
        width: "23.75rem", backgroundColor: "white", 
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", overflowX:'hidden' }}>
        {this.rightDrawer()}
        {this.leftDrawer()}

      </Box>

    </Drawer>


  )
  arrow = (isOpen: boolean) => {
    return isOpen ? ( <img src={rightarrow} alt="arrow" style={{ textAlign: "left" }} />
    ) : 
    (
    <img src={arrowpng} alt="arrow" />
    );
  };

  rightDrawer = () => { return (
    <Box style={{ maxWidth: '100px', borderRight: '1px solid #C3D1FF', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center',padding:'5px' }} >
        <div className="toolbar" />
        <Divider />
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Button data-test-id="toggleTest" onClick={() => this.toggleSidebar('openCollapse')}>
            {this.arrow(this.state.openCollapse)}
          </Button>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
          <img src={secondLogo} alt="logo" />
        </div>
        {this.state.mobileOpen &&
          <CloseIcon style={{ position: 'fixed', top: '21px', left: '337px', cursor: 'pointer' }}
            onClick={this.handleclosesidebar} data-test-id="closeSideBarTest" />
        }
        <Typography style={{
          fontSize: "14px",
          fontWeight: 400,
          textAlign: 'center',
          color: '#BFBFBF',
          marginTop: '20px'
        }}>Actions</Typography>
        <div data-test-id="navigateDashboard" style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
        onClick={this.handleBackNavigationOrDashboardNavigation("dashboard")}
        >
          <img src={hometab} style={{ width: '24px', height: '24px' }} />
        </div>
        <div style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-test-id="settingid">
          <img src={filetab} style={{ width: '24px', height: '24px' }} />
        </div>
        <div style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-test-id="settingid">
          <img src={chattab} style={{ width: '24px', height: '24px' }} />
        </div>
        <div style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-test-id="settingid" >
          <img src={weighttab} style={{ width: '24px', height: '24px' }} />
        </div>
        <div style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-test-id="settingid">
          <img src={threefolder} style={{ width: '24px', height: '24px' }} />
        </div>
        <div style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-test-id="settingid" >
          <img src={chattab} style={{ width: '24px', height: '24px',color:"blue" }} />
        </div>
        <Typography style={{
          fontSize: "14px",
          fontWeight: 400,
          textAlign: 'center',
          color: '#BFBFBF',
          marginTop: '20px'
        }}>Manage</Typography>
        <div style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-test-id="settingid" 
        >
          <img src={paymenttab} style={{ width: '24px', height: '24px' }} />
        </div>
        <div style={{ width: '42px', height: '42px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-test-id="settingid" 
        >
          <img src={settingtab} style={{ width: '24px', height: '24px' }} />
        </div>
      </Box>
    )
  }

  buttonMenu = () => 
    (
  <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        data-test-id="DrawerToggleTest"
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />
      </IconButton>
    </ButtonBox>
  )

  getLatestMessageTime = (chatRoomId: number) => {
    const { userList } = this.state;

    if (!Array.isArray(userList) || userList.length === 0) {
        return "N/A";
    }

    const channelSid = sessionStorage.getItem("channelSid");

    const matchedUser = userList.find(user => user.chat_room_id === chatRoomId);
    const matchedUser2 = userList.find(user => user.channel_sid === channelSid);

    const lastMessage1 = matchedUser?.last_message_at || null;
    const lastMessage2 = matchedUser2?.last_message_at || null;

    // If both are null, return "N/A"
    if (!lastMessage1 && !lastMessage2) {
        return "N/A";
    }

    // If only one exists, return that one
    if (!lastMessage1) return lastMessage2;
    if (!lastMessage2) return lastMessage1;

    // Compare timestamps and return the latest one
    return new Date(lastMessage1) > new Date(lastMessage2) ? lastMessage1 : lastMessage2;
};

  
  
  getArchivedTextColor = () => {
    return this.state.archived ? "red" : "#8C8C8C";
  };
  renderArchiveToggle = () => {
    const { archived } = this.state;
  
    return (
      <Typography  style={{
        gap: "5px",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "DM Sans",
        display: "flex",
        alignItems: "center",
      }} onClick={() => this.handleArchiveStatus(archived ? "Restore" : "Archive")}>
        {archived ? "Restore" : "Archive"}
      </Typography>
    );
  };
  getUserProfileUrl(chatRoomId: number): string | null {
    const channelSid = sessionStorage.getItem("channelSid");

    const userList = Array.isArray(this.state.userList) ? this.state.userList : [];

    // Find user by chatRoomId or channelSid
    const user = userList.find(u => u?.chat_room_id === chatRoomId) 
              || userList.find(u => u?.channel_sid === channelSid);

    return user?.user_profile?.url || null;
}

getUserJurisdiction(chatRoomId: number): string | null {
    const channelSid = sessionStorage.getItem("channelSid");

    const userList = Array.isArray(this.state.userList) ? this.state.userList : [];

    // Find user by chatRoomId or channelSid
    const user = userList.find(u => u?.chat_room_id === chatRoomId) 
              || userList.find(u => u?.channel_sid === channelSid);

    return user?.user_jurisdiction || null;
}

  renderMarkAsReadMenuItem = () => {
    let tokenData = JSON.parse(String(localStorage.getItem("token")));
    const userRole = tokenData?.user_role;
  
    if (userRole === "lawyer" || this.state.archived) return null;
  
    return (
      <MenuItem onClick={this.handleClose}>
        <Typography
          style={{
            gap: "5px",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "DM Sans",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CheckIcon
            style={{
              width: "16px",
              height: "16px",
            }}
          />
          Mark as read
        </Typography>
      </MenuItem>
    );
  };
  renderArchiveMenuItem = () => {
    let tokenData = JSON.parse(String(localStorage.getItem("token")));
    const userRole = tokenData?.user_role;
  
    if (userRole === "lawyer") return null;
  
    return (
    
      <MenuItem>
      <Typography
        style={{
          gap: "5px",
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "DM Sans",
          display: "flex",
          alignItems: "center",
        }}
      >
        {this.state.archived ? (
          <img 
            src={refresh.default} 
            alt="Refresh"
            style={{ width: "18px", height: "14px", display: "inline-block" }}
          />
        ) : ( 
          <DeleteOutlineSharpIcon style={{ width: "16px", height: "16px" }} />
        )}  
        {this.renderArchiveToggle()}
      </Typography>
    </MenuItem>
    );
  };
  renderFormattedTime = (chatRoomId:number, channelSid:number) => {
    const { archived } = this.state;

    const parsedChatRoomID = chatRoomId ? Number(chatRoomId) : 0;
    const parsedChannelSid = channelSid ? Number(channelSid) : 0;

    const selectedId = parsedChatRoomID || parsedChannelSid;

    const latestMessageTime = this.getLatestMessageTime(selectedId);

    return archived
        ? this.formatArchiveTime(latestMessageTime ?? new Date().toISOString())
        : this.formatTimeConnection(latestMessageTime ?? new Date().toISOString());

  };
  renderUserAvatar = (imageUrl: string | null, fallbackInitial: string) => {
    return imageUrl ? (
      <img
        style={{
          width: "44px",
          height: "44px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
        src={imageUrl}
        alt="User Avatar"
      />
    ) : (
      <span>{fallbackInitial}</span>
    );
  };
  renderUserBigAvatar = (imageUrl: string | null, fallbackInitial: string) => {
    return imageUrl ? (
      <img
        style={{
          width: "131px",
          height: "131px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
        src={imageUrl}
        alt="User Avatar"
      />
    ) : (
      <span>{fallbackInitial}</span>
    );
  };
  
  topBox = () => {
    const {selectedLawyers } = this.state;
   const chatRoomId = sessionStorage.getItem("chat_room_id")
  const channelSid = sessionStorage.getItem("channelSid");
  const parseChatRoomID = chatRoomId ? Number(chatRoomId) : 0;
   const parsedChannelSid = channelSid ? Number(channelSid) : 0;
    const selectedLawyerNames = selectedLawyers.length > 0 
    ? selectedLawyers.map((lawyer: { name: any; }) => lawyer.name).join(", ") 
    : "No lawyer selected";
    const imageUrl = this.getUserProfileUrl(parseChatRoomID);
    const jurisdictionName = this.getUserJurisdiction(parseChatRoomID);
    const fallbackInitial = selectedLawyers.length > 0 
  ? selectedLawyers[0]?.name?.charAt(0).toUpperCase() 
  : "U";
    return (
      <TopBox style={{
         background:"#F0F0F0"
      }} data-test-id={"topBoxTestId"}>
        <Box className="topBoxHeader">
         
          <Box style={{
            display:"flex",
            width:"100%",
            marginLeft:"5px",
            gap:"6px",
            background:"#F0F0F0"
          }}>
  <Avatar>
  {this.renderUserAvatar(imageUrl, fallbackInitial)}
</Avatar>



        
            <Box style={{
              display:"flex",
              flexDirection:"column",
              width:"100%",
              background:"#F0F0F0"
            }}>
            <Typography style={{
             color:"#262626",fontFamily:"DM Sans", fontWeight:600,
             display:"flex",
             alignItems:"center",
              fontSize:"16px",
              gap:"5px",
              textTransform:"capitalize"
          }}>{selectedLawyerNames}
           <VpnLockIcon style={{
            height: "18px",
            width: "18px",
            color: "#355EE7",}}/><span style={{
              color:"#8C8C8C",
              fontWeight:400,
              fontSize:"12px"
            }}>{jurisdictionName || "N/A"}</span> </Typography> <span style={{
              color: this.getArchivedTextColor(),
              fontFamily:"DM Sans", fontWeight:400,
              fontSize:"12px",
              display:"flex",
              alignItems:"center",
              gap:"5px"
            }}> {this.state.archived && (<Typography style={{width:"73px",
              height:"22px",
              borderRadius:"8px",
              background:"#F0F0F0",
              fontFamily:"DM Sans",
              fontWeight:400,
              fontSize:"10px",
              display:"flex",
              alignItems:"center",
              justifyContent:"center",
              color:"#434343"
            }}>ARCHIVED</Typography>)}  
         {this.renderFormattedTime(parseChatRoomID, parsedChannelSid)}</span> 
            </Box>
          
          </Box>
          <Box style={{
  gap: "5px",
  display: "flex",
  alignItems: "center",  
  whiteSpace: "nowrap"   
}}>
  <Typography style={{
    fontSize: "14px",
    color: '#4872FF',
    fontWeight: 400,
    borderRadius: "500px",
    background: "#ECF1FF",
    padding: "5px 10px", 
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    gap:"10px"
  }}>
    <CalendarTodayIcon style={{width:"20px",
      height:"20px"
    }}/>
    Schedule Meeting
  </Typography>
  <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{
            color: "#BFBFBF",
          }}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={this.state.anchorEl} 
          open={this.state.open}
          onClose={this.handleClose} 
        >
        {this.renderMarkAsReadMenuItem()}

          <MenuItem >
            <Typography style={{
              gap:"5px",
              fontSize:"16px",
              fontWeight:400,
              fontFamily:"DM Sans",
              display:"flex",
              alignItems:"center"

            }}>< ErrorOutlineSharpIcon style={{
              width:"16px",
              height:"16px"
            }}/>Task Info</Typography>
          </MenuItem>
          {this.renderArchiveMenuItem()}
        </Menu>
      </div>
</Box>
        </Box>
      </TopBox>
    )
  }
  getUserFullName = () => {
    const { userdata } = this.state;
    return `${userdata?.first_name || ""} ${userdata?.last_name || ""}`.trim();
  };


   getMessageText = (newMessage: string, nonMediaFiles: string[]) => {
    if (newMessage.trim()) {
      return nonMediaFiles.length > 0 
        ? `${newMessage} (${nonMediaFiles.join(", ")})` 
        : newMessage.trim();
    } else if (nonMediaFiles.length > 0) {
      return `(${nonMediaFiles.join(", ")})`;
    }
    return "";
  };
  
  
  handleSendMessage = () => {
    const { newMessage, messageList, attachments,selectedFiles } = this.state;
  
    if ((newMessage.trim() !== "" || selectedFiles.length > 0)) {
      const images = attachments.filter((file) => file.type.startsWith("image/"));
      const videos = attachments.filter((file) => file.type.startsWith("video/"));
      const nonMediaFiles = attachments
        .filter((file) => !file.type.startsWith("image/") && !file.type.startsWith("video/"))
        .map((file) => file.name);
  
      const messageText = this.getMessageText(newMessage, nonMediaFiles);
  
      const newSentMessage: MessageData = {
        id: messageList.length + 1,
        created_at: new Date().toISOString(),
        sender: {
          id: 1,
          type: "user",
          name: this.getUserFullName(),
          profile: { url: null },
          message: newMessage.trim(),
          created_at: new Date().toISOString(),
          unreadCount: 0,
          lastMessage: messageText.trim(),
          attachments: []
        },
        receiver: {
          id: 2,
          type: "lawyer",
          name: "Receiver Name",
          profile: { url: null },
          message: "",
          created_at: new Date().toISOString(),
          unreadCount: 0,
          lastMessage: messageText.trim(),
          attachments: []
        },
        images: images.map((file) => ({
          name: file.name,
          url: URL?.createObjectURL(file),
        })),
        videos: videos.map((file) => ({
          name: file.name,
          url: URL?.createObjectURL(file),
        })),
        pdfs: [], 
        text: messageText.trim(),
        time: new Date().toLocaleTimeString(),
        unreadCount: 0,
        lastMessage: "",
      };
  
      this.setState(
        { sending: true },
        () => {
          this.setState(
            (prevState) => ({
              messageList: [...prevState.messageList, newSentMessage],
              newMessage: "",
              filePreviews: [],
              videoPreviews: [],
              pdfPreviews: [],
              validFilesPreview:[]
            }),
            () => {
              this.messagesConversation();
              this.getUserList();
              this.getAllFolders();
              this.handleUpdateCalls();
              this.handleUpdateCalls();
              this.handleUpdateCalls();
              this.handleUpdateCalls2();
          
              this.scrollToBottom();
              this.setState({ selectedFiles: [], newMessage: "" });
            }
          );
        }
      );
    } else {
      this.handleUpdateCalls();
    }
  };

   formatTimeConnection = (dateString:DateInput) => {
    if (!dateString) return "N/A";
  
    const now = new Date();
    const messageDate = new Date(dateString);
  
    if (isNaN(messageDate.getTime())) return "Invalid Date";
  
    const diffInMs = now.getTime() - messageDate.getTime();
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
  
    if (now.toDateString() === messageDate.toDateString()) {
      return `Last connection today at ${messageDate.toLocaleTimeString([], {  
        hour: "2-digit", 
        minute: "2-digit", 
        hour12: true
      })}`;
    }
  
    if (diffInHours < 24) {
      return `Last connection ${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    }
  
    if (diffInDays === 1) {
      return "Last connection yesterday";
    }
  
    if (diffInDays < 7) {
      return `Last connection ${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    }
  
    return `Last connection ${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
  };
  
  
   formatTime = (dateString: string | number | Date) => {
    const date = new Date(dateString);  
    return date.toLocaleTimeString([], {  
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true, 
    });
  };
   formatArchiveTime = (dateString: string | number | Date): string => {
    
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date"; 
    }
  
    return new Intl.DateTimeFormat("en-GB", {
      weekday: "long",
      day: "2-digit",
      month: "long",
    }).format(date);
  };
  

  breakTextAfterWords = (text: string, maxWords = 15) => {
    const words = text.split(' ');
    const result: string[] = [];
    let line: any[] = [];
  
    words.forEach((word: any, index: number) => {
      line.push(word);
      if (line.length === maxWords || index === words.length - 1) {
        result.push(line.join(' ')); 
        line = [];  
      }
    });
  
    return result.join(' \n'); 
  };

  
  renderAvatar = (sender: { name: string; profile: { url?: string } | null }): JSX.Element | null => {
    if (sender?.name === this.getUserFullName()) {
      return null;
    }
  
    const imageUrl = sender?.profile?.url ?? "";
    const { isImageLoaded } = this.state;
  
    return (
      <ListItemAvatar style={{ alignSelf: "flex-start" }}>
        <Avatar style={{ backgroundColor: "#BFBFBF" }}>
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="User Avatar"
              onLoad={() => this.setState({ isImageLoaded: true })}
              onError={() => this.setState({ isImageLoaded: false })}
              style={{
                borderRadius: "50%",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                visibility: isImageLoaded ? "visible" : "hidden", 
              }}
            />
          ) : (
            sender.name[0] 
          )}
        </Avatar>
      </ListItemAvatar>
    );
  };
  
renderCurrentUserAvatar = (sender: { name: string; profile: { url?: string } | null }): JSX.Element | null => {
  if (sender?.name !== this.getUserFullName()) {
    return null; }

  const imageUrl = sender?.profile?.url ?? undefined;
  const { isImageLoaded } = this.state;

  return (
    <ListItemAvatar style={{ alignSelf: "flex-start" }}>
      <Avatar
        style={{backgroundColor: "#BFBFBF", 
        }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="User Avatar"
            onLoad={() => this.setState({ isImageLoaded: true })}
            onError={() => this.setState({ isImageLoaded: false })}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
              display: isImageLoaded ? "block" : "none",
            }}
          />
        ) :(
          sender.name[0] 
        )}
      </Avatar>
    </ListItemAvatar>
  );
};
renderSenderAvatar = (msg: { sender?: { name?: string; profile?: { url?: string | null } } }) => {
  if (!msg.sender) return null;

  return this.renderCurrentUserAvatar({
    name: msg.sender?.name ?? "Unknown",
    profile: msg.sender?.profile ? { url: msg.sender.profile.url ?? undefined } : null,
  });
};
renderReceiverAvatar = (msg: { sender?: { name?: string; profile?: { url?: string | null } } }) => {
  if (!msg.sender) return null;
  
  return this.renderAvatar ({
    name: msg.sender?.name ?? "Unknown",
    profile: msg.sender?.profile ? { url: msg.sender.profile.url ?? undefined } : null, });
};

getMessageAlignment = (senderName: string): "flex-end" | "flex-start" => {
  return senderName === this.getUserFullName() ? "flex-end" : "flex-start";
};
getAlignemnt = (senderName: string): "right" | "left" => {
  return senderName === this.getUserFullName() ? "right" : "left";
};
renderSenderDetails = (msg: MessageData) => {
  const isCurrentUser = msg.sender?.name === this.getUserFullName();
  const timeFormatted = this.formatTime(msg.sender?.created_at);

  return (
    <Typography
      style={{
        whiteSpace: "nowrap",
        fontWeight: 400,
        fontSize: "12px",
        fontFamily: "DM Sans",
        color: "#8C8C8C",
        display: "flex",
        gap: "4px",
        textTransform: "capitalize",
      }}
    >
      {isCurrentUser ? (
        <>
          <span style={{ marginRight: "8px" }}>{timeFormatted}</span>
          {msg.sender?.name}
        </>
      ) : (
        <>
          {msg.sender?.name}
          <span style={{ marginLeft: "8px" }}>{timeFormatted}</span>
        </>
      )}
    </Typography>
  );
};
renderInviteMessage = (selectedLawyerNames: string) => {
  const { messageList } = this.state;

  if (messageList.length > 1 && 
    messageList[0].receiver?.message !== "message" &&
    messageList[1].receiver?.message !== "message") {
    return null;
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        style={{
          fontFamily: "DM Sans",
          fontSize: "22px",
          fontWeight: "500",
          color: "#262626",
        }}
      >
        Your invite to {selectedLawyerNames} has been sent
      </Typography>
      <span
        style={{
          fontFamily: "DM Sans",
          fontSize: "16px",
          fontWeight: "400",
          color: "#8C8C8C",
          marginBottom: "3px",
        }}
      >
        You will chat with the user once your chat request has been accepted.
      </span>
    </Box>
  );
};
 getDownloadClass = (isDocument: boolean, isVideo: boolean) => {
  if (isDocument) {
    return "downloadButton";
  }
  if (isVideo) {
    return "downloadButtonVideo";
  }
  return "downloadButton2";
};
handleDownload = (e: { preventDefault: () => void; }, url: string | URL | Request, filename: string) => {
  e.preventDefault();

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);
      link.href = objectURL;
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    })
};
renderAttachment = (attachment: Attachment, index: number) => {
  const { url, content_type, filename, byte_size } = attachment;
  const specialFileTypes = [
    "application/pdf",
    "application/zip",
    "application/msword",
    "application/x-ole-storage",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const isDocument = specialFileTypes.includes(content_type);
  const isVideo = content_type.startsWith("video/");

  const downloadClass = this.getDownloadClass(isDocument, isVideo);

  const renderMedia = () => {
    if (content_type.startsWith("image/")) {
      return <Box className="imgBox" > <span className="imgBox2">{filename} </span><img src={url} alt={filename} className="media" /></Box>
    }
    if (content_type.startsWith("video/")) {
      return <Box className="imgBox" > <span className="imgBox2">{filename} </span> <video src={url} controls className="media" /></Box>
    }
    if (content_type === "application/pdf") {
      return   <img src={pdfI} alt="PDF Icon" width="50" height="50" style={{ borderRadius: "5px" }} />;
    }
    if (specialFileTypes.includes(content_type)) {
      return (
        <Box flex className="docBox">
          <img src={docxx} alt="Document Icon" className="docIcon" />
        </Box>
      );
    }
    return (
      <p className="fileText">
        {filename} <span>{this.formatFileSize(byte_size)}</span>
      </p>
    );
  };

  return (
    <div key={index} style={{ textAlign: "center", position: "relative", display: "flex", width: "376px", gap: "5px" ,left:"12px"}}>
      {renderMedia()}
      <a
          href={url}
          key={content_type}
          className={downloadClass}
          data-test-id="downloaded"
          onClick={(e) => this.handleDownload(e, url, filename)} 
        >
          <img src={downlaodIcon} alt="Download" className="downloadIcon" />
      
        </a>

      {["application/pdf", "application/zip",   "application/x-ole-storage", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"].includes(content_type) && (
        <p className="fileDetails">
          {filename} <span className="fileSize">{this.formatFileSize(byte_size)}</span>
        </p>
      )}
    </div>
  );
};

renderMessageItem = (msg: MessageData, index: any) => {
  return (
    <ListItem
      key={msg.id || index}
      style={{
        display: "flex",
        gap: msg.sender?.name === this.getUserFullName() ? "16px" : "3px",
        alignItems: "flex-start",
        justifyContent: this.getMessageAlignment(msg.sender?.name),
      }}
    >
 {this.renderReceiverAvatar(msg)}


      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: this.getMessageAlignment(msg.sender?.name),
            gap: "8px",
          }}
        >
          {this.renderSenderDetails(msg)}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: this.getMessageAlignment(msg.sender?.name),
          }}
        >
          <Typography
            style={{
              backgroundColor:
                msg.sender?.name === this.getUserFullName()
                  ? "#ECF1FF"
                  : "#F5F5F5",
              padding: "6px 12px",
              borderRadius: "15px",
              wordBreak: "break-word",
textAlign: this.getAlignemnt(msg.sender?.name),
              marginTop: 1,
              display: "flex",
              flexDirection:"column", 
              whiteSpace: "pre-wrap",
              width:" auto",
              overflowWrap: "break-word",
            }}
          >
            {this.breakTextAfterWords(
              `${msg.sender?.message || ""} ${msg.receiver?.message || ""}`
            )}
<Box style={{
  display:"flex",
  justifyContent: this.getMessageAlignment(msg.sender?.name),
}}>
{(msg.sender?.attachments?.length > 0 || msg.receiver?.attachments?.length > 0) && (
  <div style={{ display: "flex", gap: "10px", flexDirection:"column", whiteSpace: "nowrap"}}>
    {Array.from(
      new Map(
        [...msg.sender?.attachments, ...msg.receiver?.attachments]?.map(attachment => [
          attachment.url,
          attachment,
        ])
      ).values()
    ).map(this.renderAttachment)}
  </div>
)}
</Box>


          </Typography>
        </div>
      </div>

      {this.renderSenderAvatar(msg)}


    </ListItem>
  );
};
removePDF = (index: number) => {
  this.setState((prevState) => ({
    pdfPreviews: prevState.pdfPreviews.filter((_, i) => i !== index),
       newMessage:""
  }));
};
removeDoc = (index: number) => {
  this.setState((prevState) => ({
    validFilesPreview: prevState.validFilesPreview.filter((_, i) => i !== index),
       newMessage:""
  }));
};
removeVideo = (index: number) => {
  this.setState((prevState) => ({
    videoPreviews: prevState.videoPreviews.filter((_, i) => i !== index),
       newMessage:""
  }));
};
removeImage = (index: number) => {
  this.setState((prevState) => ({
    filePreviews: prevState.filePreviews.filter((_, i) => i !== index),
    newMessage:""
  }));
};
renderPreviewList = (
  previews: any[],
  removeFunction: (index: number) => void,
  type: string
) => {
  if (previews.length === 0) return null;

  const renderPreviewContent = (previewSrc: string) => {
    switch (type) {
      case "image":
        return (
          <img
            src={previewSrc}
            alt="Preview"
            style={{
              width: "54px",
              height: "52px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        );

      case "video":
        return (
          <video
            src={previewSrc}
            style={{ width: "54px", height: "52px", borderRadius: "5px" }}
            controls
          />
        );

      case "pdf":
        return <img src={pdfI} alt="PDF Icon" width="54px" height="52px" />;

      case "validfiles":
        return (
          <Box
            style={{
              width: "54px",
              height: "52px",
              background: "#C3D1FF",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DescriptionOutlinedIcon
              style={{
                width: "27px",
                color: "#355EE7",
                height: "25px",
              }}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <div style={{ display: "flex", gap: "18px", flexWrap: "wrap" }}>
      {previews.map((preview: any, index: number) => {
        const previewSrc = type === "validfiles" ? preview.image : preview;

        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              gap: "2px",
              padding: "6px",
              borderRadius: "8px",
              border: "1px solid #9AB1FF",
              width: "70px",
              height: "78px",
              background: "#ECF1FF",
            }}
          >
            <button
              onClick={() => removeFunction(index)}
              style={{
                position: "absolute",
                left: "67px",
                top: "0px",
                border: "none",
                borderRadius: "50%",
                width: "14px",
                height: "14px",
                cursor: "pointer",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "transparent",
              }}
            >
              <CloseIcon
                style={{ width: "13px", height: "13px", color: "#8C8C8C" }}
              />
            </button>

            {renderPreviewContent(previewSrc)}

            <div
              style={{
                width: "80%",
                height: "5px",
                background: "#355EE7",
                borderRadius: "4px",
                overflow: "hidden",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  width: `${this.state.uploadProgress?.[index] || 0}%`,
                  height: "100%",
                  background: "#4CAF50",
                  transition: "width 0.5s ease-in-out",
                }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

  messagesConversation = () => {
    const {  selectedLawyers,archived } = this.state;
  
    const chatRoomId = sessionStorage.getItem("chat_room_id")
    const channelSid = sessionStorage.getItem("channelSid");
    const parseChatRoomID = chatRoomId ? Number(chatRoomId) : 0;
     const parsedChannelSid = channelSid ? Number(channelSid) : 0;
  const selectedLawyerNames = selectedLawyers.length > 0 ? selectedLawyers.map((lawyer: { name: any; }) => lawyer.name).join(", ") : "No lawyer selected";
  const imageUrl = this.getUserProfileUrl(parseChatRoomID);
  const juisdictionName = this.getUserJurisdiction(parseChatRoomID)
  const fallbackInitial = selectedLawyers.length > 0 ? selectedLawyers[0]?.name?.charAt(0).toUpperCase() : "U";
    return (
      <Box>
        <List  ref={this.chatContainerRef}
        className="messageBoxUi">
        <Box style={{
          display:'flex',
          flexDirection:"column",
          alignItems:"center",
          marginBottom:"49px"
        }}>
<Avatar  style={{
        width:"131px",
          height:"131px",
          marginBottom:"5px",
        objectFit: "cover",
        border:"4px solid #DCECFF"
      }}>
{this.renderUserBigAvatar(imageUrl, fallbackInitial)}
</Avatar>


        <Typography style={{
          fontFamily:"DM Sans",
          textTransform:"capitalize",
          fontWeight:600,
          fontSize:"22px"
        }}>{selectedLawyerNames}</Typography>
        <Typography style={{
           fontFamily:'DM Sans',
           fontSize:"16px",
           fontWeight:"400",
           color: this.getArchivedTextColor()
        }}>  {this.renderFormattedTime(parseChatRoomID, parsedChannelSid)}</Typography>
        <Typography
                                variant="body2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "2px",
                                  fontFamily: "Dm sans",
                                  gap: "3px"
                                }}
                              >
                                <VpnLockIcon style={{
                                  height: "18px",
                                  width: "18px",
                                  color: "#355EE7",
                                }} /> <span style={{
                                  fontSize: "11px",
                                  fontFamily: "Dm sans",
                                  fontWeight: 400
                                }}>{juisdictionName || "N/A"}</span>
                              </Typography>
        </Box>
        {this.state?.messageList &&
  this.state.messageList?.map((msg, index) => this.renderMessageItem(msg, index))}
         
        </List>
        {this.renderInviteMessage(selectedLawyerNames)}
        <>
  {archived === false && (
    <>

<Box style={{
    display:"flex",
    justifyContent:"center"
  }}>
  <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "auto",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: "10px",
        backgroundColor: "#F9F9F9",
        boxShadow:"none",
        width:"92%",
        background:"#FAFAFA"
      }}
    >
 <TextField
  value={this.state.newMessage}
  onChange={(e) => this.setState({ newMessage: e.target.value })}
  placeholder={`Send a Message to ${selectedLawyerNames}`}
  fullWidth
  multiline
  rows={1}  
  rowsMax={3}
  InputProps={{
    disableUnderline: true,
    style: { 
      fontSize: "18px", 
      color: "black", 
      fontWeight: 400, 
      fontFamily: "Dm Sans", 
      whiteSpace: 'pre-wrap',  
      wordWrap: 'break-word',  
      resize: 'none' ,
      transition: "width 0.5s ease-in-out", 
    },
  }}
/>

<Box style={{
  display:"flex",
  gap:"10px"
}}>
{this.renderPreviewList(this.state.filePreviews, this.removeImage, "image")}
      {this.renderPreviewList(this.state.videoPreviews, this.removeVideo, "video")}
      {this.renderPreviewList(this.state.pdfPreviews, this.removePDF, "pdf")}
      {this.renderPreviewList(this.state.validFilesPreview, this.removeDoc, "validfiles")}
</Box>




      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IconButton onClick={this.handleOpenUploadDialog} style={{ color: "#8A2BE2" }}>
          <AttachFileIcon />
        </IconButton>
        <IconButton onClick={this.handleSendMessage}
          style={{
            backgroundColor: "#6200EA",
            color: "#FFFFFF",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Paper>
  </Box>
  </>
  )}
</>
 
       
      </Box>
    );
  };
  
  
  renderTopBox = () => {
    const {isInviteSent} = this.state
    return isInviteSent ? this.topBox() : null;
  };
  renderLoading() {
    return (
      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
      <CircularProgress 
      data-test-id="circular-loader" 
      style={{ color: "#355EE7", width: 40, height: 40 }} 
      />
    </Box>
    );
  }

  renderEmptyState() {
    let tokenData = JSON.parse(String(localStorage.getItem("token")))
    const userRole = tokenData?.user_role;

    return (
      <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Box
        style={{
          background: "white",
          display: "flex",
          gap: "6px",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <img style={{ width: "124px", height: "124px" }} src={newgroup} alt="" />
        <Typography
          style={{
            fontSize: "22px",
            fontWeight: 600,
            fontFamily: "DM Sans",
            color: "#262626",
          }}
        >
          Task Chat
        </Typography>
        <span
          style={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "DM Sans",
            color: "#BFBFBF",
          }}
        >
          In this section you will be able to communicate with the lawyers who
        </span>
        <span
          style={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "DM Sans",
            color: "#BFBFBF",
          }}
        >
          will be handling your case.
        </span>
        {userRole !== "lawyer" && !this.state.taskView.attributes.lawfirm_as_lawyer && (
  <Typography
    style={{
      fontFamily: "DM Sans",
      border: "1px solid #4872FF",
      borderRadius: "12px",
      color: "#4872FF",
      padding: "6px 10px",
      display: "flex",
      fontSize: "14px",
      fontWeight: 600,
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
      cursor: "pointer",
    }}
    onClick={this.handleOpenChatBox}
  >
    <img src={chatG} alt="" /> Start a new chat
  </Typography>
)}


      </Box>
    </Box>
    );
  }
  rightBox = () => {
   const {isInviteSent,loading}  = this.state
   const renderMainContent = () => {
    if (loading) return this.renderLoading();
    if (isInviteSent) return this.messagesConversation();
    return this.renderEmptyState();
  };
    return ( <RightBox data-test-id={"rightBoxTestId"}>
      {this.renderTopBox()}
        <MainBox>
        {renderMainContent()}

 
        </MainBox>
      </RightBox>
    )
  }
 
  getStyles() {
    const { createChat, selectedLawyers } = this.state;
    
    const isDisabled = createChat.message.trim() === '' || selectedLawyers.length === 0;  
    const backgroundColor = isDisabled ? "#F0F0F0" : "#355EE7";
    const textColor = isDisabled ? "#8C8C8C" : "#FFFFFF";
    const labelColor = this.state.hasError ? "red" : "black";  
    const borderColor = this.state.hasError ? '1px solid red' : '1px solid #C3D1FF';
  
    return { backgroundColor, textColor,labelColor, borderColor, isDisabled };
    
  }
  
  handleSendInvite = () => {
    this.setState(
      (prevState) => ({
        newMessage: prevState.createChat.message, 
        isInviteSent: true,
        chatDialog: false,
      }),
      () => {
        this.handleSendMessage(); 
        this.handleUpdateCalls3();
        this.sendInvite(this.state.newMessage)
        this.getUserList()
       this.handleCUpdate()
        this.getUserList()
         this.messagesConversation()
       
      }
    );
  };
  
  renderLawyersList = (lawyersArray: DataResponse[]) => {
    return lawyersArray.length > 0 ? (
      lawyersArray.map((lawyer, index) => (
        <ListItem
          key={lawyer.id || index}
          style={{ height: "45%", marginTop: "8px" }}
          button
          data-test-id="clickButton2"
          onClick={() => this.handleLawyerSelect(lawyer)}
        >
          <Box
            style={{
              width: "99%",
              display: "flex",
              borderBottom: "1px solid #C3D1FF",
              padding: "8px",
              gap: "6px",
            }}
          >
            <ListItemAvatar>
              <Avatar
                style={{
                  marginBottom: "3%",
                  width: "56px",
                  height: "56px",
                  marginLeft: "3px",
                }}
                src={userProfile}
              />
            </ListItemAvatar>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box style={{ display: "flex", gap: "15px", fontFamily: "Dm sans" }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    fontFamily: "Dm sans",
                    marginLeft: "2px",
                  }}
                >
                  {lawyer.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ListItem>
      ))
    ) : (
      <Typography style={{ padding: "16px" }}>No Current Associated Lawyers</Typography>
    );
  };
  getMainDivStyles = () => {
    const { mobileOpen } = this.state;
  
    return {
      maxHeight: mobileOpen ? '100vh' : '',
      overflow: mobileOpen ? 'hidden' : '',
      position: 'relative' as 'relative', 
    };
  };
  getErrorState = () => {
    return this.state.isSizeExceeded || this.state.isWrongFileMimeType;
  };

renderFileUploadSection = () => {
  return (
    <>
      <Box style={{ width: "26%", display: "flex", alignItems: "center", gap: "8px" }}>
        <span style={{ display: "block", borderBottom: "1px solid #9AB1FF", width: "100%" }}></span>
        <Typography style={{ display: "flex", justifyContent: "space-between" }}>Or</Typography>
        <span style={{ display: "block", borderBottom: "1px solid #9AB1FF", width: "100%" }}></span>
      </Box>

      <Box>
        <label htmlFor="fileUploadInput" style={{ position: "relative", display: "inline-block" }}>
          <Button
            component="span"
            style={{
              backgroundColor: "#355EE7",
              borderRadius: "8px",
              width: "150px",
              height: "36px",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Dm sans",
              textTransform: "none",
              color: "#FFFFFF",
              cursor: "pointer",
            }}
          >
            Browse Files
          </Button>
          <input
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
            data-test-id="fileUploadInputId"
            accept="image/png, image/jpg, image/jpeg, video/mp4, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
            type="file"
            id="fileUploadInput"
            multiple
            onChange={this.handleFileChange}
          />
        </label>
      </Box>
    </>
  );
};
renderErrorBox = () => {
  const { isSizeExceeded, isWrongFileMimeType } = this.state;

  if (!(isSizeExceeded || isWrongFileMimeType)) {
    return null;
  }

  return (
    <Box
      style={{
        borderRadius: "8px",
        background: "#FEE2E2",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        justifyContent: "space-between",
        position: "relative",
        top: "2%",
      }}
      data-error={this.getErrorState()}
    >
      <div>
        {isWrongFileMimeType && (
          <p
            style={{
              color: "#DC2626",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "DM Sans",
              margin: 0,
              gap: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "24px",
                height: "24px",
              }}
              src={errorIcon}
              alt=""
            />
            File format not supported
          </p>
        )}
        {isSizeExceeded && (
          <p
            style={{
              color: "#DC2626",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "DM Sans",
              margin: 0,
              gap: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "24px",
                height: "24px",
              }}
              src={errorIcon}
              alt=""
            />
            Maximum file size exceeded
          </p>
        )}
      </div>

      <IconButton
        onClick={this.handleCloseErrorBox}
        style={{
          width: "24px",
          height: "24px",
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

handleSaveFiles = async () => {
  if (this.state.selectedUploadedFile.length > 0) {
    const dataTransfer = new DataTransfer();

    await Promise.all(
      this.state.selectedUploadedFile.map(async (file) => {
        try {
          const response = await fetch(file.url);
          const blob = await response.blob();
          const actualFile = new File([blob], file.filename, { type: file.content_type });
          dataTransfer.items.add(actualFile);
        } catch (error) {
          console.error("Error converting file:", error);
        }
      })
    );

    const fileList = dataTransfer.files;
    this.processFiles(fileList);

    this.setState({
      isSaveEnabled: false, 
      selectedUploadedFile:[],
      uploadDialog: false, 
    });
  }
};


 handleFileClick = async (file: { url: string; filename: string; content_type: string }) => {
  try {
    const response = await fetch(file.url);
    const blob = await response.blob();

    const selectedFile = new File([blob], file.filename, { type: file.content_type });

    const customFile = {
      url: file.url,
      filename: file.filename,
      content_type: file.content_type,
      file: selectedFile, 
    };

    this.setState({
      selectedUploadedFile: [...this.state.selectedUploadedFile, customFile], 
      isSaveEnabled: true, 
    });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(selectedFile);
    const fileList = dataTransfer.files;
    
    this.processFiles(fileList);
  } catch (error) {
    console.error("Error fetching file:", error);
  }
};
 getFileImageUrl = (file: { content_type: string; url: any; }) => {
  if (file.content_type === "application/pdf") {
    return pdfI;  
  }

  if (file.content_type.startsWith("image/") || file.content_type.startsWith("video/")) {
    return file.url;  
  }

  const specialFileTypes = [
    "application/pdf",
    "application/zip",
    "application/msword",
    "application/x-ole-storage",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  if (specialFileTypes.includes(file.content_type)) {
    return doc;  
  }


  return doc; 
};

 getButtonConditionalStyles = (isSaveEnabled: boolean) => ({
  backgroundColor: isSaveEnabled ? "#355EE7" : "#F0F0F0",
  color: isSaveEnabled ? "white" : "#8C8C8C",
  cursor: isSaveEnabled ? "pointer" : "not-allowed",
});

 getButtonStyles = (isSaveEnabled: boolean) => ({
  ...this.getButtonConditionalStyles(isSaveEnabled), 
  borderRadius: "8px",
  width: "150px",
  height: "44px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Dm sans",
  textTransform: "none" as "none",
});



renderUploadedFiles = () => {
  if (!this.state.uploadedFil || this.state.uploadedFil.length === 0) {
    return null; 
  }
  
  return this.state.uploadedFil.map((file, index) => {
  
    const imageUrl = this.getFileImageUrl(file);

  
    const trimmedFileName =
      file.filename.length > 10 ? file.filename.substring(0, 10)  : file.filename;


    return (
      <Box
        key={index}
        data-test-id="clickedd"
        onClick={() => this.handleFileClick(file)}
        style={{
          border: "1px solid #9AB1FF",
          borderRadius: "8px",
          width: "150px",
          height: "158px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 0px 1px 0px",
          marginBottom: "5px",
          cursor: "pointer",
          background: "white",
        }}
      >
        <Typography
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            width: "126px",
            height: "110px",
            background: "#F5F5F5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         {file.content_type.startsWith("video/") ? (
            <video style={{ width: "55px", height: "55px" }} src={file.url} controls />
          ) : (
            <img style={{ width: "45px", height: "55px" }} src={imageUrl} alt={file.filename} />
          )}
        </Typography>
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "DM Sans",
            display: "flex",
            justifyContent: "flex-start",
            width: "83%",
          }}
        >
          {trimmedFileName}
        </Typography>
      </Box>
    );
  });
};


  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openPop, searchQuery,isSizeExceeded, isWrongFileMimeType } = this.state; 
    console.log(this.state.selectedLawyers,"merrrrrrrrr");
    
    const lawyersArray = this.state.searchResults ? Object.values(this.state.searchResults) : [];
    const { backgroundColor, labelColor, borderColor } = this.getStyles();
    console.log(this.state.uploadedFil,"uploaded")
    return (
      <MainContainer data-test-id="chatwebId">
      <Box style={{ display: "flex", justifyContent: "start", gap: "10px", alignItems: "start" }}>
                <Box className="navigationstyle"  >
                  <NavigationMenu navigation={this.props.navigation} id={""} />
                </Box>
       <MainDiv>
                  
                <Box className="gridDiv1" >
                                {this.leftDrawer()}
                                <Divider orientation="vertical" flexItem style={{ marginLeft: '16px' }} />
                                </Box>
                <Box className="gridDiv3" data-test-id="rightbar">
                  {this.rightBox()}
                </Box>
              
               <Box style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '24px',
    fontWeight: 'bold'
  }}>
   {this.state.chatDialog && (
<CustomizeDialog data-test-id="customize3" >

<Box style={{
marginLeft: "24px", fontFamily: "Dm sans", fontWeight: 500,
fontSize: "22"
}}>
<Typography style={{
display: "flex",
height: "30px"
}}>
<IconButton
onClick={this.handleCloseChatBox}
style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
>
<CloseIcon />
</IconButton>
</Typography>

<Typography style={{
  fontSize:"22px",
  fontWeight:500,
  fontFamily:"Dm Sans",
  color:"#262626"
}}> New Chat</Typography>
<Typography
style={{ fontSize: "14px", fontWeight: 400, color: "#8C8C8C ", fontFamily: "Dm sans", width: "98%" }}>Before starting a chat you must send a request to the other participant and you can only invite lawyers who are working on this task.</Typography>
</Box>
<DialogContent style={{
marginTop: "11px",
overflow:"visible"
}} >
<Box display="flex" flexDirection="column">
<Box>

<Box style={{
                  position:"relative"
                 }}>
                  <TextField
                    placeholder="Search by Lawyer name"
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{
                            color: "DADADA",
                            width: "24px",
                            height: "24px"
                          }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      inputProps: {
                        style: {

                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    className="selectBox"
                  />
                  {openPop && (
            
            <Box
              style={{
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "96.5%",
                left: "50%",
                transform: "translate(-50%,1%)",

                zIndex: 1300,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                height: "250px",
                overflowY: "auto",
                width: "98%"
              }}
            >

 <List style={{ maxHeight: "400px", overflowY: "auto" }}>
      {this.renderLawyersList(lawyersArray)}
    </List>

            </Box>
        )}
                  </Box>
                  {this.state.selectedLawyers.length > 0 && (
                    <Box mt={2} className="selectedUser">
                      {this.state.selectedLawyers?.map((lawyer, index) => (
                        <Box data-test-id={`mapFunction${index}`}
                        key={lawyer?.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: '1px solid #C3D1FF',
                            borderRadius: "10px",
                            alignItems: "center",
                            gap: "8px",
                            padding: "6px",
                            fontFamily: "Dm sans",
                          }}

                        >
                          <Avatar src={group} style={{
                            marginBottom: "3%",
                            width: "56px",
                            height: "56px",
                            marginLeft: "3px"
                          }} />
                          <Box>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                                fontFamily: "Dm sans",
                                width: "100%"
                              }}
                            >
                              <Typography style={{ fontWeight: 400, fontSize: "14px", fontFamily: "Dm sans", marginLeft: "2px" }}>
                                {lawyer.name}  
                              </Typography>
                            
                            </Box>
                            <Box style={{
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "2px",
                                  fontFamily: "Dm sans",
                                  gap: "3px"
                                }}
                              >
                                <VpnLockIcon style={{
                                  height: "18px",
                                  width: "18px",
                                  color: "#355EE7",
                                }} /> <span style={{
                                  fontSize: "11px",
                                  fontFamily: "Dm sans",
                                  fontWeight: 400
                                }}>america</span>
                              </Typography>
                            </Box>
                          
                          </Box>
                          <IconButton data-test-id="iconButton"                
                         onClick={() => this.handleRemoveSelectedLawyer(lawyer)}
                            style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
</Box>
</Box>
<Box>
<Box
style={{
display: "flex",
justifyContent: "space-between",
alignItems: "center",
width: "100%"
}}
>
<Typography style={{
fontWeight: 700,
fontSize: "14px",
marginBottom: "4px",
fontFamily: "Dm sans",
color: labelColor
}}>
Message
<span style={{ color: "red" }}>*</span>
</Typography >


</Box>
<TextareaAutosize
onChange={this.handleChangeMessage}
  placeholder="Write a first message in this invite"
  style={{
    width: "100%",
    height: "150px",
    borderRadius: "12px",
    border: borderColor,
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Dm Sans",
    marginBottom: "10px",
    padding: "8px",
    color: "black", 
  }}
  className="custom-textarea"
/>

</Box>
</DialogContent>
<DialogActions style={{
width: "94%"
}}>
<Button
data-test-id="cancelBtn3"
onClick={this.handleCloseChatBox}
style={{
border: "1px solid #8C8C8C",
width: "150px",
height: "44px",
display: "flex",
alignItems: "center",
borderRadius: "8px",
fontSize: "16px",
fontWeight: 700,
fontFamily: "Dm sans",
color: "#8C8C8C",
textTransform: "none",
}}
>
Cancel
</Button>
<Button
  data-test-id="reject1"
  onClick={this.handleSendInvite} 
  style={{
    backgroundColor: backgroundColor,
    borderRadius: "8px",
    width: "150px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Dm sans",
    textTransform: "none",
    color: this.getStyles().isDisabled ? "#8C8C8C" : "#FFFFFF", 
    cursor: this.getStyles().isDisabled ? "not-allowed" : "pointer", 
    pointerEvents: this.getStyles().isDisabled ? "none" : "auto", 
  }}
  disabled={this.getStyles().isDisabled} 
>
  Send Invite
</Button>

</DialogActions>

</CustomizeDialog>
)}
  {this.state.uploadDialog && (
<CustomizeDialog2 data-test-id="customize4" >
              <Box className="uploadBox">
                <Box style={{display:'flex', justifyContent:'space-between', alignItems:"center"}}>
              
                  <Box className="uploadHeading" >Upload Files</Box>
                  <IconButton
            onClick={this.handleClosUploadDialog}
            style={{
              width: "24px",
              height: "24px",
            }}
          >
            <CloseIcon />
          </IconButton>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '12px' ,marginTop:"21px"}}>
                  <Box
                    data-test-id="uploadDragZoneId"
                    className="uploadDragZone"
                    data-error={this.getErrorState()}
                    onDrop={this.handleDrop}
                    onDragOver={this.handleDragOver}
                    id="fileUploadInput"
                    hidden
                  >
                    <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.1245 7.16992V6.04492C16.1245 5.9418 16.0401 5.85742 15.937 5.85742H6.93701C6.83389 5.85742 6.74951 5.9418 6.74951 6.04492V7.16992C6.74951 7.27305 6.83389 7.35742 6.93701 7.35742H15.937C16.0401 7.35742 16.1245 7.27305 16.1245 7.16992ZM6.93701 9.23242C6.83389 9.23242 6.74951 9.3168 6.74951 9.41992V10.5449C6.74951 10.648 6.83389 10.7324 6.93701 10.7324H11.2495C11.3526 10.7324 11.437 10.648 11.437 10.5449V9.41992C11.437 9.3168 11.3526 9.23242 11.2495 9.23242H6.93701ZM11.2495 19.9668H4.87451V3.4668H17.9995V10.4043C17.9995 10.5074 18.0839 10.5918 18.187 10.5918H19.4995C19.6026 10.5918 19.687 10.5074 19.687 10.4043V2.5293C19.687 2.11445 19.3519 1.7793 18.937 1.7793H3.93701C3.52217 1.7793 3.18701 2.11445 3.18701 2.5293V20.9043C3.18701 21.3191 3.52217 21.6543 3.93701 21.6543H11.2495C11.3526 21.6543 11.437 21.5699 11.437 21.4668V20.1543C11.437 20.0512 11.3526 19.9668 11.2495 19.9668Z" fill="currentColor" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M16.8745 13.3105C14.907 13.3105 13.312 14.9055 13.312 16.873C13.312 18.8406 14.907 20.4355 16.8745 20.4355C18.842 20.4355 20.437 18.8406 20.437 16.873C20.437 14.9055 18.842 13.3105 16.8745 13.3105ZM12.187 16.873C12.187 14.2842 14.2857 12.1855 16.8745 12.1855C19.4633 12.1855 21.562 14.2842 21.562 16.873C21.562 19.4619 19.4633 21.5605 16.8745 21.5605C14.2857 21.5605 12.187 19.4619 12.187 16.873Z" fill="currentColor" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.312 16.873C13.312 14.9055 14.907 13.3105 16.8745 13.3105C18.842 13.3105 20.437 14.9055 20.437 16.873C20.437 18.8406 18.842 20.4355 16.8745 20.4355C14.907 20.4355 13.312 18.8406 13.312 16.873ZM19.1007 16.0588L18.3979 16.9373L17.4368 16.1684V18.7481H16.3118V16.1684L15.3507 16.9373L14.6479 16.0588L16.8743 14.2777L19.1007 16.0588Z" fill="currentColor" />
                    </svg>
                    <DragDropText data-error={this.getErrorState()} component="p" >Drag and drop your videos here to start uploading</DragDropText>
               
                    {!isSizeExceeded && !isWrongFileMimeType && this.renderFileUploadSection()}
  
   
                  </Box>
                </Box>
                <Box style={{
                    display:"flex",
                    justifyContent:"space-between"
                  }}><Typography className="customStyleUpload">Supported formats: PDF, DOC, XLS, PPT, JPEG, PNG, MP4</Typography> <Typography className="customStyleUpload">Maximum file size: 50MB </Typography></Box>
               
              </Box>
              {this.renderErrorBox()}

  
<Box style={{
overflow:"visible",
padding:"0px !important",
position:"relative",
top:"4%"
}} >
  <Box
style={{
display: "flex",
justifyContent: "space-between",
alignItems: "center",
width: "100%"
}}
>
<Typography style={{
fontWeight: 700,
fontSize: "14px",
marginBottom: "4px",
fontFamily: "Dm sans",
}}>
Task Folder Files
<span style={{ color: "red" }}>*</span>
</Typography >


</Box>
<Box >
<Box>

<Box style={{
                  position:"relative"
                 }}>
                  <TextField
                    placeholder="Search by File Name"
                    value={this.state.searchFiles}
                    onChange={this.handleSearchFiles}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{
                            color: "DADADA",
                            width: "24px",
                            height: "24px"
                          }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      inputProps: {
                        style: {

                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    className="selectBox"
                  />
            
                  </Box>
                  <Box
        style={{
          border: "1px solid #F0F0F0",
          width: "873px",
          height: "240px",
          borderRadius: "8px",
          padding: "12px",
          marginTop: "13px",
          maxHeight:"240px",
          overflow:"scroll",
          flexWrap:"wrap",
          display:"flex",
          gap:"24px"
        }}
      >
             {this.renderUploadedFiles()}
             </Box>
</Box>
</Box>
</Box>
<Box style={{
width: "100%",
position:"relative",
top:"6%",
display:"flex",
justifyContent:"flex-end",
gap:"5px"
}}>
<Button
data-test-id="cancelBtn3"
onClick={this.handleClosUploadDialog}
style={{
border: "1px solid #8C8C8C",
width: "150px",
height: "44px",
display: "flex",
alignItems: "center",
borderRadius: "8px",
fontSize: "16px",
fontWeight: 700,
fontFamily: "Dm sans",
color: "#8C8C8C",
textTransform: "none",
}}
>
Cancel
</Button>
<Button
 data-test-id="saveButton"
  onClick={this.handleSaveFiles} 
  style={this.getButtonStyles(this.state.isSaveEnabled)}
  disabled={!this.state.isSaveEnabled} 
>
  Save Files
</Button>


</Box>

</CustomizeDialog2>
)}
  </Box>
              </MainDiv>
              </Box>
              </MainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledBadge = styled(Badge)({
  '&.badge-blue .MuiBadge-badge': {
    backgroundColor: '#355EE7',
    color: '#355EE7',
    boxShadow: "0 0 0 2px white",
  },
  '&.badge-grey .MuiBadge-badge': {
    backgroundColor: ' #8C8C8C',
    color: ' #8C8C8C',
    boxShadow: "0 0 0 2px white",
  }
 
});

const NoFilesOrFolder = styled('div')({
  display: 'flex',
  flexDirection: "column",
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  height: "100%",
  "& .noDataImg": {
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    height: '70px',
    width: '70px',
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  }
})

const NoPermissionModal = styled('div')({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  overflowY: 'scroll',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  "& .NoPermissionModaldeleteIconBox": {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },
  "& .NoPermissionModaldeleteIcon": {
    fontSize: '3rem',
    color: '#d9291e',
    borderRadius: '50%',
    padding: '20px',
    backgroundColor: '#fee2e1'
  },
  "& .NoPermissionModaldeleteMsg": {
    fontSize: '1.2rem',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },

  "& .NoPermissionModalmoveModalContainer": {
    display: 'flex',
    width: '50%',
    height: '28%',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    flexDirection: 'column',
    padding: '0 20px',
    gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '28%'
    },
  },
  "& .NoPermissionModalmoveModalCloseIconBox": {
    padding: '20px 10px 0 20px',
    display: 'flex',
    justifyContent: 'end',
    cursor: 'pointer !important'
  },
  "& .NoPermissionModalmoveModalCloseIcon": {
    color: 'gray',
  },
})

const DeleteModal = styled('div')({
  overflowY: 'scroll',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  "& .DeleteModaldeleteIconBox": {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },
  "& .deleteIcon": {
    backgroundColor: '#fee2e1',
    fontSize: '3rem',
    borderRadius: '50%',
    padding: '20px',
    color: '#d9291e'
  },
  "& .deleteMsg": {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem'
  },

  "& .moveModalContainer": {
    display: 'flex',
    width: '50%',
    height: '35%',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    flexDirection: 'column',
    padding:'0 20px',
    gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '35%'
    },
  },
  "& .moveModalCloseIconBox": {
    display: 'flex',
    justifyContent: 'end',
    cursor: 'pointer !important',
    padding: '20px 10px 0 20px'
  },
  "& .moveModalCloseIcon": {
    color: 'gray',
  },
  "& .moveModalHeading": {
    marginTop: '-15px',
    fontSize: '22px'
  },
  "& .moveModalControls": {
    padding: '10px',
    display: 'flex',
    gap: '0.5rem'
  },
  "& .moveModalControlsBtn": {
    fontSize: '16px',
    border: 'none',
    color: ' gray',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '4px 2px',
    borderRadius: '8px',
    minWidth: '50%',
    cursor: 'pointer !important'
  },
  "& .downloadContainer": {
    borderRadius: '120px',
    padding:'0 20px',
    gap: '1rem',
    display: 'grid',
    width: '100%',
    height: '55%',
    backgroundColor: '#FFFFFF',
   
    "@media (min-width:1200px)": {
      width: '40%',
      height: '45%'
    },
  },
  "& .moveModalControlsBtn1":{
    border: '1px solid gray',
    backgroundColor: '#f4f4f4',
    color: 'gray'
  },
  "& .moveModalControlsBtn2": {
    backgroundColor: '#d9291e',
    color: 'white'
  }

})

const NewFolderModal = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  overflowY: 'scroll',
  userSelect: 'none',

  "& .moveModalContainer": {
    display: 'flex',
    width: '50%',
    height: '25%',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    flexDirection: 'column',
    padding:'0 20px',gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '25%'
    },
  },
  "& .moveModalCloseIconBox": {
    display: 'flex',
    padding: '20px 10px 0 20px',
    justifyContent: 'end',
    cursor: 'pointer !important'
  },
  "& .moveModalCloseIcon": {
    color: 'gray',
  },
  "& .moveModalHeading": {
    fontSize: '22px',
    marginTop: '-15px'
  },
  "& .moveModalInputBox": {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '30px',
    marginBottom: '10px'
  },
  "& .moveModalInput": {
    width: '98%',
    display: 'flex',
    outline: 'none',
    padding: '20px 20px 20px 10px',
    borderRadius: '10px',
    fontSize: '15px',
    border: '1px solid #b2dafa'
  },
  "& .moveModalInput::placeholder": {
    color: '#a29e9e'
  },
  "& .moveModalControls": {
    padding: '10px',
    display: 'flex',
    justifyContent: 'end',
    gap: '0.5rem'
  },
  "& .moveModalControlsBtn": {
    fontSize: '16px',
    border: 'none',
    color: ' gray',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '4px 2px',
    borderRadius: '8px',
    minWidth: '150px',
    cursor: 'pointer !important'
  },
  "& .moveModalControlsBtn1": {
    backgroundColor: '#f4f4f4',
    border: '1px solid gray',
    color: 'gray'
  },
  "& .moveModalControlsBtn2": {
    backgroundColor: '#345ee6',
    color: 'white'
  }
})

const MoveFolders = styled('div')({
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  borderRadius: '10px',
  width: 'calc(99% - 20px)',
  maxHeight: 320,
  overflow: 'scroll',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  "&::-webkit-scrollbar": {
    width: "10px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "red",
    borderRadius: "5px",
  },

  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
    borderRadius: "5px",
  },

  scrollbarWidth: "auto",
  scrollbarColor: "#345ee6 white",

  "& .folderIcon": {
    color: '#BFBFBF'
  },
  "& .folderInput": {
    borderColor: 'red'
  },
  "& .mainFolder": {
    padding: '10px 10px 10px 25px',
    display: 'flex',
    gap: '0.5rem',
    position: 'relative'
  },
  "& .subFolder": {
    padding: '10px 10px 10px 25px',
    display: 'flex !important',
    gap: '0.5rem',
    position: 'relative'
  },
  "& .selectFolder": {
    position: 'absolute',
    left: '0'
  },
  "& .borderBottom": {
  },
  "& .showSubMenu": {
  },
  "& ul": {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  "& li": {
    margin: 0,
    padding: 0,
  },
})

const MoveModal = styled('div')({
  overflowY: 'scroll',
  position: 'fixed',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100vw',
  height: '100vh',
  userSelect: 'none',
  maxHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',

  "& .moveModalContainer": {
    width: '50%',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    borderRadius: '20px',
    flexDirection: 'column',
    padding: '0 20px',
    gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '70%'
    },
  },
  "& .moveModalCloseIconBox": {
    display: 'flex',
    padding: '20px 10px 0 20px',
    justifyContent: 'end',
    cursor: 'pointer !important'
  },
  "& .moveModalHeading": {
    fontSize: '22px',
    marginTop: '-15px'
  },
  "& .moveModalInputBox": {
    display: 'flex',
    width: '100%',
    height: '30px',
    position: 'relative',
    marginBottom: '10px'
  },
  "& .moveModalInput": {
    display: 'flex',
    width: '100%',
    padding: '20px 20px 20px 35px',
    outline: 'none',
    borderRadius: '10px',
    fontSize: '15px',
    border: '1px solid #b2dafa'
  },
  "& .moveModalInput::placeholder": {
    color: '#a29e9e'
  },
  "& .moveModalSearchIcon": {
    position: 'absolute',
    top: '10px',
    left: '10px',
    color: '#BFBFBF'
  },
  "& .moveModalData": {
    width: '100%',
    height: '60%',
    backgroundColor: '#FFFFFF'
  },
  "& .moveModalControls": {
    padding: '10px',
    display: 'flex',
    justifyContent: 'end',
    gap: '0.5rem'
  },
  "& .moveModalControlsBtn": {
    display: 'inline-block',
    border: 'none',
    color: ' gray',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: '16px',
    margin: '4px 2px',
    borderRadius: '8px',
    minWidth: '150px',
    cursor: 'pointer !important'
  },
  "& .moveModalControlsBtn1": {
    border: '1px solid gray',
    backgroundColor: '#f4f4f4',
    color: 'gray'
  },
  "& .moveModalControlsBtn2": {
    backgroundColor: '#345ee6',
    color: 'white'
  }
})

const InfoModal = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  fontSize: '20px',
  backgroundColor: '#FFFFFF',
  top: '4px',
  padding: '0 20px 20px 20px',
  right: '60px',
  borderRadius: '10px',
  width: '350px',
  flexWrap: 'wrap',
  boxShadow: '0 4px 10px rgba(164, 161, 161, 0.5)',
  "& .fileFormatsBox": {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    margin: '-30px 20px 0 0',
  },
  "& .fileFormats": {
    color: '#3695f4'
  },
  "& .fileSizeBox": {
    display: 'flex'
  },
  "& .infoIcss": {
    position: 'absolute',
    fontSize: '30px',
    color: '#595959',
    top: '7px',
    right: '8px'
  },
  "& .infoIconI": {
    color: '#dbecfd'
  },
  "& .infoIconBox": {
    display: 'flex',
    justifyContent: 'end'
  },
  "& .infoIconBox:hover": {
    cursor: 'pointer !important'
  }
})
const DragDropText = styled(Box)({
  fontSize: 12,
  margin: 0,
  color: '#434343',
  fontWeight: 500,
  fontFamily: 'DM Sans',
  lineHeight: '15.62px',
  '&[data-error="true"]': {
    color: "#DC2626",
  },
})

const MoreOptions = styled('div')({
  position: 'absolute',
  padding: '10px 20px 10px 10px',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0 4px 10px rgba(164, 161, 161, 0.5)',
  "& .tripledotcss": {
    position: 'absolute',
    fontSize: '30px',
    color: '#595959',
    top: '7px',
    right: '8px'
  },
  "& .tripledotcss:hover": {
    cursor: '!important'
  },
  "& .optionRow": {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '10px',
    fontSize: '20px',
    cursor: 'pointer !important',
    textWrap: 'nowrap'
  }

})

const MoreOptionsF = styled('div')({
  position: 'absolute',
  padding: '10px 20px 10px 10px',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0 4px 10px rgba(164, 161, 161, 0.5)',
  "& .tripledotcss": {
    position: 'absolute',
    fontSize: '30px',
    color: '#595959',
    top: '7px',
    right: '8px'
  },
  "& .optionRow": {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '10px',
    fontSize: '20px',
    cursor: 'pointer !important',
    textWrap: 'nowrap'
  }

})


const ScrollBox = styled('div')({
  width: "100%",
  height: "32%",
  margin: "0 auto",
  overflow: "hidden",
  overflowY: "auto",
  padding: "0 20px 0 0",

  "&::-webkit-scrollbar": {
    width: "10px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "red",
    borderRadius: "5px",
  },

  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
    borderRadius: "5px",
  },

  scrollbarWidth: "auto",
  scrollbarColor: "#345ee6 white",
});

const MainBox = styled('div')({
  display: 'flex',
  'flex-direction': 'column-reverse',
  width: '100%',
  padding: '0 0 0 0px',
  gap: '54px',
  overflow:"hidden",
  height:"92vh",
  fontFamily: 'DM Sans !important',
  "& .leftMidBox":{
    fontSize:"14px",
    fontWeight:400,
    fontFamily:"DM Sans",
     color:"#8C8C8C",
    
  },
  "& .messageBoxUi":{
    overflowY: "auto",
     height: "738px",
     '@media (max-height:900px)': {
      overflowY: "auto",
      height: "677px",
  },
  '@media (max-height:930px)': {
    overflowY: "auto",
    height: "700px",
},

  '@media (max-height:915px)': {
    overflowY: "auto",
    height: "670px",
},

     '@media (max-height:800px)': {
      overflowY: "auto",
      height: "614px",
  },
 
     '@media (max-height: 768px)': {
      overflowY: "auto",
      height: "600px",
  },
  '@media (max-height: 700px)': {
    overflowY: "auto",
    height: "524px",
},
  },
  "& .folderBox": {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  "& .folderBoxA": {
    width: '100%',
    height: '650px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    overflowY: 'scroll',
  },
  '& .showScroll': {
    scrollbarWidth: "none", 
  },
  '& .noScroll': {
    scrollbarWidth: "none",
  },
  "& .noScroll::-webkit-scrollbar": {
    display: "none",
  },
  "& .headerContent": {
    fontSize: '1rem',
    fontWeight: '600',
    "@media (min-width:1200px)": {
      fontSize: '1.5rem',
    },
  },
  "& .uploadBox": {
    width: '100%',
    display: "flex",
    gap: 12,
    flexDirection: 'column',
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    boxSizing: "border-box",
  },
  "& .uploadHeading":{
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "23.44px",
    color: "#262626",
  },
  '& .maximumUploadError': {
    padding: "8px 12px",
    display: "flex",
    gap: 12,
    backgroundColor: "#FEE2E2",
    borderRadius: 8,
    '& > p': {
      margin: 0,
      fontSize: 12,
      fontWeight: "400",
      lineHeight: "24px",
      color: "#DC2626",
      fontFamily: "DM Sans",
    },
  },
  
  "& .uploadDragZone": {
    display:"flex",
    flexDirection:"column",
    width: '100%',
    border: '2px dashed #7192FF',
    color: "#4872FF",
    gap: 6,
    padding: '10px 12px',
    backgroundColor: '#ecf1ff',
    borderRadius: '12px',
    fontSize: '12px',
    alignItems: "center",
    height:"102px",
    '&[data-error="true"]': {
      border: '2px dashed #F35F5F',
      color: "#DC2626",
      backgroundColor: '#FEE2E2',
    },
  },
  "& .infoIconBox": {},
  "& .infoIcon": {
    color: 'gray'
  },
  "& .addNewFolder": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#345ee6',
    color: '#FFFFFF',
    borderRadius: '10px',
    marginLeft: '0',
    cursor: "pointer",
    padding: '11px',
  },
  "& .orText": {
    margin: "0",
    display: 'flex',
    width: '100%',
    justConten: 'center',
    gap: '5px',
    color: "#345ee6",
    marginTop: '1rem',
    alignItems: 'center',
  },
  "& .hr": {
    width: "50%",
    height: '1px',
    backgroundColor: '#345ee6'
  },


})

const TopBox = styled('div')({
  width:'100%',
  display:'flex',
  flexDirection:'column',
  background:"grey",
  gap:'0.5rem',
  "& .topBoxHeader":{
    display:'flex',
    justifyContent:"space-between",
    gap:'0.5rem', padding:'0.2rem',
    alignItems:'center',
  },
  "& .backIcon": {
    color: '#6b92ff',
    cursor: 'ponter !important',
    "@media (min-width:1200px)": {
      fontSize: '3rem',
    },
  },
  "& .headerContent": {
    fontSize: '1rem',
    fontWeight: '600',
    "@media (min-width:1200px)": {
      fontSize: '1.5rem',
    },
  },
  "& .topBoxSubHeader": {
    display: 'flex',
    gap: '0.5rem',
    padding: '0.2rem',
    alignItems: 'center',
  },
  "& .subHeaderContent":{
    display:'flex',
    fontSize:'0.9rem',
    alignItems:'center', paddingLeft: '2rem',
    "@media (min-width:1200px)": {
      fontSize: '1.2rem',
      paddingLeft: '3.5rem',
    },
  },
  "& .optionsBar": {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    marginBottom: 32,
    "@media (min-width:1200px)": {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  "& .filterSelected": {
    display: 'flex',
    gap: '0.5rem',
    marginLeft: '3rem',
    marginBottom: '10px'
  },
  "& .filterSelectedOption":{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',  gap:'0.5rem',
    padding:'5px 10px',
    backgroundColor:'#dbecfd',
    borderRadius:'50px', color:'#345ee6',
  },
  "& .filterSelectedOptionIcon": {
    fontSize: '18px'
  },
  "& .searchBar": {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  "& .searchIcon": {
    color: '#b8b8b8',
    width: '100%',
    "@media (min-width:1200px)": {
      fontSize: '1.8rem',
    },
  },
  "& .searchFieldBox": {
    flexGrow: 1
  },
  "& .searchField": {
    width: '100%',
    outline: 'none',
    border: 'none',
    padding: '0.1rem'
  },
  "& .searchField::placeholder": {
    color: 'gray',
    "@media (min-width:1200px)": {
      fontSize: '1rem',
    },
  },
  "& .optionsBarSubBox":{
    display:'flex',
    alignItems:'center', gap:'12px',
    justifyContent:'space-between',
    "@media (min-width:1200px)": {
      justifyContent: 'start',
      gap: '12px'
    },
  },
  "& .filterIcon": {
    color: '#345ee6'
  },
  "& .addNewFolder": {
    border: 0,
    outline: "none",
    backgroundColor: '#345ee6',
    padding: '10px 16px',  borderRadius: '10px',
    cursor: "pointer",
    fontfamily: "DM Sans",
    fontWeight: 500,color: "#FFFFFF",
    fontSize: 16,
    lineHeight: "24px",
  },
  "& .viewFilterBox": {
    display: 'none',
    alignItems: 'center',
    gap: '0.5rem',
    "@media (min-width:1200px)": {
      display: 'flex',
    },
  },
  "& .listBox": {
    backgroundColor: '#dbecfd',
    padding: '0.7rem 0.5rem 0.7rem 0.7rem',
    borderRadius: '50% 0 0 50%'
  },
  "& .listIcon": {
    color: '#345ee6'
  },
  "& .listIconi": {
    color: 'gray'
  },
  "& .gridBox": {
    backgroundColor: '#dbecfd',
    padding: '0.7rem 0.7rem 0.7rem 0.5rem',
    borderRadius: '0 50% 50% 0'
  },
  "& .gridIcon": {
    color: '#345ee6'
  },
})

const RightBox = styled('div')({
  width: '100%',
  height: '100%',
  fontFamily: 'DM Sans !important',
})

const MainContainer = styled('div')({
  backgroundColor: '#fff',
  fontFamily: 'DM Sans',
  overflow:"hidden"

});

const MainDiv = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  fontFamily: 'DM Sans',
  backgroundColor: "white",
  '@media (max-width: 768px)': {
    gridTemplateColumns: '1fr',
  },
  "& .gridDiv1": {
    display: 'flex',
    background:"white",
    '@media (max-width: 991px)': {
      display:'none'
    }
  },
  "& .gridDiv2": {
    display: 'flex',
    width: "30%",
    justifyContent: "space-between"
  },
  "& .gridDiv3": {
    display: 'flex',
    width: "100%",
    flexDirection: 'column',
  },
  "& .imageBox":{
    width:'32px',
    height:'32px',
    borderRadius:'50%',
    border:'1px solid #F0E5FF',
    marginRight:'10px',
  },   
    "& .imgBox":{
      display:"flex",
      flexDirection:"column",
      justifyContent:"flex-end"
    },
    "& .imgBox2":{
      display:"flex",
      justifyContent:"flex-end",
      color:"#7192FF",
      fontWeight:400,
      fontSize:"12px",
      fontFamily:"DM Sans",
      textOverflow: "ellipsis",   
      whiteSpace: "nowrap",    
      overflow: "hidden",  
      width:"44ch"
    },
"&  .media": {
    width: "367px",
    height: "240px",
    objectFit: "cover",
    borderRadius: "12px",
  },
 "& .icon": {
    width: "50px",
    height: "50px",
    borderRadius: "5px",
  },
"&  .docBox": {
    width: "40px",
    height: "40px",
    borderRadius: "6px",
    background: "#C3D1FF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
  },
"&  .docIcon": {
    width: "18px",
    height: "18px",
    borderRadius: "5px",
    marginRight: "1px",
  },
"&  .fileText": {
    fontSize: "12px",
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
  },
 "& .downloadButton": {
    position: "absolute",
    top: "3px",
    right: "10px",
    padding: "5px",
    borderRadius: "6px",
    cursor: "pointer",
    border: "1px solid #9AB1FF",
    width: "92px",
    height: "32px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color:"#262626",
    gap: "14px",
  },
  "& .downloadButton2": {
    height: "32px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "92px",
    display: "flex",
    background:"#FFFFFF99",
    gap: "14px",
    borderRadius: "6px",
    position: "absolute",
    top: "38px",
    color:"#262626",
    left:"20px",
    padding: "5px",  },
  "& .downloadButtonVideo": {
    position: "absolute",
    top: "35px",
    left:"14px",
    padding: "5px",
    alignItems: "center",
    justifyContent: "center",
    gap: "14px",
    borderRadius: "6px",
    color:"#262626",
    cursor: "pointer",
    display: "flex",
    background:"#FFFFFF99",
    width: "92px",
    height: "32px",},
"&  .downloadIcon": {
    width: "20px",
    height: "20px",
    color:"#262626",
    cursor: "pointer",
  },
"&  .fileDetails": {
    fontSize: "12px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    overflow: "hidden",         
    textOverflow: "ellipsis",     
    whiteSpace: "nowrap",
    width: "15ch"  
  },
 "& .fileSize": {
    color: "#8C8C8C",
    fontSize: "12px",
    fontFamily: "DM Sans",
    fontWeight: 400,
  },
 "& .customStyleUpload":{
  fontFamily:"DM Sans",
  fontWeight:500,
  color:"#8C8C8C",
  fontSize:"11px"
 },
 "& .chipInProgress": {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    fontWeight: 700,
    fontSize: "10px",
  },
  "& ::-webkit-scrollbar": {
    width: "10px !important"
  },
 "& .custom-textarea::placeholder" :{
    color: "#BFBFBF"
  },
  
  "& ::-webkit-scrollbar-track": {
    background: "#F5F5F5 !important",
    borderRadius: 10,
  },
  "& ::-webkit-scrollbar-thumb": {
    background: "#91AAFF !important",
    borderRadius: 10,
  },
  "& .uploadDragZone": {
    width: '100%',
    marginBottom:"17px",
    border: '2px dashed #7192FF',
    flexDirection:"column",
    color: "#4872FF",
    display: 'flex',
    gap: 6,
    padding: '10px 12px',
    backgroundColor: '#ecf1ff',
    borderRadius: '12px',
    fontSize: '12px',
    alignItems: "center",
    height:"145px",
    justifyContent:"center",
    '&[data-error="true"]': {
      border: '2px dashed #F35F5F',
      color: "#DC2626",
      backgroundColor: '#FEE2E2',
    },
  },
  "& ::-webkit-scrollbar-thumb:hover": {
    background: "#91AAFF !important"
  },
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .MuiSelect-selectMenu:focus": {
    backgroundColor: "#fff"
  },
  "& .sidebar": {
    height: '100vh',
    position: 'fixed',
    overflow:"hidden",
    top: '0',
    left: '0',
    borderRight: "1px solid #C3D1FF",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #01175F14",
    "@media (max-width: 1100px)": {
      width: "355px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .drawerBar":{
    "@media (max-width: 991px)": {
      display:'none'
  }
  },
  "& .rightbar": {
    width: 'calc(100% - 355px)',
    padding: "1px 0px",
    marginLeft: '23.8rem',
    marginTop: '0px',
    backgroundColor: '#FAFAFA',
    "@media (max-width: 1100px)": {
      padding: '1px 2px',
      width: 'calc(100% - 355px)',
      marginLeft: '355px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px',
      marginTop: '0px',
      height: 'calc(100vh - 90px)'
    }
  },

  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '355px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '24rem',
    width: 'calc(100% - 355px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },

})

const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "387px",
  "& .logobox": {
    display: 'flex', justifyContent: "space-between", alignItems: 'center',
    height: "76px", marginTop: '20px',
    marginLeft: '20px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingtext": {
    color: '#262626', fontSize: '22px',
    marginLeft: '6px',
    fontWeight: 400, fontFamily: 'DM Sans',
    marginTop: '3px'
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
    cursor: 'pointer'
  },

  "& .lefticon":
  {
    marginTop: '4px',
    marginLeft: '30px',
    fontSize: '30px !important',
    color: '#514e4eeb'
  },

  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '355px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '355px',
    display: 'none',
  },
});

const ButtonBox = styled('div')({
  display: 'none',
  "@media (max-width: 991px)":
  {
    width: '100%',
    display: 'block', minHeight: '70px',
    "& .buttonbox":
    {
      height: '70px', width: '100vw'
    },

    "& .iconbtn":
    {
      marginTop: '10px', marginLeft: '10px'
    }
  }
})

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '6px',
  width: '50px',
  borderRadius: '5px',
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
    backgroundColor: '#355EE7',
  },
}));

const webStyles = {
  fileName: {
    maxWidth: '220px',
    overflowWrap: "break-word" as 'break-word',
    fontSize: '20px',
    wordWrap: "break-word" as 'break-word'
  },
  noDataHeading: {
    fontWeight: 700,
    fontSize: '24px',
    fontFamily: 'DM Sans',
    lineHeight: '31.25px',
    color: '#0F172A',
  },
  noDataDescription: {
    textAlign: 'center' as 'center',
    color: '#BFBFBF',
    fontFamily: 'DM Sans',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px'
  },
  modalStyle: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sizeModal: {
    height: '160px',
    width: '45%',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '15px'
  },
  modalBorder: {
    width: '100%',
    height: '100%',
    border: '1px solid #FEE2E1',
    borderRadius: '8px', display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeIcon: {
    marginLeft: 'auto',
    marginTop: '-28px',
    color: '#8C8C8C',
    cursor: 'pointer'
  },
  avatar: {
    backgroundColor: '#FEE2E1'
  },
  warning:
    { color: '#AB3E41' },
  warningText: {
    fontFamily: 'DM sans',
    textAlign: 'center' as 'center',
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: '23.44px',
    color: '#000000'
  },
  imgStyle: {
    height: '42px', width: '42px', margin: 'auto'
  }

}

const CustomizeDialog = styled(Box)({
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF', borderRadius: "12px", outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "44px",
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundSize: "17px",
    paddingLeft: "8px"
  },
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  boxShadow: "  0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
  borderRadius: "12px",
  padding: "15px 9px 9px 9px",
  width: "580px",
  background: "white",

  "& .MuiPaper-rounded ": {
    bordeRadius: "17px",
  },
  "& .mainDiv": {
    background: "#FFF",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    borderRadius: "16px",
    padding: "32px 24px 32px 24px",
    gap: "20px",
    fontFamily: "Dm sans",

  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },
  "& .selectBox": {
    border: "1px solid #C3D1FF",
    position:"relative",
    width: "97%",
    borderRadius: "12px",
    color: "black", height: "44px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    "& .MuiInputBase-input::placeholder": {
      color: "#BFBFBF",
    },
    "& .div-root-1 .MuiInputBase-input": {
      padding: "0px"
    }
  },
  "& .forGap": {
    padding: "4px",
  },
  "& . moneySlider": {
    color: "red",
  },
  "& .fontsizing": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#212121",
  },
  "& .selectedUser": {
    border: '1px solid #F0F0F0', borderRadius: '10px', display: 'flex', flexDirection: 'column', gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "97.5%",
    padding: "6px"
  },
  "& .selectedUser2": {
    border: '1px solid #C3D1FF',
    borderRadius: '10px',
    display: 'flex',
    gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "100%"
  }
});
export const CustomizeDialog2 = styled(Box)({
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF', borderRadius: "12px", outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "44px",
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundSize: "17px",
    paddingLeft: "8px" },
  display: "flex",
  padding: "15px 21px 9px 21px",
  width: "900px",
  height:"auto",
  background: "white",
  flexDirection: "column",
  boxShadow: "  0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
  borderRadius: "12px",
  "& .MuiPaper-rounded ": {
    bordeRadius: "17px",},
  "& .mainDiv": {
    background: "#FFF",
    width: "100%",
    justifyContent: "center",

    gap: "20px",
    flexDirection: "column",
    display: "flex",
    borderRadius: "16px",
    padding: "32px 24px 32px 24px",
    fontFamily: "Dm sans",},
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
    
      outline: "none",
      position: "relative",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      bottom: "3px",
      height: "44px"},
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
    
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative", },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px", },
  },
  "& .selectBox": {
    border: "1px solid #C3D1FF",
    position:"relative",
    width: "99%",
    borderRadius: "12px",
    color: "black", height: "44px",
    display: "flex",
    justifyContent: "center", paddingLeft: "6px",
    "& .MuiInputBase-input::placeholder": {
      color: "#BFBFBF",
    },
    "& .div-root-1 .MuiInputBase-input": {
      padding: "0px"
    } },
  "& .forGap": {
    padding: "4px",
  },
  "& . moneySlider": {
    color: "red",
  },
  "& .fontsizing": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#212121",
  },
  "& .selectedUser": {
    border: '1px solid #F0F0F0', borderRadius: '10px', display: 'flex', flexDirection: 'column', gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "97.5%",
    padding: "6px"
  },
  "& .selectedUser2": {
    border: '1px solid #C3D1FF',
    borderRadius: '10px',
    display: 'flex',
    gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "100%"
  }
});

// Customizable Area End
