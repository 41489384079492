// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { Alert, Platform } from "react-native";
import DocumentPicker from 'react-native-document-picker';
import { getStorageData } from "../../../framework/src/Utilities";
import * as helpers from "../../../framework/src/Helpers";
import { SelectOption } from "../../advancedsearch/src/components/SearchAndFilter.web";
import { DocumentationSearchArgs } from "./components/DocumentationSearchAndFilter.web";import { TaskProfile } from "../../tasks/src/types";
import { saveAs } from 'file-saver';


const navigation = require("react-navigation");

interface Account {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    full_phone_number: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    type: string | null;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    firm: string | null;
  };
}

interface FileType {
  id: number;
  name: string;
  account_id: number | null;
  account_name: string | null;
  account_profile_image: string | null;
  byte_size: number;
  record_type: string;
  record_id: number;
  created_at: string;
  filename: string;
  content_type: string;
  url: string;
}

interface FolderAttributes {
  folder_name: string;
  parent_id: number | null;
  is_archived: boolean;
  account_id: number | null;
  task_id: number;
  archived_at: string | null;
  created_at: string;
  restore_id: string | null;
  profile: string | null;
  account: { data: Account | null };
  subfolder: { data: FolderType }[];
  files: FileType[];
}

interface FolderType {
  id: string;
  type: string;
  attributes: FolderAttributes;
}

type MoveFolder = {
  id: string;
  name: string;
  parentId: string;
  subFolders: MoveFolder[];
}

export interface GalleryMedias {
  url?: string,
  blob_id?: number,
  filename?: string,
  gallery_id?: string | number,
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface FolderItemsData {
  id?: number,
  type?: string,
  attributes: { folder_name: string }
}

export interface DataItems {
  id: string,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder: {
      data: Array<FolderItemsData>
    }
  }
}

interface FileMenu {
  id?: number | string,
  file?: string,
  blob_id?: number,
  fileAndFolderName?: string,
}

export interface AllDocumentsResponse {
  data: Array<DataItems>;
}

export interface AllDocumentData {
  gallery_id?: string,
  filename?: string,
  blob_id?: string
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface MapData {
  id: number
  url: string,
  blob_id: number,
  filename: string,
  gallery_id: number,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder_name: string,
    gallery: {
      id: number
    }
  }
}

interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string
}

interface SuccessResponse {
  message: string;
}

interface ErrorResponse {
  errors: string;
}

interface TokenExpire {
  errors: Array<TokenAlert>
}

interface TokenAlert {
  token: string
}

// For Mobile
export interface Galleryobjectuniqueid {
  gallery_type: string;
  account_id: number;
}
export interface Innerobjectoffolder {
  folder_name: string;
  folder_type: string;
  gallery: Galleryobjectuniqueid;
  folder_medias: Array<GalleryMedia>
}
export interface Foldermedia {
  type: string;
  attributes: Innerobjectoffolder
}
export interface FolderInterface {
  data: Array<Foldermedia>
}
export interface GalleryMedia {
  url: string;
  blob_id: number;
  filename: string;
}
export interface Innerobject {
  gallery_type: string;
  gallery_medias: Array<GalleryMedia>
  folder: FolderInterface;
}
export interface Versionitems {
  data: Array<ObjectMedia>;
}
export interface ObjectMedia {
  type: string;
  attributes: Innerobject;
}

export interface Itemmedia {
  concat(data: Itemmedia[]): Itemmedia[];
  url: string,
  blob_id: number,
  filename: string
}

export interface DocumentlistItemapi {
  data: Array<GetAllDocumentlistapi>;
}

export interface GetAllDocumentlistapi {
  type: string;
  attributes: Objectattributes;
}

export interface Objectattributes {
  folder: ObjectOffolder;
  gallery_medias: Itemmedia;
}

export interface ObjectOffolder {
  data: Itemmedia[];
}

interface GetAllDocumentResponseJson {
  data: Array<Versionitems>;
  message: string
}

interface Errorobject {
  errors: string;
}

interface Tokenexpire {
  errors: Array<Tokenalert>
}
export interface Tokenalert {
  token: string
}

export interface Itemmedia {
  url: string;
  blob_id: number;
  filename: string;
  id: string,
  type: string,
  attributes: Folderobject
}
export interface Folderobject {
  folder_name: string;
  folder_type: string;
  gallery: Uniqeidnumberobject;
  folder_medias: Array<Fileinfolder>;
  cover_photo: null;
}
export interface Fileinfolder {
  url: string;
  blob_id: number;
  filename: string;
}

export interface Uniqeidnumberobject {
  gallery_type: string,
  account_id: number
}

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  id: string;
}

interface Task {
  title: string;
  count: number;
}

export interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  loader: boolean;
  fileMenu: FileMenu | null;
  addFolderModal: boolean;
  renameModal: FileMenu | boolean | null;
  deleteModal: null | FileMenu;
  uploadedFiles: [];
  folderName: string;
  renameValue: string;
  menuOption: string;
  folderMenuOptions: boolean;
  allDocuments: Array<FolderItemsData | GalleryMedias>,
  folderId: number;
  galleryId: number | string;
  blobId: number;
  isSizeExceeded:boolean;
  openCollapse:boolean;
  documentOpen:string | null;
  openSecondCollapse:boolean;
  activeTab:string
  loading: boolean;
  filterState: boolean;
  selectedTask: string;
  selectedFolder:string;
  clientToken:string | null

  // For Mobile

  modalDots: boolean;
  folderModal: boolean;
  folderNameNative: string;
  cancel: boolean;
  create: boolean;
  rename: boolean;
  delete: boolean;
  modalRename: boolean;
  fileRenameText: string;
  deleteModalnative: boolean;
  fileDeleteText: string;
  documentList: Array<Itemmedia>;
  blobIdNumber: number;
  floderId: string;
  documentId: string;
  isFolder: boolean;
  isDelete: boolean;
  isloading: boolean;
  yesNoModal: boolean;
  yesNoModalFile: boolean;
  responseFailureData: boolean;

  mobileOpen : boolean;
  closeOption: false;
  openOption: false;
  openOptionIndex: number;
  openOptionIndexF: number;
  closebox: boolean;

  LoadedFiles: File[],
  LoadedFilesPreview: any
  uploadProgress: any,
  uploadingFiles: any,
  Files: any,
  uploadMimetype: any,
  uploadResponse:any,
  upLoading:boolean,
  isDeleting:boolean,
  isDeleteID:string,
  folders:any,
  files:any,
  viewMode:string,
  moreOptions:boolean,
  infoModal:boolean,
  dateInput1:string,
  inFilterModal:boolean,
  isDateUploadedRange:boolean,
  filterTypeValue:string,
  isFilterType:boolean,
  filterOwnerValue:string,
  filterOwner:any
  isFilterOwner:boolean,
  filterUploadedDateRangeStartValue:string,
  filterUploadedDateRangeEndValue:string,
  filterList:any,
  filterOwnerSearchResult:any,
  searchTerm:string,
  moveFolders:MoveFolder[],
  folderLevel:any;
  selectedFolderForMove:string|number;
  moveModalPopUp:boolean;
  renameModalPopUp:boolean;
  selectedForRename:string;
  newFolderModalPopUp:boolean;
  deleteModalPopUp:boolean;
  isNoPermission:boolean;
  taskID:string|number;
  taskName:string;
  taskIdFrom:string;
  uploadFolderId:string|number;
  archiveFolder:any;
  isArchiveSection: boolean;
  folderPath:Array<{id: string, folderName: string}>;
  isArchiveFolder:boolean;
  addNewFolderInput:string;
  deleteFileOrFolderDetails:{
    id:string|number,
    shouldDelete:boolean,
    type:string,
    name:string
  };
  workLoading:boolean;
  renameInput:string;
  downloadUrl:string;
  itemList:any;
  pageLoading:boolean;
  openOptionIndexL:number;
  isWrongFileMimeType: boolean;
  showUndo: boolean;
  ownerNameLoading: boolean;
  ownerNameOptions: Array<SelectOption>;
  taskProfile:TaskProfile[],
  filterTask:TaskProfile[],
  selectedTaskId: string,
  currentFolderId: string;
  folderNameSearchText: string;
  
}

interface SS {
  id: string;
}

export default class DocumentationController extends BlockComponent<
  Props,
  S,
  SS
> {
  uploadControllers: { [key: string]: AbortController } = {};

  addFolderApiCallId: string = "";
  renameFileApiCallId: string = "";
  allDocumentsApiCallId: string = "";
  renameFolderApiCallId: string = "";
  deleteFileApiCallId: string = "";
  deleteFolderApiCallId: string = "";
  uploadFilesAndFoldersApiCallId: string = "";
  moveFolderMargin:number = 0;
  getAllFoldersApiCallId: string = "";
  getAllSubFoldersApiCallId: string = "";
  getTaskDetailsApiCallId: string = "";
  getSearchDetailsApiCallId: string = "";
  postSubFolderApiCallId: string = "";
  postRootFolderApiCallId: string = "";
  postArchiveFolderApiCallId: string = "";
  putRenameFolderApiCallId: string = "";
  deleteFileOrFolderApiCallId: string = "";
  PostBulkUploadEndPointCallId: string = "";
  putMoveToArchiveFolderApiCallId: string = "";
  deleteAllItemsFromArchive: string = "";
  putRestoreFileOrFolderApiCallId: string = "";
  putMoveFileApiCallId: string = "";
  putMoveFolderApiCallId: string = "";

  permissionErrorText: string = "";
  timeout: any= 0;
  currentFolderId: string | number = "";
  currentFileFolderType: string = "";
  currentFileOrFolderName: string = "";
  getTaskListsApiCallId: string = "";
  getTaskDataApiCallID: string = "";
  getFolderURLCallId: string ="";
  

  // For Mobile

  getAllDocumentsApiCallId: string = "";
  createDocumentApiCallId: string = "";
  getDeleteFileApiCallId: string = "";
  getPatchRenameFileApiCallId: string = "";
  getAddFolderApiCallId: string = "";
  getDeleteFolderApiCallId: string = "";
  getRenameFolderApiCallId: string = "";
  willFocusScreen!: string;
  documentationSearchAPICallId: string = "";
  ownerNameSearchAPICallId: string = "";

  onlyMoveFolders: boolean = false;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      isSizeExceeded:false,
      isWrongFileMimeType: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      deleteModal: null,
      allDocuments: [],
      addFolderModal: false,
      uploadedFiles: [],
      loader: false,
      folderName: "",
      renameModal: null,
      galleryId: 0,
      renameValue: "",
      menuOption: "",
      folderMenuOptions: false,
      folderId: 0,
      fileMenu: null,
      blobId: 0,
      openCollapse:false,
      openSecondCollapse:false,
      activeTab: 'active',
      loading: true,
      selectedTaskId:'',
      filterState: false,
      taskName:'',
      isArchiveSection: false,
      selectedTask: "",
      selectedFolder : "" ,
      clientToken:"",

      // For Mobile

      modalDots: false,
      folderModal: false,
      cancel: false,
      create: false,
      rename: false,
      delete: false,
      folderNameNative: '',
      modalRename: false,
      documentList: [],
      documentId: '512',
      blobIdNumber: 0,
      fileRenameText: '',
      deleteModalnative: false,
      fileDeleteText: '',
      floderId: '',
      isFolder: false,
      isDelete: false,
      isloading: false,
      yesNoModal: false,
      yesNoModalFile: false,
      responseFailureData: false,

      mobileOpen: false,
      closeOption: false,
      openOption: false,
      openOptionIndex: -1,
      openOptionIndexF: -1,
      closebox: false,

      LoadedFiles: [],
      LoadedFilesPreview: [],
      uploadProgress: [],
      uploadingFiles: {},
      Files: [],
      uploadMimetype: [],
      uploadResponse:[],
      upLoading:false,
      isDeleting:false,
      isDeleteID:"",
      folders:[],
      files:[],
      viewMode:'grid',
      moreOptions:false,
      infoModal:false,
      dateInput1:'',
      inFilterModal:false,
      isDateUploadedRange:false,
      filterTypeValue:'',
      isFilterType:false,
      filterOwnerValue:'',
      filterOwner:[],
      isFilterOwner:false,
      filterUploadedDateRangeStartValue:'',
      filterUploadedDateRangeEndValue:'',
      filterList:[
        {type:''},
        {owner:''},
        {dateUploaded:''},
        {dateUploadedRange:''}
      ],
      filterOwnerSearchResult:[],
      searchTerm:'',
      moveFolders:[],
      folderLevel:[],
      selectedFolderForMove:'',
      moveModalPopUp:false,
      renameModalPopUp:false,
      selectedForRename:'',
      newFolderModalPopUp:false,
      deleteModalPopUp:false,
      isNoPermission:false,
      taskID:'',
      documentOpen:'',
      taskIdFrom:'',
      uploadFolderId:'',
      archiveFolder:null,
      folderPath:[],
      isArchiveFolder:false,
      addNewFolderInput:'',
      renameInput:'',
      deleteFileOrFolderDetails:{id:'',shouldDelete:false,type:'',name:''},
      workLoading:false,
      downloadUrl:'',
      itemList:[],
      pageLoading:false,
      openOptionIndexL:-1,
      showUndo: false,
      ownerNameLoading: false,
      ownerNameOptions: [],
      taskProfile: [],
      filterTask:[],
      currentFolderId: "",
      folderNameSearchText: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // For Mobile
      if (responseJson.status === 500) {
        this.showAlert("Error", "Internal Server Error");
        return;
      }

      if (responseJson && !responseJson.errors) {
        this.getAllSuccessFunctionCallBack(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.getAllErrorFunctionCallBack(apiRequestCallId, responseJson);
      }

      // For Web

      switch(apiRequestCallId){
        case this.getAllFoldersApiCallId : this.checkResponseForgetAllFoldersApiCallId(responseJson) ; break;
        case this.PostBulkUploadEndPointCallId : this.checkResponsePostBulkUploadEndPointCallId(responseJson); break;
        case this.postRootFolderApiCallId : this.checkResponsepostRootFolderApiCallId(responseJson); break;
        case this.postArchiveFolderApiCallId : this.checkResponsepostArchiveFolderApiCallId(responseJson); break;
        case this.postSubFolderApiCallId : this.checkResponsepostSubFolderApiCallId(responseJson); break;
        case this.getAllSubFoldersApiCallId : this.checkResponsegetAllSubFoldersApiCallId(responseJson); break;
        case this.putMoveToArchiveFolderApiCallId : this.checkResponseputMoveToArchiveFolderApiCallId(responseJson); break;
        case this.putRestoreFileOrFolderApiCallId : this.checkResponseputRestoreFileOrFolderApiCallId(responseJson); break;
        case this.putRenameFolderApiCallId : this.checkResponseputRenameFolderApiCallId(responseJson); break;
        case this.putMoveFileApiCallId : this.checkResponseputMoveFileApiCallId(responseJson); break;
        case this.putMoveFolderApiCallId : this.checkResponseputMoveFolderApiCallId(responseJson); break;
        case this.deleteFileOrFolderApiCallId : this.checkResponsedeleteFileOrFolderApiCallId(responseJson); break;
        case this.deleteAllItemsFromArchive : this.checkResponsedeleteAllItemsFromArchive(responseJson); break;
        case this.documentationSearchAPICallId : this.checkResponseForSearchDocuments(responseJson); break;
        case this.ownerNameSearchAPICallId : this.checkResponseForOwnerNames(responseJson); break;
        case this.getTaskListsApiCallId : this.handleTaskApicall(responseJson); break;
        case this.getTaskDataApiCallID : this.taskDataResponse(responseJson); break;
        case this.  getFolderURLCallId: this.handleFolderURL(responseJson) ; break;
      }

      
    }
  }

  async componentWillUnmount() {
    clearTimeout(this.timeout)
    sessionStorage.removeItem("taskID")
    sessionStorage.removeItem("folderPaths")
    sessionStorage.removeItem("Document")
  }
  
  async componentDidMount(): Promise<void> {
    if (Platform.OS !== 'web') {
      this.willFocusScreen = this.props.navigation.addListener(
        'willFocus',
        () => {
          this.getAllDocumentList()
        }
      );
    }

    if(helpers.getOS() === "web") {
      const taskID = sessionStorage.getItem("taskID")
      const folderPaths = sessionStorage.getItem("folderPaths")
      this.fetchAllFolders(taskID, folderPaths)
    }
    this.getTaskLists();
    this.setState({ documentOpen: sessionStorage.getItem("Document") ?? "" });
    this.setState({
      clientToken:localStorage.getItem("clientTokes")
    })
  }

  taskDataResponse = async(responseJson: any) => {
    this.setState({ pageLoading:true })



     let allFolders = responseJson.data[0].attributes.subfolder.filter((item: any) => {
      return item.data.attributes.folder_name !== "Archive Section"; });
      

     let moveFolders = responseJson.data as FolderType[];
       let archiveFolder = responseJson.data[0].attributes.subfolder.filter((item: any) => {
         return item.data.attributes.folder_name === "Archive Section"; });

         let files = responseJson.data[0].attributes.files;

            let gridFolders = responseJson.data[0].attributes.subfolder.map((x: any) => {
            return { type: 'folder', name: x.data.attributes.folder_name, createdAt: x.data.attributes.created_at, owner: x.data.attributes.account.data?.attributes.first_name }})
    
            let gridFiles = responseJson.data[0].attributes.files.map((x: any) => {
            return { type: 'file', name: x.filename, createdAt: x.created_at, owner: x.account_name }})

    this.setState({
      pageLoading: false,  uploadFolderId: responseJson.data[0].id,  itemList: [...gridFolders, ...gridFiles],  files,
      folders: allFolders, moveFolders: this.formatAllFolderData(moveFolders), archiveFolder: archiveFolder[0],
      folderPath: [{ id: responseJson.data[0].id, folderName: 'Document Folder' }],
    })

  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.uploadProgress.every((item:any) => item.progress === 100)) {
      if(!this.state.upLoading){
      this.uploadBulkAttachements(this.state.taskID,this.state.uploadFolderId)
      }
      
    }
  }

  handleBackNavigationOrDashboardNavigation = (type: "back" | "dashboard") => async() => {
    const navigationObj: Record<typeof type, Record<string, string>> = {
      "back": {
        client: "TaskList",
        lawyer: "TaskListLawyer",
        lawfirm_admin: "TaskListLawfirm"
      },
      "dashboard": {
        client: "ClientDashboard",
        lawyer: "LawyerDashboard",
        lawfirm_admin: "DashBoard"
      },
    }

    const userDetail = await getStorageData("token", true)
    let baseNav = navigationObj[type].client;
    let userRole = "client"
    
    if(userDetail) {
      userRole = userDetail.user_role
      baseNav = navigationObj[type][userRole]
    }

    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
    baseNav);
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);

    sessionStorage.removeItem("taskID")
  }

  checkResponseForOwnerNames = (responseJson: unknown) => {
    const ownerNames = responseJson as Array<{id: number, name: string}>
    this.setState({ownerNameLoading: false})
    if(ownerNames?.[0]?.id) {
      const ownerOptions = ownerNames.map((owner) => ({label: owner.name, value: owner.name}))
      this.setState({ownerNameOptions: ownerOptions})
    }
  }

  checkResponseForSearchDocuments = (responseJson: any) => {
    this.setState({pageLoading: false})

    let newFolders = [], newFiles = [];
    if(responseJson?.folders?.data) {
      newFolders = responseJson.folders.data.map((folder: any) => ({data: folder}))
    }

    if(responseJson?.files?.length > 0) {
      newFiles = responseJson.files 
    }

    this.setState({folders: newFolders, files: newFiles})
  }

  formatAllFolderData = (foldersData: FolderType[]): MoveFolder[] => {
    return foldersData.map((folder) => {
      const newSubFolders = folder.attributes.subfolder.map(subfolder => subfolder.data)
      const newFormattedSubFolders = this.formatAllFolderData(newSubFolders)
      return {
        id: folder.id,
        name: folder.attributes.folder_name,
        parentId: folder.attributes.parent_id ? `${folder.attributes.parent_id}` : "",
        subFolders: newFormattedSubFolders
      }
    })
  }

  checkResponseForgetAllFoldersApiCallId = async (responseJson: any) => {

    
    this.setState({pageLoading:false})

    const moveFolders = this.formatAllFolderData(responseJson.data)

    if(this.onlyMoveFolders) {
      this.setState({moveFolders: moveFolders})
      return;
    }

    this.onlyMoveFolders= false
    let allFolders = responseJson.data[0].attributes.subfolder.filter((item: any) => {
      return item.data.attributes.folder_name !== "Archive Section";
    });


    let archiveFolder = responseJson.data[0].attributes.subfolder.filter((item: any) => {
      return item.data.attributes.folder_name === "Archive Section";
    });

    let files = responseJson.data[0].attributes.files

    let gridFolders = responseJson.data[0].attributes.subfolder.map((x: any) => {
      return { type: 'folder', name: x.data.attributes.folder_name, createdAt: x.data.attributes.created_at, owner: x.data.attributes.account?.data?.attributes.first_name }
    })


    let gridFiles = responseJson.data[0].attributes.files.map((x: any) => {
      return { type: 'file', name: x.filename, createdAt: x.created_at, owner: x.account_name }
    })

    this.setState({
      pageLoading:false,
      uploadFolderId:responseJson.data[0].id,
      itemList: [...gridFolders, ...gridFiles],
      files,
      folders: allFolders,
      moveFolders: moveFolders,
      archiveFolder: archiveFolder[0],
      folderPath: [{ id: responseJson.data[0].id, folderName: 'Document Folder' }],
      currentFolderId: responseJson.data[0].id
    })


  }

  checkResponsePostBulkUploadEndPointCallId = async (responseJson:any) =>{

    if(responseJson.data){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.getAllSubFolders(this.state.taskID, fid.id)
      await this.cancelFilesData()
    }
    else{
      await this.cancelFilesData()
    }
  
  }

  checkResponsepostRootFolderApiCallId = async (responseJson:any)=>{
    if(responseJson.data){
      await this.createArchiveFolder(this.state.taskID)
    }
  }

  checkResponsepostArchiveFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.data){
      await this.getAllFolders(true)
    }
  
  }

  checkResponsepostSubFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.data){
      this.setState({addNewFolderInput:'',newFolderModalPopUp:false})
      let fid = this.state.folderPath[this.state.folderPath.length - 1];
      this.getAllSubFolders(this.state.taskID, fid.id)
      this.getAllFolders(false)
    }
  
  }

  checkResponsegetAllSubFoldersApiCallId = async (responseJson:any)=>{
    this.setState({pageLoading: false})
    if(responseJson.data){
      let allFolders = responseJson.data.attributes.subfolder.filter((item:any) => {
        return item.data.attributes.folder_name !== "Archive Section";
      });
      const archiveFolder = responseJson.data.attributes.subfolder.find((item:any) => item.data.attributes.folder_name === "Archive Section")
      let files = responseJson.data.attributes.files
      this.setState({
        folders: allFolders,
        files,
        uploadFolderId:responseJson.data.id,
        archiveFolder,
      })
    }
  }

  checkResponseputMoveToArchiveFolderApiCallId = async (responseJson:any)=>{
    if (responseJson.message === "You don't have permission to move this folder" || responseJson.message === "You don't have permission to move this file"){
      this.permissionErrorText = `You cannot delete this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({ isNoPermission:true })
      this.handleCloseDeleteModalPopUp()
      return;
    }

    if (responseJson.message === "File moved successfully" || responseJson.message === "Folder moved successfully") {
      let fid = this.state.folderPath[this.state.folderPath.length - 1];
      this.currentFileFolderType = this.state.deleteFileOrFolderDetails.type
      this.currentFolderId = this.state.deleteFileOrFolderDetails.id
      this.currentFileOrFolderName =this.state.deleteFileOrFolderDetails.name
      this.setState({ workLoading: false , showUndo: true})
      this.timeout = setTimeout(() => {
        this.setState({showUndo: false})
      }, 5000)
      this.handleCloseDeleteModalPopUp()
      this.getAllFolders(false)
      this.getAllSubFolders(this.state.taskID, fid.id)
    }
  
  }

  checkResponseputRestoreFileOrFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.message === "You don't have permission to move this folder" || responseJson.message === "You don't have permission to move this file" ) {
      this.permissionErrorText = `You cannot restore this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({ deleteFileOrFolderDetails: { id: '', name: '', type: '', shouldDelete: false }, isNoPermission: true })
      return;
    }
    
    if(responseJson.message === "File moved successfully" || responseJson.message === "Folder moved successfully"){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({workLoading: false, showUndo: false})
      this.currentFileOrFolderName = ""
      clearTimeout(this.timeout)
      this.getAllFolders(false)
      this.getAllSubFolders(this.state.taskID, fid.id)
    }
  
  }

  checkResponseputRenameFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.message === "Folder renamed successfully." || responseJson.message === "File renamed successfully."){
      let fid = this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({ workLoading: false, deleteFileOrFolderDetails:{id:"",shouldDelete:false,type:'',name:''} })
      this.handleRenameModalPopUp()
      this.getAllSubFolders(this.state.taskID, fid.id)
      this.getAllFolders(false)
    }
    else if(responseJson.error === "Folder not found or unauthorized access." || responseJson.error === "File not found or unauthorized access."){
      this.permissionErrorText = `You cannot rename this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({ workLoading: false, deleteFileOrFolderDetails:{id:"",shouldDelete:false,type:'',name:''} })
      this.handleRenameModalPopUp()
      this.setState({isNoPermission:true})
    }
  
  }

  handleShowMoveNoPermissionModal = () => {
    this.permissionErrorText = `You cannot move this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
    this.handleMoveModalPopUp()
    this.setState({workLoading: false, isNoPermission: true, deleteFileOrFolderDetails:{ id:"", shouldDelete:false, type:'', name:'' }, folderNameSearchText: ""})
  }

  checkResponseputMoveFileApiCallId = async (responseJson:any)=>{

    if(responseJson.error === "You don't have permission to move this file.") {
      this.handleShowMoveNoPermissionModal()
      return;
    }

    if(responseJson.message === "File moved successfully." || responseJson.message === "Folder moved successfully."){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({workLoading:false, deleteFileOrFolderDetails:{ id:"", shouldDelete:false, type:'', name:'' }, folderNameSearchText: ""})
      this.handleMoveModalPopUp()
      this.getAllSubFolders(this.state.taskID, fid.id)
    }

  
  }

  checkResponseputMoveFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.message === "You can only move your own folders") {
      this.handleShowMoveNoPermissionModal()    
      return;
    }

    if(responseJson.message === "File moved successfully." || responseJson.message === "Folder moved successfully."){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.handleMoveModalPopUp()
      this.setState({workLoading:false, deleteFileOrFolderDetails:{ id:"", shouldDelete:false, type:'', name:'' }, folderNameSearchText: ""})
      this.getAllSubFolders(this.state.taskID, fid.id)
      this.getAllFolders(false)
    }

  
  }

  checkResponsedeleteFileOrFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.error === "Folder not found or unauthorized access." || responseJson.error === "File not found or unauthorized access.") {
      
      this.permissionErrorText = `You cannot delete this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({
        isNoPermission: true,
        deleteFileOrFolderDetails: { id: '',name: '', type: '', shouldDelete: false }
      })
    }

    if(responseJson.message === "File deleted successfully." || responseJson.message === "Folder deleted successfully."){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({workLoading:false})
      this.getAllSubFolders(this.state.taskID, fid.id)
      this.getAllFolders(false)
    }
  
  }

  checkResponsedeleteAllItemsFromArchive = async (responseJson:any)=>{

    let fid = await this.state.folderPath[this.state.folderPath.length - 1];
    this.setState({workLoading:false})
    this.getAllSubFolders(this.state.taskID, fid.id)
  
  }

  handleTaskApicall = (responseJson: any) => {
     if (responseJson.data && responseJson.data.length > 0) {
      this.setState({ taskProfile: responseJson.data,
        loading:false
      });
      const taskId = sessionStorage.getItem("taskID")

      if(!taskId){
        const folderPaths = sessionStorage.getItem("folderPaths")
        this.fetchAllFolders(responseJson.data[0].id, folderPaths)
      }
    } else {
      this.setState({ taskProfile: []});
    }
  }

  handleRedirectPayment = async() => {

    const ctoken: string = await getStorageData("clientTokes");
    const message = new Message(getName(MessageEnum.NavigationMessage));
      if (ctoken) { message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
      } else {  message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
      }
       message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  };

  handleRedirectLiveChat = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LiveChat");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  paymentRedirect = ()=>{
    window.location.href = '/payment'
  }
   handleFolderURL = async (responseJson: any) => {
    const files = responseJson.data.attributes.all_files;

    if (!files || files.length === 0) return;

    try {
        const downloadPromises = files.map(async (file: any) => {
            const response = await fetch(file.url);
            if (!response.ok) throw new Error(`Failed to download: ${file.url}`);

            const blob = await response.blob();
            saveAs(blob, `(${file.current_folder_name})${file.filename}`);
        });

        await Promise.all(downloadPromises);
    } catch (error) { }
};
  handleDocumentFolderNavigation = (taskID:string|number) =>{ sessionStorage.setItem("taskID", `${taskID}`)
    this.setState({taskID:taskID})
      this.getTaskData(taskID)
  }

  getToken = async () =>{
    const CloginTokens: string = await getStorageData("clientTokes");
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
    const maintokens = this.determineMainToken(metaSignUpToken, metaLoginToken);
    return maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token || CloginTokens
  }

  hanldeTaskIn = async() => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));
    
    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskList");
    }
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleFolderMoreOption = (index:number,x:any) =>{
    this.handleOpenOption(index)
    this.setState(
      {
        deleteFileOrFolderDetails:
        {id:x.data.id,shouldDelete:false,type:'folder',name:x.data.attributes.folder_name},
        isSizeExceeded: false,
        isWrongFileMimeType: false
      }
    )
  }

  hanldeRedirectChat = async() => {
    const ctoken: string = await getStorageData("clientTokes");
    const message = new Message(getName(MessageEnum.NavigationMessage));
    if (ctoken) {
    message.addData(getName(MessageEnum.NavigationTargetMessage), "live-chat"); 
    }  
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleFileMoreOption = (index:number,x:any) =>{
    this.handleOpenOptionF(index)
    this.setState(
      {
        deleteFileOrFolderDetails: {id:x.id,shouldDelete:false,type:'file',name:x.filename},
        downloadUrl:x.url,
        isSizeExceeded: false,
        isWrongFileMimeType: false
      }
    )
  }

  cancelFilesData = async () =>{
    this.setState({ 
      LoadedFiles: [], 
      LoadedFilesPreview: [], 
      uploadProgress: [],
      upLoading:false
    })
  }

  formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const day = String(date.getUTCDate()).padStart(2, '0'); 
    const month = monthNames[date.getUTCMonth()]; 
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  formatDateTimeA = (dateTime: string) => {
    const date = new Date(dateTime);

    const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    const day = String(date.getUTCDate()).padStart(2, '0'); 
    const month = monthNames[date.getUTCMonth()]; 
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }

  handleListMoreoptions = (i:number,x:any) =>{
    if(x.data){
      this.setState(
        {
        deleteFileOrFolderDetails:
          {id:x.data.id,shouldDelete:false,type:'folder',name:x.data.attributes.folder_name},
        closebox:true,
        infoModal:false,
        openOptionIndexL: this.state.openOptionIndexL === i ? -1 : i,
        isWrongFileMimeType: false, 
        isSizeExceeded: false,
        }
      )
    }
    else{
      this.setState(
        {
          deleteFileOrFolderDetails:
            {id:x.id,shouldDelete:false,type:'file',name:x.filename},
          downloadUrl:x.url,
          closebox:true,
          infoModal:false,
          isWrongFileMimeType: false, 
          isSizeExceeded: false,
          openOptionIndexL: this.state.openOptionIndexL === i ? -1 : i 
        }
      )
    }
  }

  openFolder = async (x:any) =>{
    const newFolderPaths = [...this.state.folderPath, {id:x.data.id, folderName:x.data.attributes.folder_name}]
    this.setState({
      folderPath:newFolderPaths,
      pageLoading: true,
      folders: [],
      files: [],
      archiveFolder: null,
      currentFolderId: x.data.id,
      isSizeExceeded: false,
      isWrongFileMimeType: false,
    })
    this.getAllSubFolders(this.state.taskID,x.data.id)
    sessionStorage.setItem("folderPaths", JSON.stringify(newFolderPaths))

    if ( x.data.attributes.folder_name === "Archive folder"){
      this.setState({
        isArchiveSection:true
      })
    }else{
      this.setState({
        isArchiveSection:false
      })    }

  }

  handleFileFolderPath = async (index:number) => {
    const newFolderPaths = [...this.state.folderPath].slice(0, index+1);
    let fid = newFolderPaths[newFolderPaths.length - 1];
    this.setState({
      pageLoading: true, 
      currentFolderId: fid.id, 
      folderPath: newFolderPaths,
      isSizeExceeded: false,
      isWrongFileMimeType: false,
    })
    this.getAllSubFolders(this.state.taskID,fid.id)
    sessionStorage.setItem("folderPaths", JSON.stringify(newFolderPaths))
  };

  handleChangeAddNewFolderInputValue = (e:any) =>{
    this.setState({addNewFolderInput:e.target.value})
  }
  handleSearchAndFilter = () => {
    const { taskName, taskProfile } = this.state;
  
    const filteredLawyerfirmResults = taskProfile.filter((task) => {
      if (!task || !task.attributes) return false;
  
      const searchTerm = taskName?.trim().toLowerCase();
      return task.attributes.task_name?.toLowerCase().includes(searchTerm);
    });
  
    this.setState({
      filterTask: filteredLawyerfirmResults,
    });
  };
  

  handleChange = (event:any) => {
    const searchValue = event.target.value;
    this.setState({ 
      taskName: searchValue,
      filterState: !!searchValue.trim(),
    }, () => {
      this.handleSearchAndFilter();
    });
  };
  handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      this.handleSearchAndFilter();
    }
  };

  handleRenameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ renameInput: event.target.value })
  }

  handleCreateFolder = async () => {
    if (this.state.addNewFolderInput) {
      this.onlyMoveFolders = true
      let fid = this.state.folderPath[this.state.folderPath.length - 1] 
      this.createSubFolders(fid.id, this.state.taskID, this.state.addNewFolderInput)
    }
  }

  apiCall = async (data: ApiCallData) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //  For Mobile
  getAllSuccessFunctionCallBack = (apiRequestCallId: string, responseJson: GetAllDocumentResponseJson & DocumentlistItemapi) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderSuccesCallBack(responseJson);
    }
  }

  getAllErrorFunctionCallBack = (apiRequestCallId: string, responseJson: Tokenexpire & string & Errorobject) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderFailureCallBack(responseJson);
    }
  }

  //  For Web

  getAllFolders= async (pageLoading: boolean) => {
    this.setState({pageLoading: pageLoading})
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllFoldersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders?task_id=${this.state.taskID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSubFolders= async (taskId:string|number,folderId:string|number) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubFoldersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/all_subfolders?folder_id=${folderId}&task_id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAllFolders = (taskID: string | null, folderPaths: string | null) => {
    if (taskID) {
      this.setState({ taskID: taskID, selectedTaskId: taskID, pageLoading: true }, () => {
        if (!folderPaths) {
          this.getAllFolders(true)
        } else {
          const parsedFolderPaths = JSON.parse(folderPaths) as S["folderPath"]
          this.setState({ folderPath: parsedFolderPaths })
          const lastFolder = parsedFolderPaths[parsedFolderPaths.length - 1]
          if (parsedFolderPaths.length === 0 || parsedFolderPaths.length === 1) {
            this.getAllFolders(true)
          } else {
            this.onlyMoveFolders = true
            this.getAllFolders(true)
            this.getAllSubFolders(taskID, lastFolder.id)
          }
        }
      })
    }
  }


  createSubFolders = async (folderId:string|number,taskId:string|number,folderName:string) =>{
    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken(),
    };

    const raw = JSON.stringify({
      "folder_name": folderName,
      "task_id": taskId
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSubFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/create_sub_folders?folder_id=${folderId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createArchiveFolder = async (taskId:string|number) =>{
    const header = {
      "Content-Type": "application/json",
    };

    const raw = JSON.stringify({
      "folder_name": "Archive Section",
      "task_id": taskId
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postArchiveFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/archive_folder`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  renameFileFolder = async (fId:string|number,type:string,newName:string) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putRenameFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/${fId}/rename_file_folder?type=${type}&new_name=${newName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  moveFolder = async (fId:string|number,folID:string|number) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.onlyMoveFolders = true  
    this.putMoveFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/move_folders?from_folder_id=${fId}&to_folder_id=${folID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  moveFile = async (fId:string|number,folID:string|number) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putMoveFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/move_file?file_id=${fId}&to_folder_id=${folID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  moveToArchiveFileFolder = async (taskId:string|number,from_folder_id:string|number,type:string) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putMoveToArchiveFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/move_to_archive_file_folder?task_id=${taskId}&${type === 'file' ? 'file_id' : 'from_folder_id'}=${from_folder_id}&type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  deleteFileOrFolder = async (fId:string|number,type:string) =>{

    this.setState({workLoading:true})

    const header = {
      "token":await this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFileOrFolderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/${fId}/delete_file_folder?type=${type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteFileFolder = () =>{
    this.deleteFileOrFolder(this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type)
  }

  handleRestoreFileOrFolder = () =>{
    this.restoreFileOrFolder(this.state.taskID,this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type)
  }

  handleUndoClick = () => {
    this.timeout = undefined;
    this.onlyMoveFolders = true
    this.setState({workLoading: true})
    this.restoreFileOrFolder(this.state.taskID, this.currentFolderId, this.currentFileFolderType)
  }

  restoreFileOrFolder = async (taskId:string|number,fromId:string|number,type:string) =>{

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putRestoreFileOrFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/restore_file_folder?task_id=${taskId}&${type === 'file' ? 'file_id' : 'from_folder_id'}=${fromId}&type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    this.currentFileFolderType = ""
    this.currentFolderId = ""

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleEmptyArchive = () =>{
    this.emptyArchive(this.state.archiveFolder.data.id,this.state.taskID)
  }

  emptyArchive = async (fId:string|number,taskId:string|number) =>{

    this.setState({workLoading:true})

    const header = {
      "token":await this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAllItemsFromArchive = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/${fId}/empty_archive?task_id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };

  uploadBulkAttachements = async (taskId:string|number,folderId:string|number)=>{
    
    const allNotUploading = this.state.uploadProgress.length > 0 ? 
    this.state.uploadProgress.every((obj:any) => obj.isUploading === false):false;
    if(!allNotUploading){
      return;
    }
    this.setState({upLoading:true})
    const formdata = new FormData(); 
    const filesArray = Array.from(this.state.LoadedFiles);
    filesArray.forEach((file) => {
        formdata.append("file[]", file, file.name);
    });
    const header = {
      token: await this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostBulkUploadEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/upload_file?folder_id=${folderId}&task_id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNoPermissionModalPopUp = () => {
    this.setState({isNoPermission:!this.state.isNoPermission})
  }

  handleOpenDeleteModalPopUp = () =>{
    this.setState({deleteModalPopUp:true,isSizeExceeded: false,isWrongFileMimeType: false})
  }

  handleCloseDeleteModalPopUp = () => {
    this.setState({
      deleteModalPopUp:false,
      deleteFileOrFolderDetails:{id:'',shouldDelete:false,type:'',name:''},
      workLoading: false,
    })
  }

  handleDeleteFileORFolder = () =>{
    this.onlyMoveFolders = true
    this.moveToArchiveFileFolder(this.state.taskID,this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type)
  }

  handleNewFolderModalPopUp = () => {
   let  clientToken = localStorage.getItem("clientTokes")
   if (clientToken || this.state.activeTab === "active") {
    this.setState({
      newFolderModalPopUp: !this.state.newFolderModalPopUp,
      isSizeExceeded: false,
      isWrongFileMimeType: false,
      addNewFolderInput: "",
    });
  }
    
  }

  handleRenameModalPopUp = () => {
    this.setState({
      renameModalPopUp:!this.state.renameModalPopUp, 
      renameInput:this.state.deleteFileOrFolderDetails.name, 
      workLoading: false,
      isSizeExceeded: false,
      isWrongFileMimeType: false
    })
  }

  handleRenameFileFolder = () => {
    this.onlyMoveFolders = true
    this.renameFileFolder(this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type,this.state.renameInput)
  }

  handleMoveModalPopUp = () => {
    this.setState({
      moveModalPopUp:!this.state.moveModalPopUp,
      selectedFolderForMove:'',
      folderLevel:[], 
      workLoading: false,
      isSizeExceeded: false,
      isWrongFileMimeType: false,
      folderNameSearchText: "",
    })
  }

  handleMoveFileFolder = () =>{
    if(this.state.deleteFileOrFolderDetails.type === 'file'){
      this.moveFile(this.state.deleteFileOrFolderDetails.id,this.state.selectedFolderForMove)
    }
    else if(this.state.deleteFileOrFolderDetails.type === 'folder'){
      this.moveFolder(this.state.deleteFileOrFolderDetails.id,this.state.selectedFolderForMove)
    }
  }

  getTaskLists = async() => {
    const headerVal = await this.getTokenUrlVal()
    const header = {
      "token": headerVal.newToken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${headerVal.newUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFolderUrl = async(id: any) => {
    const headerVal = await this.getTokenUrlVal()
    const header = {
      "token": headerVal.newToken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFolderURLCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.folderurl}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTokenUrlVal = async () => {
    const ctoken: string = await getStorageData("clientTokes");
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const mainNewToken =  maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token
    let newToken
    let newUrl
    if(ctoken){
      newToken = ctoken
      newUrl = configJSON.clientListApiEndPoint
    }else{
      switch(maintoken?.user_role){
        case "lawfirm_admin":
          newToken = mainNewToken
          newUrl = configJSON.tasksApiLawfirmEndPoint
          break;
        case "lawyer":
          newToken = mainNewToken
          newUrl = configJSON.tasksListLawyerApiEndPoint
          break;
      }
    }
    return {newToken,newUrl}
  }

  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string };user_role:string },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string };user_role:string }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string };user_role:string } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  getTaskData = async(taskID:string | number) => {
    const ctoken: string = await getStorageData("clientTokes");
    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskDataApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.clientTaskData}${taskID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSelectedFolderForMove = (folderId:string | number) =>{
    this.setState({selectedFolderForMove:folderId})
  }

  toggleFolderLevel = (x:any) =>{
    this.setState((prevState:any) => {
      const { folderLevel } = prevState;
      if (folderLevel.includes(x)) {
        return { folderLevel: folderLevel.filter((num:any) => num !== x) };
      } else {
        return { folderLevel: [...folderLevel, x] };
      }
    });
  }

  showSubMenu = (event:any,x:any) =>{
    if(event.target.parentElement.nextElementSibling.classList.contains('showSubMenu')){
    if(event.target.parentElement.nextElementSibling.style.display === 'none'){
      event.target.parentElement.nextElementSibling.style.display = 'block'
      this.toggleFolderLevel(x)
    }else{
      event.target.parentElement.nextElementSibling.style.display = 'none'
      this.toggleFolderLevel(x)
    }
  }
  }

  mainSubParent = (event:any,x:any) =>{
    if(event.target.parentElement.parentElement.nextElementSibling.classList.contains('showSubMenu')){
      if(event.target.parentElement.parentElement.nextElementSibling.style.display === 'none'){
        event.target.parentElement.parentElement.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.parentElement.parentElement.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  mainSubParentParent = (event:any,x:any) =>{
    if(event.target.parentElement.parentElement.parentElement.nextElementSibling.classList.contains('showSubMenu')){
      if(event.target.parentElement.parentElement.parentElement.nextElementSibling.style.display === 'none'){
        event.target.parentElement.parentElement.parentElement.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.parentElement.parentElement.parentElement.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  showMainSub = (event:any,x:any) =>{
    if(event.target.nextElementSibling.classList.contains('showSubMenu')){
      if(event.target.nextElementSibling.style.display === 'none'){
        event.target.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  showSubSub = (event:any,x:any) =>{
    if(event.target.parentElement.classList.contains('showSubMenu')){
      if(event.target.nextElementSibling.style.display === 'none'){
        event.target.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  toggleSubMenu = (event: any, x: any) => {
    if (event.target.parentElement.classList.contains('selectFolder')) {
      return;
    } else if (event.target.classList.contains('mainFolder')) {
      this.showMainSub(event, x);
    } else if (event.target.parentElement.parentElement.parentElement.classList.contains('mainFolder')) {
      this.mainSubParentParent(event, x);
    } else if (event.target.parentElement.parentElement.classList.contains('mainFolder')) {
      this.mainSubParent(event, x);
    } else if (event.target.parentElement.classList.contains('mainFolder')) {
      this.showSubMenu(event, x);
    } else if (event.target.classList.contains('subFolder')) {
      this.showSubSub(event, x);
    } else if (event.target.parentElement.parentElement.parentElement.classList.contains('subFolder')) {
      this.mainSubParentParent(event, x);
    } else if (event.target.parentElement.parentElement.classList.contains('subFolder')) {
      this.mainSubParent(event, x);
    } else if (event.target.parentElement.classList.contains('subFolder')) {
      this.showSubMenu(event, x); 
    }
  }

  handleInfoModal = () =>{
    this.setState({infoModal:!this.state.infoModal,closebox:true})
  }

  handleMoreOptions = () =>{
    this.setState({
      moreOptions:!this.state.moreOptions
    })
  }

  handleViewMode = (mode:string) => {
    this.setState({viewMode:mode})
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ 
      mobileOpen: false 
    });
  }

 handleDownload = async (filename : any) => {
    if (!this.state.downloadUrl) return;

    try {
        const response = await fetch(this.state.downloadUrl);
        if (!response.ok) throw new Error(`Failed to download: ${response.statusText}`);

        const blob = await response.blob();
        saveAs(blob, filename);
    } catch (error) {  }
};

handleDownloadFolder =  (folderName : any,id:any) => {
  this.getFolderUrl(id)
  this.setState({selectedFolder: folderName})
};
   

  handleOpenOption = (index:any) => {
    this.setState(prevState => ({
      openOptionIndex: prevState.openOptionIndex === index ? null : index 
    }));
    this.setState({closebox:true,infoModal:false})
  }
  handleOpenOptionF = (index:any) => {
    this.setState(prevState => ({
      openOptionIndexF: prevState.openOptionIndexF === index ? null : index 
    }));
    this.setState({closebox:true,infoModal:false})
  }
  handleCloseBox=()=>{
    if(this.state.closebox){
      this.setState({openOptionIndexL:-1,
         openOptionIndexF:-1,openOptionIndex:-1,
         closebox:false,infoModal:false,inFilterModal:false,
         filterUploadedDateRangeStartValue:''})
    }
  }

  formatFileSize(bytes: number): string {
    if (bytes < 1024) return `${bytes} B`;
    else if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
    else if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(2)} MB`;
    else return `${(bytes / 1073741824).toFixed(2)} GB`;
  }

  handleDelete = (fileName: string) => { this.removeFileData(fileName);};

  isValidFileMimeType = (files: FileList) => {
    const filesMimeType = new Set([
      "image/png",
      "image/jpg",
      "image/jpeg",
      "video/mp4",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf"
    ])
    const doesFileMimeTypeExist = Array.from(files).some(file => filesMimeType.has(file.type))
    return doesFileMimeTypeExist
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const maxFileSize = 52428800;
      const isValidFileMimeType = this.isValidFileMimeType(files)
      if (!isValidFileMimeType) {
        this.setState({ isWrongFileMimeType: true, isSizeExceeded: false })
        return;
      }
      const isSizeExceeded = Array.from(files).some(file => file.size > maxFileSize)
      if (isSizeExceeded) {
        this.setState({ isWrongFileMimeType: false, isSizeExceeded: true })
        return;
      }
      this.setState({ isWrongFileMimeType: false, isSizeExceeded: false })
      this.processFiles(files);
    }
  };

  handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }


  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation()
    const files = event.dataTransfer.files;
    const doesFileMimeTypeExist = this.isValidFileMimeType(files)
    if (!doesFileMimeTypeExist) {
      this.setState({ isWrongFileMimeType: true, isSizeExceeded: false })
      return;
    }
    if (files && files.length > 0) {
      const maxFileSize = 52428800;
      const isSizeExceeded = Array.from(files).some((file) => file.size > maxFileSize)
      if (isSizeExceeded) {
        this.setState({ isSizeExceeded: true, isWrongFileMimeType: false })
        return;
      }
      this.setState({ isSizeExceeded: false, isWrongFileMimeType: false })
      this.processFiles(files);
    }
  };

  toggleSidebar = (id: string) => {
    this.setState((prevState) => ({
      openCollapse: id === 'openCollapse' ? !prevState.openCollapse : false,
      openSecondCollapse: id === 'openSecondCollapse' ? !prevState.openSecondCollapse : false,
    }));
  };

  handleTab = (tab: string) => {
    this.setState({ activeTab: tab });
  };

  processFiles = (files: any) => {
    const fileArray = [...files];
    const previews = fileArray.map((file) => ({
      file: URL.createObjectURL(file),
      fileName: file.name,
      fileSize: file.size,
    }));

    const uploadProgress = fileArray.map((file) => ({
      fileName: file.name,
      progress: 0,
      isUploading: true,
    }));

    this.setState(
      { LoadedFiles: [...this.state.LoadedFiles, ...fileArray], LoadedFilesPreview: [...this.state.LoadedFilesPreview, ...previews], uploadProgress: [...this.state.uploadProgress, ...uploadProgress] },
      () => {
        this.uploadFiles(fileArray);
      }
    );
  };

  uploadFiles = (files: File[]) => {
    files.forEach((file) => {
      const controller = new AbortController();
      this.uploadControllers[file.name] = controller;
      const updateProgress = (progress: number) => {
        this.setState((prevState) => ({
          uploadProgress: prevState.uploadProgress.map((p: any) =>
            p.fileName === file.name ? { ...p, progress } : p
          ),
        }));
      };
      this.simulateUpload(file, updateProgress, controller);
    });
  };

  simulateUpload = (
    file: File,
    updateProgress: (progress: number) => void,
    controller: AbortController
  ) => {
    let progress = 0;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += 1;
        updateProgress(progress);
      } else {
        clearInterval(interval);
        this.setState((prevState) => ({
          uploadProgress: prevState.uploadProgress.map((p: any) =>
            p.fileName === file.name ? { ...p, isUploading: false } : p
          ),
        }));
      }
    }, 20);

    controller.signal.addEventListener("abort", () => {
      clearInterval(interval);
      updateProgress(0);
      this.removeFileData(file.name);
    });
  };

  cancelUpload = (fileName: string) => {
    const controller = this.uploadControllers[fileName];
    if (controller) {
      controller.abort();
      delete this.uploadControllers[fileName];
    }
  };

  removeFileData = (fileName: string) => {
    this.setState((prevState) => ({
      ...this.state,
      LoadedFiles: prevState.LoadedFiles.filter((file) => file.name !== fileName),
      LoadedFilesPreview: prevState.LoadedFilesPreview.filter((preview: any) => preview.fileName !== fileName),
      uploadProgress: prevState.uploadProgress.filter((progress: any) => progress.fileName !== fileName),
    }));
  };



  // API Success and Failure Callbacks for Web



  // API Success and Failure Callbacks for Mobile

  getAllDocListSuccesCallBack =  (responseJson: DocumentlistItemapi) => {
    this.setState({ isloading: false}
      ,  () => {
        this.setState({ documentList: responseJson.data[0].attributes.gallery_medias.concat(responseJson.data[0].attributes.folder.data) });
      }
    )
  };

  getAllDocListFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== errorResponseJson", responseJson);
  };

  createDocumentSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false })
    if (responseJson.data) {
      this.getAllDocumentList()
    }
  };

  createDocumentFailureCallBack =  (responseJson: Errorobject) => {
    this.setState({ isloading: false })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };
 
  deleteFileSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModalFile: false, deleteModalnative: false, blobIdNumber: 0, fileDeleteText: '', isloading: false })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  deleteFileFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== deleteFileFailureCallBack", responseJson);
  };

  renameNewFileSuccesCallBack = async (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, blobIdNumber: 0, fileRenameText: '' })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  renameNewFileFailureCallBack =  (responseJson: Errorobject) => {
    this.setState({ isloading: false, modalRename: false, })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };

  addNewFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ folderModal: false }, () => {
      this.getAllDocumentList()
    })
    this.setState({ isloading: false })
  };

  addNewFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
  };

  deleteFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModal: false, isloading: false, deleteModalnative: false, floderId: '', fileDeleteText: '' })
    this.showAlert('Alert', responseJson.message)
    this.getAllDocumentList()
  };

  deleteFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
  };

  renameFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, floderId: '', folderNameNative: '', })
    this.showAlert('Alert', responseJson.message)
    this.getAllDocumentList()
  };

  renameFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false, modalRename: false, })
  };

  getAllDocumentList = async () => {
    this.setState({ isloading: true })
    this.getAllDocumentsApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentation/gallery_documents/account_gallery?gallery_type=documents`,
      body: "",
      type: ""
    });
  }

  DeleteFileApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber
        }
      }
    }
    this.getDeleteFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`/bx_block_documentation/gallery_documents/${this.state.documentId}/destroy_media_file`,
      body: apidata,
      type: ""
    });
  }

  RenameFileApiCell = async () => {
    this.setState({ isloading: true })
    const apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber,
          filename: this.state.fileRenameText
        }
      }
    }
    this.getPatchRenameFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`bx_block_documentation/gallery_documents/${this.state.documentId}/update_file_name`,
      body: apidata,
      type: ""
    });
  }

  addFolderApiCell = async () => {
    if (this.state.folderNameNative.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.folderNameNative
        }
      }
    }

    this.getAddFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: `bx_block_documentation/folder_documents`,
      body: apidata,
      type: ""
    });
  }

  RenameFolderApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      Alert.alert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.fileRenameText,
        }
      }
    }
    this.getRenameFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`bx_block_documentation/folder_documents/${this.state.floderId}/update_folder_name`,
      body: apidata,
      type: ""
    });
  }

  DeleteFolderApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
        }
      }
    }
    this.getDeleteFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`bx_block_documentation/folder_documents/${this.state.floderId}`,
      body: apidata,
      type: ""
    });
  }
  closeSizeModal=()=>{
    this.setState({isSizeExceeded:false, isWrongFileMimeType: false})
  }

  threeDotsTouchable = (item: Itemmedia) => item.type !== 'folder' ? this.onRequestClose(item.blob_id, item.filename) : this.onFolderRenameandDelete(item.id, item.attributes.folder_name)

  onRequestClose = (Item_id: number, fileName: string) => this.setState({ blobIdNumber: Item_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: false, isDelete: false }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  onFolderRenameandDelete = (Items_id: string, fileName: string) => this.setState({ floderId: Items_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: true, isDelete: true }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  folderClickButton = () => this.setState({ folderModal: true, create: false, cancel: false, folderNameNative: '' })

  modalDotsTrue = () => this.setState({ modalDots: false })

  modalOverMOdal = () => this.setState({ modalDots: false, modalRename: true, })

  modalDeleteOverMOdal = () => this.setState({ modalDots: false, deleteModalnative: true, })

  navigateScreen =  (item: Itemmedia) => { this.props.navigation.navigate('DocumentationFolder', { item: item, documentId: this.state.documentId }) }

  changeTextFolderModal = (text: string) => { this.setState({ folderNameNative: text }) }

  RenameChangeFileModal = (text: string) => { this.setState({ fileRenameText: text }) }

  changeDeleteFileModal = (text: string) => { this.setState({ fileDeleteText: text }) }

  cancelFolderModal = () => this.setState({ folderModal: false, cancel: true, create: false })

  cancelDeleteFile = () => this.setState({ deleteModalnative: false, cancel: true, delete: false })

  cancelFolderRename = () => this.setState({ modalRename: false, cancel: true, rename: false })

  createFolderModal = () => { this.setState({ create: true, cancel: false, }, () => this.addFolderApiCell()) }

  responseRenameFile = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFileApiCell()) }

  responseRenameFolder = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFolderApiCell()) }

  responseDeleteFile = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModalFile: true }) }

  responseDeleteFolder = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModal: true }) }

  responseDeleteFolderYesNo = () => { this.DeleteFolderApiCell() }

  responseDeleteFolderYesNoFile = () => { this.DeleteFileApiCell() }

  uplaodCsvFile = async () => {
    try {
      const isResponse = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf]
      })
    }
    catch (error) {
    }
  };

  yesNoModalFalse = () => this.setState({ yesNoModal: false, yesNoModalFile: false })


  onOwnerSearch = async(searchText: string) => {
    const token = await this.getToken()
    this.setState({ownerNameLoading: true, ownerNameOptions: []})
    const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))
    reqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET")
    reqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify({token, "Content-Type": "application/json"}))
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ownerNames}?id=${this.state.taskID}&first_name=${searchText}`
    )
    this.ownerNameSearchAPICallId = reqMsg.messageId
    this.send(reqMsg)
  }

  handleDocumentationSearch = async(args: DocumentationSearchArgs) => {
    let params = `filter=true&task_id=${this.state.taskID}&folder_id=${this.state.currentFolderId}`
    if(!args.typeOption && !args.dateUploaded && !args.ownerName && !args.fileOrFolderName) {
      this.setState({pageLoading: true})
      const lastFolder = this.state.folderPath[this.state.folderPath.length - 1]
      this.getAllSubFolders(this.state.taskID, lastFolder.id)
      return;
    }

    if(args.dateUploaded) {
      params = params + `&date_uploaded=${new Date(args.dateUploaded.format("YYYY-MM-DD")).toISOString()}`
    }

    if(args.fileOrFolderName) {
      params = params + `&name=${args.fileOrFolderName}`
    }

    if(args.typeOption) {
      params = params + `&type=${args.typeOption.value}`
    }

    if(args.ownerName) {
      params = params + `&owner_name=${args.ownerName}`
    }

    this.setState({pageLoading: true, folders: [], files: [], archiveFolder: null, isWrongFileMimeType: false, isSizeExceeded: false})
    const token = await this.getToken()

    const newReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token
      })
    )
    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )
    newReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.documentationSearch}?${params}`
    )

    this.documentationSearchAPICallId = newReqMessage.messageId
    
    this.send(newReqMessage)
  }

  handleRemoveUploadFileError = () => {
    this.setState({isSizeExceeded: false, isWrongFileMimeType: false})
  }

  handleChangeFolderNameSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({folderNameSearchText: event.target.value})
  }

  flatMoveFolders = (folders: MoveFolder[], newFolders: Array<Omit<MoveFolder, "subFolders">>) => {
    folders.forEach((folder) => {
      newFolders.push({
        id: folder.id,
        name: folder.name,
        parentId: folder.parentId
      })
      this.flatMoveFolders(folder.subFolders, newFolders)
    })
    return newFolders
  }

  createNestedFolder = (folders: Array<Omit<MoveFolder, "subFolders">>) => {
    const foldersMap = new Map<string, MoveFolder>()

    folders.forEach((folder) => {
      foldersMap.set(folder.id, {
        ...folder,
        subFolders: []
      })
    })

    folders.forEach((folder) => {
      if(folder.parentId) {
        const parentFolder = foldersMap.get(folder.parentId)
        if(parentFolder && parentFolder.name !== "Root_folder") {
          parentFolder.subFolders.push(foldersMap.get(folder.id)!)
          foldersMap.delete(folder.id)
        }
      }
    })

    return Array.from(foldersMap.values())
  }
  
  filteredFolders = () => {
    if(this.state.folderNameSearchText.trim().length === 0) {
      return this.state.moveFolders
    }
    const searchText = this.state.folderNameSearchText.toLowerCase()

    const folders = this.flatMoveFolders(this.state.moveFolders, [])
    const filteredFolders = folders.filter((folder) => folder.name.toLowerCase().includes(searchText))
    return this.createNestedFolder(filteredFolders)
  }
}

// Customizable Area End
