import React from "react";
// Customizable Area Start

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { logo, userpic, bag, earth, tooltipIcon } from "./assets";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import {
  styled,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Box,
  Chip,
  Typography,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { LegalServiceType } from "./AdminLawyerController.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        backgroundColor: "#fff",
        zIndex: 999,
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width: "450px",
        borderRadius: "12px",
        padding: "10px",
      },
    },
  },
});
// Customizable Area End

import AdminLawyerController, { Props } from "./AdminLawyerController.web";

export default class AdminLawyerManagement extends AdminLawyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getButtonClass = (status: string): string => {
    return status === "active" ? "activebtnCss" : "inactivebtnCss";
  };
  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? "block" : "" }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo.default} alt="logc1o" className="logoimag" />
      </div>
      {this.state.mobileOpen && (
        <CloseIcon
          style={{
            position: "fixed",
            top: "30px",
            left: "260px",
            cursor: "pointer",
          }}
          onClick={this.handleclosesidebar}
        />
      )}
      <div
        className="settingbox"
        onClick={this.handleBack}
        data-test-id="settingid"
      >
        <ChevronLeftIcon className="lefticon" />
        <Typography className="settingtext">Back</Typography>
      </div>
      <List style={{ padding: "8px 13px" }}>
        <ListItem
          className="listbox1"
          onClick={this.handleAdminLawyerManagement}
        >
          <ListItemIcon>
            <PeopleAltOutlinedIcon className="icondrawer1" />
          </ListItemIcon>
          <ListItemText className="listtile1">
            Lawyer Management
            <Typography className="listtext">
              Settings related to your personal information and account
              credentials
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleLawfirmManagment}>
          <ListItemIcon>
            <PermIdentityIcon className="icondrawer2" />
          </ListItemIcon>
          <ListItemText className="listtile">
            Lawfirm Management
            <Typography
              className="listtext"
              style={{ whiteSpace: "none !important" }}
            >
              View Digip's Terms and Conditions and Privacy Policy
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          data-test-id="navigateToServiceManagement"
          className="listbox"
          onClick={this.handleServicesManagement}
        >
          <ListItemIcon>
            <BusinessCenterOutlinedIcon className="icondrawer3" />
          </ListItemIcon>
          <ListItemText className="listtile">
            Services Management
            <Typography className="listtext">
              Manage all services here
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </DrawerBox>
  );

  topbox = () => {
    const { userdata } = this.state;
    const filteredLawyer = userdata.find((data) => {
      const attributes =
        data.attributes.associated_lawyer_details.data.attributes;
      return attributes && attributes.firm;
    });
    const firmName = filteredLawyer
      ? filteredLawyer.attributes.associated_lawyer_details.data.attributes.firm
      : "";
    return (
      <Box className="topbox">
        <Typography className="titleuser" data-test-id="Lawfirm_Management">
          {firmName} /{" "}
          <span style={{ color: "#595959" }}>Associated Lawyers</span>
        </Typography>
      </Box>
    );
  };

  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: "24px", color: "#000" }}
      >
        <MenuIcon />
      </IconButton>
    </ButtonBox>
  );

  userMultiValues = (attributes: any) => {
    return (
      <div className="userbox2">
        <Box
          style={{ display: "flex", marginBottom: "7px", alignItems: "center" }}
        >
          <img src={bag} alt="Bag" className="bagCss" />
          <Typography className="servicesLegalCss">Legal Services</Typography>
        </Box>
        <Box style={{ display: "flex" }}>
          {!attributes?.legel_service_type ||
          attributes.legel_service_type.length == 0 ? (
            <Typography
              className="FamilyTextCss"
              style={{ padding: "3px 5px" }}
            >
              Family Law
            </Typography>
          ) : (
            <>
              {attributes.legel_service_type
                .slice(0, 2)
                .map((e: { legal_service_name: string }, i: number) => (
                  <Typography
                    key={i}
                    className="FamilyTextCss"
                    style={{ padding: "3px 5px", marginRight: "7px" }}
                  >
                    {e.legal_service_name || "Family Law"}
                  </Typography>
                ))}

              {attributes.legel_service_type.length > 2 && (
                <Box>
                  <MuiThemeProvider theme={theme}>
                    <Tooltip
                      title={
                        <div style={tooltipStyles.tooltipContiane}>
                          <div style={tooltipStyles.tooltipSubContaine}>
                            <div style={tooltipStyles.tooltipGridContaine}>
                              {attributes.legel_service_type
                                .slice(2)
                                .map(
                                  (
                                    service: LegalServiceType,
                                    index: number
                                  ) => (
                                    <Chip
                                      key={index}
                                      label={service.legal_service_name}
                                      className="popup-chip"
                                      style={tooltipStyles.chipStyles}
                                    />
                                  )
                                )}
                            </div>
                            <div
                              className="popup-image"
                              style={tooltipStyles.tooltipimgcontaine}
                            >
                              <img
                                src={tooltipIcon}
                                style={tooltipStyles.imgStyle}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <Typography
                        className="FamilyTextCss"
                        style={tooltipStyles.lenghtStyle}
                      >
                        +{attributes.legel_service_type.length - 2}
                      </Typography>
                    </Tooltip>
                  </MuiThemeProvider>
                </Box>
              )}
            </>
          )}
        </Box>
      </div>
    );
  };
  UserMapBox = () => {
    return (
      <Box className="userContainer">
        {this.state.userdata.map((data, index) => {
          const attributes =
            data.attributes.associated_lawyer_details?.data.attributes;
          const isOpen = this.state.openOptionIndex === index;
          return (
            <Box key={index} className="userBoxData">
              <div className="userbox1">
                <Box className="userProfile">
                  <Box>
                    <img
                      src={attributes?.profile_image?.url || userpic}
                      alt="Profile"
                      className="imageBox"
                    />
                  </Box>
                  <Box style={{ marginTop: "4px" }}>
                    <Typography className="userName">
                      {attributes?.first_name || "Maya Diab"}
                    </Typography>
                    <Typography className="userContent">
                      <span style={{ paddingRight: "4px" }}>
                        {attributes?.firm || "Perly Lawfirm"}
                      </span>
                      <StarIcon className="starIconCss" />
                      <span style={{ paddingLeft: "15px", color: "#8C8C8C" }}>
                        4.2
                      </span>
                    </Typography>
                  </Box>
                  <MoreVertIcon
                    className="tripledotcss"
                    data-test-id="tripledot"
                    onClick={() => this.handleOpenOption(index)}
                  />
                  {isOpen && (
                    <Box className="optionCss">
                      <button
                        className="EditBtnCss"
                        data-test-id="editbtntest"
                        onClick={() => this.handleEditOpen(data.id)}
                        style={{ paddingRight: "20px" }}
                      >
                        <EditIcon
                          style={{ marginRight: "3px", color: "#595959" }}
                        />
                        Edit
                      </button>
                      <button
                        className="EditBtnCss"
                        data-test-id="removetntest"
                        onClick={() => this.handleOpenDelete(data)}
                      >
                        <DeleteOutlineIcon style={{ color: "#595959" }} />
                        Remove
                      </button>
                    </Box>
                  )}
                </Box>
              </div>
              {this.userMultiValues(attributes)}
              <div className="userbox3">
                <Box
                  style={{
                    display: "flex",
                    marginBottom: "7px",
                    alignItems: "center",
                  }}
                >
                  <img src={earth} alt="Earth" className="bagCss" />
                  <Typography className="servicesLegalCss">
                    Jurisdiction
                  </Typography>
                </Box>
                <Box>
                  <span
                    className="Jurisditincss"
                    style={{ padding: "3px 10px", marginRight: "7px" }}
                  >
                    {attributes?.jurisdiction || "USA"}
                  </span>
                </Box>
              </div>
              <div className="userbox4">
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Box style={{ display: "flex" }}>
                      <StarIcon
                        style={{
                          fontSize: "20px",
                          color: "#F59E0B",
                          marginTop: "3px",
                        }}
                      />
                      <Typography className="text4css">4.2</Typography>
                    </Box>
                    <Typography className="yearExpCss">
                      {`${
                        attributes?.years_of_experience || "5"
                      } Years of experience`}
                    </Typography>
                  </div>
                  <Box>
                    <button
                      data-test-id="statusbtn"
                      className={this.getButtonClass(attributes?.lawyer_status)}
                    >
                      {attributes?.lawyer_status}
                    </button>
                  </Box>
                </Box>
              </div>
            </Box>
          );
        })}
      </Box>
    );
  };

  userBox = () => {
    return (
      <>
        {this.state.noUserData === "" ? (
          <>
            {this.state.userdata.length > 0 ? (
              <>
                {this.topbox()}
                {this.UserMapBox()}
              </>
            ) : (
              <span
                style={{
                  display: "flex",
                  margin: "0px auto",
                  alignItems: "center",
                  height: "100vh",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </span>
            )}
          </>
        ) : (
          <Typography className="nouserdatacss">
            {this.state.noUserData}
          </Typography>
        )}
      </>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainDiv
        data-test-id="AdminLawyerManagement"
        onClick={this.handleCloseOption}>
       
                 <Box style={{display:'flex', justifyContent:'start', alignItems:'start', width:'100%'}}>
                               <Box>
                                   <NavigationMenu navigation={this.props.navigation} id={""} />
                               </Box>
                               <Box className="repsonsive">
        <Box
          className="rightbar"
          data-test-id="rightbar"
          onClick={this.handleCloseBox}
        >
          {this.userBox()}
          <Dialog
            open={this.state.deletePopUp}
            onClose={this.handleCloseDelete}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
              style: {
                borderRadius: "16px",
              },
            }}
          >
            <DialogTitle id="responsive-dialog-title">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #F5F5F5",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DeleteOutlineIcon
                    style={{ color: "#355EE7", fontSize: "32px" }}
                  />{" "}
                  <span
                    style={{
                      marginLeft: "8px",
                      color: "#000000",
                      padding: "10px 0px",
                      fontSize: "22px",
                      fontWeight: 400,
                      fontFamily: "DM Sans",
                    }}
                  >
                    Remove Lawyer
                  </span>
                </span>
                <CloseIcon
                  style={{ cursor: "pointer", marginTop: "12px" }}
                  onClick={this.handleCloseDelete}
                />
              </div>
            </DialogTitle>
            <DialogContent>
              {" "}
              <DialogContentText>
                <Typography
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#595959",
                    padding: "10px 0px",
                    fontSize: "20px",
                    fontWeight: 400,
                    fontFamily: "DM Sans",
                  }}
                >
                  Are you sure you want to remove{" "}
                  <span style={{ color: "#2348C5" }}>
                    {this.state.deleteUserData.attributes.user_name ||
                      "Maya Diab"}
                  </span>{" "}
                  from{" "}
                  <span>
                    {this.state.deleteUserData.attributes.firm_name ||
                      "Perly Law Firm"}
                  </span>
                  ?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                padding: "20px",
                borderTop: "1px solid #E2E8F0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ButtonBox2>
                <button
                  data-test-id="deleteBtnId"
                  autoFocus
                  onClick={this.handleCloseDelete}
                  className="keepbtncss"
                >
                  No , Keep it
                </button>
              </ButtonBox2>
              <ButtonBox2>
                <button
                  className="logtxtdialog2"
                  onClick={() =>
                    this.handleDeleteUser(this.state.deleteUserData.id)
                  }
                  autoFocus
                >
                  Yes , Remove
                </button>
              </ButtonBox2>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.openDialogAlert}
            onClose={this.handleClosePopUpALert}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
              style: {
                borderRadius: "16px",
                minWidth: "430px",
                textAlign: "center",
              },
            }}
          >
            <DialogTitle
              id="responsive-dialog-title"
              style={{ textAlign: "right" }}
            >
              <CloseIcon onClick={this.handleClosePopUpALert} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography style={{ textAlign: "center" }}>
                  {this.state.showResponse.success ? (
                    <CheckCircleIcon
                      style={{
                        color: "#34D399",
                        fontSize: "40px",
                        fontWeight: 500,
                        marginBottom: "20px",
                      }}
                    />
                  ) : (
                    <ErrorOutlineIcon
                      style={{
                        color: "#DC2626",
                        fontSize: "40px",
                        fontWeight: 500,
                        marginBottom: "20px",
                      }}
                    />
                  )}
                </Typography>
                <Typography
                  style={{
                    color: "#262626",
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "DM Sans",
                    marginBottom: "10px",
                  }}
                >
                  {this.state.showResponse?.error ||
                    this.state.showResponse?.success}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ padding: "20px", borderTop: "1px solid #E2E8F0" }}
            >
              <Button
                variant="contained"
                onClick={this.handleClosePopUpALert}
                autoFocus
                style={{
                  backgroundColor: "#355EE7",
                  color: "#ffffff",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: "DM Sans",
                  width: "100%",
                  textTransform: "none",
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
         </Box>
                                        </Box>
      </MainDiv>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ButtonBox2 = styled("div")({
  width: "48%",
  cursor: "pointer",
  "& .logtxtdialog2": {
    width: "100%",
    padding: "15px",
    backgroundColor: "#355EE7 ",
    color: "#ffffff ",
    borderRadius: "8px ",
    fontSize: "16px ",
    fontWeight: 400,
    fontFamily: "DM Sans ",
    boxShadow: "none !important",
    border: "2px solid #355EE7 ",
    cursor: "pointer",
    "&:hover": {
      border: "2px solid #B9C5FF",
      boxShadow: "none !important",
    },
  },
  "& .keepbtncss": {
    color: "#595959",
    width: "100%",
    borderRadius: "8px ",
    fontSize: "16px ",
    fontWeight: 400,
    fontFamily: "DM Sans ",
    border: "1px solid #595959",
    boxShadow: "none !important",
    padding: "15px",
    background: "#fff",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none !important",
      border: "2px solid #B9C5FF",
      color: "#355EE7",
    },
  },
});

const MainDiv = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  overflow: "hidden",

  " ::-webkit-scrollbar": {
    width: "10px !important",
  },
  " ::-webkit-scrollbar-track": {
    background: "red !important",
  },
  " ::-webkit-scrollbar-thumb": {
    background: "red !important",
  },
  " ::-webkit-scrollbar-thumb:hover": {
    background: "red !important",
  },
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .MuiSelect-selectMenu:focus": {
    backgroundColor: "#fff",
  },

  "& .userContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    marginBottom: "30px",
  },
  "& .rightbar": {
    marginLeft: '114px',
    marginTop: '100px',
    padding: "20px 5px",
    "@media (max-width: 1100px)": {
      padding: "20 40px",
      width: "calc(100% - 355px)",
      marginLeft: "355px",
    },
    "@media (max-width: 991px)": {
      width: "auto !important",
      marginLeft: "0px",
      marginTop: "25px",
      padding: "10px 50px",
    },
  },
  "& .topbox": {
    display: "flex",
    width: "auto",
    height: "70px",
    marginBottom: "20px",
    justifyContent: "space-between",
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    marginLeft: "6px",
    fontSize: "22px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: "#000",
    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },
  "& .titlbtn": {
    height: "44px",
    width: "163px",
    border: "1px solid #355EE7",
    borderRadius: "8px",
    padding: "10px 12px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#355EE7",
    fontFamily: "DM Sans",
    "@media (max-width: 450px)": {
      height: "40px",
      width: "140px",
      padding: "7px 9px",
    },
  },
  "& .titlbtn1": {
    height: "44px",
    width: "163px",
    border: "1px solid #355EE7",
    borderRadius: "8px",
    padding: "10px 12px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#fff",
    backgroundColor: "#355EE7",
    fontFamily: "DM Sans",
    textTransform: "none",
    "&:hover": {
      border: "2px solid #B9C5FF",
      boxShadow: "none !important",
    },
    "@media (max-width: 450px)": {
      height: "40px",
      width: "140px",
      padding: "7px 9px",
    },
  },

  "& .offcanvascss": {
    position: "fixed",
    top: "0px !important",
    bottom: "0px !important",
    left: "0% !important",
    width: "355px !important",
    height: "100vh !important",
    backgroundColor: "#fff",
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: "0px",
    left: "355px",
    width: "calc(100% - 355px)",
    height: "100vh",
    zIndex: 999,
    backgroundColor: "#000",
    opacity: 0.5,
  },
  "& .userBoxData": {
    boxShadow: "2px 3px 6px 3px #0000000F",
    padding: "20px",
    borderRadius: "16px",
    width: "245px",
  },
  "& .userbox1": {
    marginBottom: "10px",
  },
  "& .userProfile": {
    display: "flex",
    width: "252px",
    position: "relative",
  },
  "& .imageBox": {
    width: "56px !important",
    height: "56px !important",
    borderRadius: "50% !important",
    border: "1px solid #F0E5FF",
    marginRight: "10px",
  },
  "& .userName": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    lineHeight: "18px",
    marginBottom: "5px",
    marginLeft: "2px",
  },
  "& .userContent": {
    fontFamily: "DM Sans",
    fontSize: "11px",
    fontWeight: 400,
    color: "#355EE7",
    padding: "2px 6px",
    background: "#ECF1FF",
    borderRadius: "6px",
    position: "relative",
  },
  "& .starIconCss": {
    fontSize: "14px",
    color: "#F59E0B",
    position: "absolute",
    top: "3px",
  },
  "& .tripledotcss": {
    position: "absolute",
    fontSize: "30px",
    color: "#595959",
    top: "7px",
    right: "8px",
  },
  "& .userbox2": {
    marginBottom: "15px",
  },
  "& .bagCss": {
    width: "18px",
    height: "18px",
  },
  "& .servicesLegalCss": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#1F1F1F",
    marginLeft: "10px",
  },
  "& .FamilyTextCss": {
    border: "1px solid #8C8C8C",
    borderRadius: "6px",
    color: "#8C8C8C",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
  },
  "& .Jurisditincss": {
    border: "1px solid #8C8C8C",
    borderRadius: "6px",
    color: "#8C8C8C",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    display: "inline-block",
  },
  "& .userbox3": {
    marginBottom: "15px",
  },
  "& .text4css": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#8C8C8C",
    marginLeft: "7px",
    marginTop: "3px",
  },
  "& .yearExpCss": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#595959",
    marginLeft: "3px",
  },
  "& .activebtnCss": {
    textTransform: "capitalize",
    marginTop: "15px",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#fff",
    background: "#4872FF",
    borderRadius: "6px",
    padding: "5px 15px",
    border: "2px solid #4872FF",
    boxShadow: "none !important",
    cursor: "pointer",
    "&:hover": {
      border: "2px solid #B9C5FF",
      boxShadow: "none !important",
    },
  },
  "& .inactivebtnCss": {
    textTransform: "capitalize",
    marginTop: "15px",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "  #8C8C8C",
    background: "#D9D9D9",
    borderRadius: "6px",
    padding: "5px 15px",
    border: "2px solid #D9D9D9",
    boxShadow: "none !important",
    cursor: "pointer",
  },
  "& .EditBtnCss": {
    width: "155px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    color: "#595959",
    borderRadius: "8px",
    border: "none",
    background: "#fff",
    "&:hover": {
      background: "#ECF1FF",
    },
  },
  "& .optionCss": {
    width: "155px",
    padding: "8px",
    position: "absolute",
    top: "30px",
    left: "180px",
    borderRadius: "8px",
    boxShadow: "2px 3px 6px 3px #0000000F",
    zIndex: 9999,
    background: "#fff",
  },
  "& .nouserdatacss": {
    height: "65vh",
    fontFamily: "DM Sans",
    fontSize: "29px",
    fontWeight: 400,
    color: "grey",
    position:"absolute",
    left:"49%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const DrawerBox = styled("div")({
  minHeight: "100vh",
  width: "355px",
  "& .logobox": {
    display: "flex",
    justifyContent: "start",
    height: "76px",
    marginTop: "20px",
    marginLeft: "20px",
  },
  "& .logoimag": {
    height: "42px",
    width: "118px",
  },
  "& .settingtext": {
    color: "#262626",
    fontSize: "22px",
    marginLeft: "6px",
    fontWeight: 400,
    fontFamily: "DM Sans",
  },
  "& .settingbox": {
    display: "flex",
    height: "65px",
    cursor: "pointer",
  },

  "& .lefticon": {
    marginTop: "4px",
    marginLeft: "30px",
  },
  "& .listbox": {
    height: "110px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2": {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
        cursor: "pointer",
      },
      "& .icondrawer3": {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .listtile": {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: "110px",
    backgroundColor: "#ECF1FF",
    borderRadius: "8px",
  },
  "& .icondrawer1": {
    marginBottom: "45px",
    color: "#355EE7",
    marginLeft: "18px",
    cursor: "pointer",
  },
  "& .icondrawer3": {
    marginLeft: "18px",
    marginBottom: "25px",
    color: "#434343",
  },
  "& .icondrawer2": {
    marginLeft: "18px",
    marginBottom: "42px",
    color: "#434343",
    cursor: "pointer",
  },

  "& .listtext": {
    fontSize: "14px",
    color: "#334155",
    fontFamily: "DM Sans",
    fontWeight: 400,
  },
  "& .listtile1": {
    fontSize: "16px",
    color: "#355EE7",
    fontFamily: "DM Sans",
    fontWeight: 400,
    cursor: "pointer",
  },
  "& .listtile": {
    fontSize: "16px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    cursor: "pointer",
    color: "#434343",
  },
  "@media (max-width: 1100px)": {
    zIndex: 999,
    width: "355px",
  },
  "@media (max-width: 991px)": {
    zIndex: 999,
    width: "355px",
    display: "none",
  },
});

const ButtonBox = styled("div")({
  display: "none",
  "@media (max-width: 991px)": {
    width: "100%",
    display: "block",
    minHeight: "70px",
    "& .buttonbox": {
      height: "70px",
      width: "100vw",
    },

    "& .iconbtn": {
      marginTop: "10px",
      marginLeft: "10px",
    },
  },
});
const tooltipStyles = {
  tooltipContiane: {
    width: "100%",
  },

  tooltipSubContaine: {
    display: "flex",
    gap: "20px",
    width: "100%",
  },
  tooltipGridContaine: {
    display: "grid",
    gridTemplateColumns: " repeat(2, 1fr)",
    gap: "10px",
    width: "80%",
    marginTop: "40px",
  },
  chipStyles: {
    backgroundColor: "#ECF1FF ",
    color: "#355EE7 ",
    fontSize: "14px",
    textTransform: "capitalize" as "capitalize",
    wordWrap: "break-word" as "break-word",
  },
  tooltipimgcontaine: { justifyContent: "flex-end", display: "flex" },
  imgStyle: {
    width: "40px",
    height: "40px",
    marginBottom: "1px",
  },
  lenghtStyle: { padding: "3px 5px" },
};

// Customizable Area End
