import React from "react";
// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { logo, userpic } from "./assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import EditIcon from '@material-ui/icons/Edit';
import {
  Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Select, MenuItem, FormHelperText, LinearProgress,
  CircularProgress
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from '@material-ui/icons/Close';
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";

import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import StarIcon from '@material-ui/icons/Star';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorSuccessPopUp from "../../../components/src/ErrorSuccessPopUp.web";
import { AddCircleOutlineOutlined, DeleteOutlineOutlined, PublishOutlined } from "@material-ui/icons";



// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawer = () => (

    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo.default} alt="logoc" className="logoimag" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox" onClick={this.handleDashboard}>
        <ChevronLeftIcon className="lefticon" />
        <Typography className="settingtext">Back</Typography>
      </div>
      <List style={{ padding: '8px 13px' }}>
        <ListItem className="listbox" onClick={this.handleAdminLawyer}>
          <ListItemIcon><PermIdentityIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile">
            Lawyer Management
            <Typography className="listtext" style={{ whiteSpace: 'none !important' }}>
              Settings related to your personal information and account credentials
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox1">
          <ListItemIcon><PeopleAltOutlinedIcon className="icondrawer1" /></ListItemIcon>
          <ListItemText className="listtile1">
            Lawfirm Management
            <Typography className="listtext">
              View Digip’s Terms and Conditions and Privacy Policy
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleServicesManagement}>
          <ListItemIcon><BusinessCenterOutlinedIcon className="icondrawer3" /></ListItemIcon>
          <ListItemText className="listtile">
            Services Management
            <Typography className="listtext">
              Manage all services here
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </DrawerBox>
  )
  topbox = () => (
    <Box className="topbox">
      <Typography className="titleuser" data-test-id="Lawfirm_Management">
        Lawfirm Management
      </Typography>
      {!this.state.editToggel ?
        <Button type="submit" data-test-id="editBtn" variant="outlined" className="titlbtn" style={{ display: this.state.mobileOpen ? "none" : "" }}>

          <EditIcon style={{ marginRight: "8px" }} /> Edit
        </Button> :
        <Button  
        onClick={this.handleSubmitForm}
         data-test-id="saveBtn" variant="outlined" className="titlbtn1" style={{ display: this.state.mobileOpen ? "none" : "" }}>
          Save Changes
        </Button>}
    </Box>
  )

  profilebox = () => (
    <Box className="profilebox">
      <Typography className="profiletext">
        Lawfirm Logo
      </Typography>
      {this.state.editToggel ?
        <Box className="profileImg">
          <Box className="positionrel" style={{ display: this.state.mobileOpen ? "none" : "" }}>
            <label htmlFor="image" className="imagenot" style={{ backgroundImage: this.state.changeimage ? `url(${this.state.changeimage})` : `url(${userpic})` }}>
              <>
                <CameraAltOutlinedIcon className="usericon" />
                <Typography className="edittxt">Edit picture</Typography>
              </>
            </label>
            <input
              type="file"
              id="image"
              name="image"
              style={{ display: 'none' }}
              onChange={this.handleImageChange}
            />
          </Box>
        </Box>
        :
        <Box className="profileImg">
          <img src={this.state.changeimage || userpic} alt="" className="userimg" />
        </Box>
      }
    </Box>
  )
  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />

      </IconButton>
    </ButtonBox>
  )
  SendInviteLink = () => {
    return (
      <Formik
        data-test-id="formikinviate"
        validationSchema={this.validationSchemaUsers()}
        initialValues={{
          role: "",
          email: "",
        }}
        onSubmit={(values,{resetForm}) => {
          if(values.email!=this.state.userMailId){
            this.inviteLinkSend(values)
            resetForm()
          }
        }}
      >
        {({ values, errors, handleSubmit, handleChange, setFieldValue, touched ,setFieldTouched}) => {
          return (
            <form className="thirdForm"
              onSubmit={handleSubmit}>
              <Box className="detailsbox">
                <Box className="personaldetailtext">
                  <Typography>Associated Lawyers</Typography>
                </Box>
                <Box className="personalform">
                  <Box className="lawFirm">
                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                      Add Lawyers<span style={{ color: '#DC2626' }}>*</span>
                    </Typography>
                  </Box>
                  <Box className="lawboxinvite" style={{marginBottom: '10px'}}>
                    <Box style={{ display: 'flex', width: '100%' }}>
                      <Box style={{ width: '100%' }}>
                        <TextField
                          inputProps={{ "data-test-id": "email" }}
                          InputProps={{
                            style: {
                              borderTopLeftRadius: '8px',
                              borderBottomLeftRadius: '8px',
                            borderTopRightRadius:"0px",
                            borderBottomRightRadius:'0px',
                            color:'#000',
                            }
                          }}
                        style={{ display: this.state.mobileOpen ? "none" : "", width: '100%',color:'#000' }}
                          name="email"
                          data-test-id="inviteEmail"
                          type="text"
                          variant="outlined"
                          placeholder="johnbatro@gmail.com"
                          value={values.email}
                          onChange={(e) => {
                            handleChange(e);
                          if(e.target.value==this.state.userMailId){
                            this.setState({EmailErrorMsg:true})
                            }
                          else{
                            this.setState({EmailErrorMsg:false})
                            }
                          }}
                        helperText={ errors.email}
                        error={ Boolean(errors.email)||this.state.EmailErrorMsg}
                        />
                      <FormHelperText error={Boolean( !errors.email  && errors.role)}>
                        {( !errors.email &&!this.state.EmailErrorMsg) && errors.role}
                        </FormHelperText>
                      </Box>

                      <Select
                        variant="outlined"
                        className="roletxt"
                        inputProps={{ "data-test-id": "role" }}
                        name="role"
                        value={values.role}
                        style={{ color: "#000",background:'#fff' }}
                        onChange={(event) => {
                          setFieldValue("role", event.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        error={ Boolean(errors.role)||this.state.EmailErrorMsg}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="" style={{ background: '#fff' }}>
                          <em style={{ color: "#BFBFBF", fontFamily: "DM Sans" }}>Role</em>
                        </MenuItem>
                        <MenuItem style={{ background: "#FFFFFF",padding:'1px 8px',margin:'0px'}} value="Lawyer"><CustomSpan>Lawyer</CustomSpan></MenuItem>
                        <MenuItem style={{ background: "#FFFFFF",padding:'1px 8px',margin:'0px'}} value="lawfirm_admin"><CustomSpan>Admin</CustomSpan></MenuItem>
                      </Select>
                    </Box>
                    <button
                      className="invitebtn"
                      disabled={Boolean(values.email&& values.role&&!errors.email)?false:true}
                      style={{ backgroundColor: (values.email&& values.role&&!errors.email )?"#355EE7":"#BFBFBF" }}
                      type="submit"
                      data-test-id="inviteBtnx"
                    >
                      Invite
                    </button>
                  </Box>
                  {this.handleErrorDiv(this.state.EmailErrorMsg,<Typography style={{marginBottom:"10px", marginTop:"-10px",color:'#f44336',fontFamily:'Dm Sans',fontSize:'.75rem',fontWeight:400}}>This email already exists</Typography>,"")}
                  {this.state.showEmail &&
                    this.state.dataArray.slice().reverse().map((e, index) => (
                      <Box style={{ display: 'flex', justifyContent: 'space-between',marginBottom:'10px' }} key={index}>
                        <Box style={{ display: 'flex' }}>
                          <CheckCircleIcon style={{ color: '#34D399', marginRight: '5px', height: '12px', width: '12px', marginTop: '3px' }} />
                          <Typography style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 400, color: '#000000' }}>{e.email}</Typography>
                        </Box>
                        <Typography className="greenInvite" style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 400, color: '#34D399' }}> Invited
                        </Typography>
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            </form>
          )
        }}
      </Formik>
    )
  }
  showResult = (mimeType:string) =>{
    return(
      <NoScrollTxt className="noscroll">
      {this.state.uploadResponse.filter((x: any) => new RegExp(`^${mimeType}`).test(x.file_type)).map((x: any,index:any) => {
        return (
          <Box key={x.file_name} className="customtxt" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography >{x.file_name}</Typography>
            <Box data-test-id={`deleteResBtn-${index}`} onClick={() => !this.state.isDeleting && this.deleteBulkUploadAttachement(x.id)}>
              <CloseIcon className="cursorPointer" />
            </Box>
          </Box>
        )
      })}
    </NoScrollTxt>
    )
  }
  uploadPopUp = () => {
    return (
      <NoScroll
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
        }}
      >
        <Box
          style={{
            backgroundColor: "whitesmoke",
            width: "550px",
            height: "650px",
            borderRadius: "8px",
            padding: "20px",
            borderBottomRightRadius: "40px"
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
              alignItems:"center"
            }}
          >
            <Typography style={{ paddingLeft:"25px",color: "black", fontWeight: 600, fontSize: "20px" }}>
              Upload Files
            </Typography>
            <Box onClick={this.handleUplodeClosePopUp} style={{cursor:'pointer'}}>
              <CloseIcon />
            </Box>
          </Box>
          <hr
            style={{
              margin: "0px -20px",
              border: "none",
              backgroundColor: "#eae8e8",
              height: "2px",
            }}
          />
          <Box
            data-test-id="dragAndDropZone"
            style={{
              padding:"20px 0px",
              width: "90%",
              height: "25%",
              border: "3px dashed rgba(68, 65, 65, 0.25)",
              borderRadius: "10px",
              margin: "10px auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onDrop={this.handleDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <Box style={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <PublishOutlined style={{ color:"#8C8C8C", fontSize: "40px" }} />
            </Box>
            <Typography style={{color: "black", fontWeight: 600, fontSize:"16px"}}>Upload your files here</Typography>
            <Typography style={{color: "black", fontSize:"16px"}}>Drag and drop your files here</Typography>
            <Box
              style={{
                margin:"5px",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
                padding:'10px',
              }}
            >
              <Typography component={"label"} htmlFor="fileUpload" style={{
                display:'flex',alignItems:'center',justifyContent:'center'}}>
                <AddCircleOutlineOutlined style={{ color: "blue", fontSize:'1.3rem' ,cursor:'pointer'}} />
              </Typography>
              <Typography
                component={"label"}
                htmlFor="fileUpload"
                style={{ color: "blue", fontSize: "16px", fontWeight: 600, cursor: "pointer", 
                  display:'flex',alignItems:'center',justifyContent:'center'
                }}
              >
                Browse
              </Typography>
            </Box>
            <input
              data-test-id="fileUploadInput"
              accept={this.state.uploadMimetype}
              type="file"
              id="fileUpload"
              hidden
              multiple
              onChange={this.handleFileChange}
            />
          </Box>
          <Box style={{ width: "90%", height: "40%", margin: "0px auto", overflow: "hidden", overflowY: "scroll", scrollbarWidth: "none" }}>
            {this.state.LoadedFiles.length > 0 && (
              <Box style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",

              }}>
                {this.state.LoadedFilesPreview.map((file: any, index: any) => {
                  const progress = this.state.uploadProgress.find(
                    (p: any) => p.fileName === file.fileName
                  );
                  return (
                    <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                        <Box
                          style={{
                            backgroundColor: "gray",
                            width: "60px",
                            height: "60px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            borderRadius: "10px"
                          }}
                        >
                          {this.state.uploadMimetype==="image/*"?(
                          <img
                            src={file.file}
                            alt={`preview-${index}`}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                              margin: "5px",
                            }}
                          />):
                          (   <video
                            src={file.file}
                          
                             style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                              margin: "5px",
                            }}
                          />)
                }
                        </Box>
                        <Box style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px"
                        }}>
                          <Typography>{file.fileName}</Typography>
                          <Typography>{this.formatFileSize(file.fileSize)}</Typography>
                        </Box>
                      </Box>
                      <Box style={{ display: 'flex' }} >
                        <Box>
                          {progress.isUploading ? (
                            <Box style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                              <Box style={{ width: "100px" }}  >
                                <CustomLinearProgress variant="determinate" value={progress.progress} />
                              </Box>
                              <Box>
                                <CloseIcon style={{color:"rgba(68, 65, 65, 0.51)"}} data-test-id='test-cancelUpload' onClick={() => this.cancelUpload(file.fileName)} />
                              </Box>
                            </Box>
                          ) : (
                            <Box data-test-id='test-deleteUpload'  onClick={() => this.handleDelete(file.fileName)}>
                              <DeleteOutlineOutlined style={{color:"rgba(68, 65, 65, 0.51)"}} />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <hr
            style={{
              margin: "10px -20px",
              border: "none",
              backgroundColor: "#eae8e8",
              height: "2px",
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection:"row-reverse",
              padding:"20px 0px"
            }}
          >
            {
              this.state.upLoading ?
                <button
                  className="invitebtn"
                  style={{ cursor: 'pointer', backgroundColor: "355EE7",padding:'10px' }}
                  data-test-id="uploaBtnx1"
                >
                  <CircularProgress size={30} style={{color:'white'}} />
                </button>
                :
                <button
                  className="invitebtn cursorPointer"
                  style={{ cursor: 'pointer', backgroundColor: `${this.state.upLoading ? "gray" : "355EE7"}` }}
                  type="submit"
                  data-test-id="uploaBtnx"
                  onClick={this.uploadBulkAttachements}
                  disabled={this.state.upLoading}
                >
                  Upload
                </button>
            }
            
            <button
              onClick={this.handleUplodeClosePopUp}
              className="invitebtn cursorPointer"
              style={{backgroundColor:"whitesmoke", border:"1px solid #355EE7",outline:"#355EE7",color:"#355EE7" }}
              data-test-id="uploadCancelBtnx"
            >
              Cancel
            </button>
          </Box>
        </Box>
      </NoScroll>
    );
  };

  getRenderBillingForm = () => {
    return (
     <>
        <Formik
            innerRef={(formik) => (this.formikBillingRef = formik)}
            data-test-id="billingFormik"
            validationSchema={this.validationBillingSchema()}
            initialValues={{
              account_number: this.state.billingDetails?.account_number || "",
              sort_code: this.state.billingDetails?.sort_code || "",
              accoutn_holder_name: this.state.billingDetails?.accountn_holder_name || "",
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
                this.setState({ editToggel: !this.state.editToggel })
            }}
          >
            {({ values, errors, handleSubmit, handleChange, touched, handleBlur }) => {
              return (
                <form className="thirdForm"
                  onSubmit={handleSubmit}>
                  <Box className="userdetailbox">
                    <Box>
                
                      <Box className="accountdetailsbox">
                        <Box className="personaldetailtext">
                          <Typography>Billing Account details</Typography>
                        </Box>
                        <Box className="personalform" style={{ marginBottom: '10px' }}>
                          <Box className="">
                            <StyleLableTypography variant="subtitle1" className="firstNamePlaceHolder">
                            Account Number 
                            </StyleLableTypography>
                            <StyleTextField
                              inputProps={{ "data-test-id": "account_number" }}
                              InputProps={{
                                style: {
                                  borderRadius: '12px',
                                  width: "70%"
                                }
                              }}
                              style={{ display: this.state.mobileOpen ? "none" : "" }}
                              name="account_number"
                              onBlur={handleBlur}
                              type="text"
                              fullWidth
                              variant="outlined"
                              placeholder="xxxx xxxx xxxx xxxx"
                              value={values.account_number}
                              disabled={!this.state.editToggel}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              helperText={errors.account_number}
                              error={Boolean(errors.account_number)}
                            />
                          </Box>
                          <Box className="" style={{marginTop:"20px"}}>
                            <StyleLableTypography variant="subtitle1" className="firstNamePlaceHolder">
                             Sort code
                            </StyleLableTypography>
                            <StyleTextField
                              inputProps={{ "data-test-id": "sort_code" }}
                              InputProps={{
                                style: {
                                  borderRadius: '12px',
                                   width: "70%"
                                }
                              }}
                              style={{ display: this.state.mobileOpen ? "none" : "" }}
                              name="sort_code"
                              type="text"
                              fullWidth
                              variant="outlined"
                              placeholder="xx-xx-xx"
                              value={values.sort_code}
                              disabled={!this.state.editToggel}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              onBlur={handleBlur}
                              helperText={errors.sort_code}
                              error={Boolean(errors.sort_code)}
                            />
                          </Box>

                          <Box className="" style={{marginTop:"20px"}}>
                            <StyleLableTypography variant="subtitle1" className="firstNamePlaceHolder">
                            Account Holder Name
                            </StyleLableTypography>
                            <StyleTextField
                              inputProps={{ "data-test-id": "accoutn_holder_name" }}
                              InputProps={{
                                style: {
                                  borderRadius: '12px',
                                   width: "70%"
                                }
                              }}
                              style={{ display: this.state.mobileOpen ? "none" : "" }}
                              name="accoutn_holder_name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter the account holder full name"
                              value={values.accoutn_holder_name}
                              disabled={!this.state.editToggel}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              onBlur={handleBlur}
                              helperText={errors.accoutn_holder_name}
                              error={Boolean(errors.accoutn_holder_name)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )
            }}
          </Formik>
     </>
    )
  }

  updateManagement = () => {
    return (
      <Box className="rightbar" data-test-id="rightbar">
        {this.state.lawFirmData &&
          <Formik
          innerRef={(formik) => (this.formikRef = formik)}
            data-test-id="userupdateFormik"
            validationSchema={this.validationSchemaUser()}
            initialValues={{
              Website: this.state.catalougeData?.data?.attributes?.website || "https://asdfgh.com/",
              firmName: this.state.catalougeData?.data?.attributes?.firm || "",
            }}
            onSubmit={(values) => {
              if (!this.state.editToggel) {
                this.setState({ editToggel: !this.state.editToggel })
              }
            }}
          >
            {({ values, errors, handleSubmit, handleChange, touched }) => {
              return (
                <form className="thirdForm"
                  onSubmit={handleSubmit}
                  >
                  {this.topbox()}
                  {this.state.isProfileComplete && this.errorBox()}
                  <Box className="userdetailbox">
                    <Box>
                      {this.profilebox()}
                      <Box className="detailsbox">
                        <Box className="personaldetailtext">
                          <Typography>Lawfirm details</Typography>
                        </Box>
                        <Box className="personalform" style={{ marginBottom: '10px' }}>
                          <Box className="lawFirm">
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                              Lawfirm Name <span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            <TextField
                              inputProps={{ "data-test-id": "firmName" }}
                              InputProps={{
                                style: {
                                  borderRadius: '8px'
                                }
                              }}
                              style={{ display: this.state.mobileOpen ? "none" : "" }}
                              name="firmName"
                              type="text"
                              fullWidth
                              variant="outlined"
                              placeholder="A & B Lawfirm"
                              value={values.firmName}
                              disabled={!this.state.editToggel}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              helperText={touched.firmName && errors.firmName}
                              error={touched.firmName && Boolean(errors.firmName)}
                            />
                          </Box>
                          <Box className="Email">
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                              Website<span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            <TextField
                              inputProps={{ "data-test-id": "Website" }}
                              InputProps={{
                                style: {
                                  borderRadius: '8px'
                                }
                              }}
                              style={{ display: this.state.mobileOpen ? "none" : "" }}
                              name="Website"
                              type="text"
                              fullWidth
                              variant="outlined"
                              placeholder="loremibsum.com"
                              value={values.Website}
                              disabled={!this.state.editToggel}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              helperText={touched.Website && errors.Website}
                              error={touched.Website && Boolean(errors.Website)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )
            }}
          </Formik>
        }
        {this.SendInviteLink()}
        <Box className="detailsbox" style={{ paddingBottom: '18px' }}>
          <Box className="personaldetailtext">
            <Typography>Upload</Typography>
          </Box>
          <Box className="accountsactions1">
            <Box>
              <Box className="uploadbox cursorPointer" data-test-id="logoutdBtn" onClick={this.handleUploadphotoes}>
                <CameraAltOutlinedIcon style={{ color: '#355EE7', fontSize: '33px' }} />
                <Typography style={{ color: '#000000', fontWeight: 400, fontSize: '16px', fontFamily: 'DM Sans' }} data-test-id="uploadPhotosPopupBtn">Upload Photos</Typography>
                <span style={{ color: '#8C8C8C', fontWeight: 400, fontSize: '11px', fontFamily: 'DM Sans', margin: '5px 0px' }}>Max Size is 20MB</span>
              </Box>
              {this.state.uploadResponse.length > 0 &&
                this.showResult('image')
              }
            </Box>
            <Box>
              <Box className="uploadbox cursorPointer" data-test-id="logoutdBtn" onClick={this.handleUploadvidoes} >
                <VideocamOutlinedIcon style={{ color: '#355EE7', fontSize: '33px' }} />
                <Typography style={{ color: '#000000', fontWeight: 400, fontSize: '16px', fontFamily: 'DM Sans' }}>Upload Videos</Typography>
                <span style={{ color: '#8C8C8C', fontWeight: 400, fontSize: '11px', fontFamily: 'DM Sans', margin: '5px 0px' }}>Max Size is 20MB</span>
              </Box>
              {this.state.uploadResponse.length > 0 &&
                this.showResult('video')
              }
            </Box>
          </Box>
        </Box>
        {this.state.FieUploadPopUp && this.uploadPopUp()}
        <Box className="accountsbox">
          <Box className="accountdetailtext">
            <Typography>Ratings</Typography>
          </Box>
          <Box className="accountsactions">
            <Box className="logouttxt" data-test-id="logoutdBtn" >
              <Typography style={{ color: '#434343', fontWeight: 700 }}>Rate</Typography>
              <StarIcon style={{ color: '#8C8C8C', fontSize: '18px' }} />
            </Box>
            <Box className="deletetxt" >
              <Typography style={{ color: '#434343', fontWeight: 700 }}>Average Response Rate</Typography>
              <HourglassEmptyOutlinedIcon style={{ color: '#8C8C8C', fontSize: "18px" }} />
            </Box>
          </Box>
        </Box>
         <Box style={{paddingBottom: "70px"}}>
           {this.getRenderBillingForm()}
          </Box>
        <Dialog
          open={this.state.openDialog}
          data-test-id="logoutMainId"
          onClose={this.handleClosePopUp}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              borderRadius: '16px'
            }
          }}
        >
          <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'right' }}><CloseIcon style={{cursor:"pointer"}} onClick={this.handleClosePopUp} /></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography style={{ textAlign: 'center' }}><CheckCircleIcon style={{
                color: '#34D399',
                fontSize: '40px',
                fontWeight: 500,
                marginBottom: '20px'
              }} /></Typography>
              <Typography
                style={{
                  color: '#262626',
                  fontSize: '16px',
                  fontWeight: 400,
                  fontFamily: 'DM Sans',
                  marginBottom: '10px'
                }}
              >
                The Invitation to {this.state.dataArray[this.state.dataArray.length - 1]?.email} has been sent successfully
              </Typography>

            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: '20px', borderTop: '1px solid #E2E8F0' }}>
            <ButtonBox2
              style={{
                backgroundColor: "#355EE7",
                color: '#ffffff',
                borderRadius: "8px",
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: 'DM Sans',
                marginLeft: '15px',
                boxShadow: 'none !important',
                border: '2px solid #355EE7 ',
                textDecoration: 'none',
                width: '100%',
                padding: '10px 2px',
                cursor: 'pointer'
              }}
              onClick={this.handleClosePopUp}
            >
              Ok
            </ButtonBox2>
          </DialogActions>
        </Dialog>

      </Box>
    )
  }
  errorBox = () => {
    return (
      <Box mb={2}>
          <Box className="statusError"  borderRadius={4} textAlign="center">
            {<ErrorOutlineIcon style={{ marginRight: '10px' }} />}{"Please complete your profile with valid information to proceed."}
          </Box>
      </Box>
    )
  }

  validationSchemaUser = () => {
    return Yup.object().shape({
      Website: Yup.string()
        .required("URL is required")
        .matches(/https?:\/\/[^\s/$.?#].[^\s]*/, "URL is Invalid "),
      firmName: Yup.string().required('Company/Law firm is required'),
    });
  };
  validationSchemaUsers = () => {
    return Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Missing email address'),
      role: Yup.string().required('Please select role to invite!'),
    });
  };

  validationBillingSchema = () => {
    return Yup.object().shape({
      account_number: Yup.string().required("Account number is required"),
      sort_code: Yup.string().required('Sort code is required'),
      accoutn_holder_name: Yup.string().required('Account holder name is required'),
    });
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     <MainDiv    data-test-id="catalougeMain" style={{
            overflow:"hidden"
          }}>
    
         
      
          <Box style={{display:'flex', justifyContent:'start', alignItems:'start', width:'100%'}}>
                        <Box>
                            <NavigationMenu navigation={this.props.navigation} id={""} />
                        </Box>
                        <Box className="repsonsive">

        {this.updateManagement()}

        {this.state.popUpShoww && <ErrorSuccessPopUp
          showPop={this.state.popUpShoww}
          setShowPop={(newState: boolean) => this.setState({ popUpShoww: newState })}
          showResponse={this.state.ShowResponsee}
                  termfunction={  () => {}}
          deletePop={this.state.LawfirmNav}
          loginNav={this.handleLawFirmManagement}
        />}
      </Box>
                                </Box>
          </MainDiv>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  "cursorPointer":{
    cursor:'pointer !important'
  },
  "& .repsonsive":{padding:'30px',height:'100vh',overflow:"auto", width:'100%',
    "@media (max-width: 1280px)": {padding:'50px',overflowY:'scroll',
    overflowX:'hidden',height:'100vh',
    },
    "@media (max-width: 1366px)": {padding:'50px',
    overflowX:'hidden',
    overflowY:'scroll',height:'100vh',
    }
  },
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .MuiSelect-selectMenu:focus": {
    backgroundColor: "#fff"
  },
 
  "& .rightbar": {
    padding: "32px 0",
    marginTop: '100px',
    "@media (max-width: 1100px)": {
      padding: '20 40px',
      width: 'calc(100% - 355px)',
      marginLeft: '355px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px',
      marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .userdetailbox": {
    width: 'auto',
    margin: "0px auto",
  },
  "& .profilebox": {
    height: '156px',
    display: 'flex',
    borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 450px)": {
      height: '120px',
    },
  },
  "& .profileImg": {
    display: 'flex',
    justifyContent: "center",
    width: "80%",
  },
  "& .profiletext": {
    fontSize: '16px',
    fontWeight: 400,
    color: '#8C8C8C',
    fontFamily: 'DM Sans',
  },
  "& .userimg": {
    height: '120px',
    width: '120px',
    border: '4px solid #ECF1FF',
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      height: '80px',
      width: '80px',
    },
  },
  "& .positionrel": {
    position: "relative",
  },
  "& .imagenot": {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    border: '4px solid #ECF1FF',
    padding: '70px',
    position: "absolute",
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      padding: '50px'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#000',
      opacity: 0.7,
      borderRadius: '50%',
      zIndex: 0
    }
  },
  "& .edittxt": {
    color: '#fff',
    position: 'absolute',
    top: '80px',
    left: '31px',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
    zIndex: 1,
    "@media (max-width: 450px)": {
      position: 'absolute',
      top: '50px',
      left: '11px',
    },
  },
  "& .usericon": {
    position: 'absolute',
    top: '52px',
    left: '55px',
    fontSize: '22px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
    color: '#fff',
    zIndex: 1,
    "@media (max-width: 450px)": {
      position: 'absolute',
      top: '28px',
      left: '37px',
    },
  },
  '& .topbox': {
    display: 'flex',
    width: "auto",
    height: '70px',
    justifyContent: 'space-between',
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn": {
    height: '44px',
    width: '163px',
    border: '1px solid #355EE7',
    borderRadius: '8px',
    padding: '10px 12px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#355EE7',
    fontFamily: 'DM Sans',
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px',
      padding: '7px 9px',
    },
  },
  "& .titlbtn1": {
    height: '44px',
    width: '163px',
    border: '1px solid #355EE7',
    borderRadius: '8px',
    padding: '10px 12px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#fff',
    backgroundColor: "#355EE7",
    fontFamily: 'DM Sans',
    textTransform: "none",
    '&:hover': {
      border:'2px solid #B9C5FF',
      boxShadow:'none !important'
    },
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px',
      padding: '7px 9px',
    },
  },
  "& .invitebtnpopcss":{
    backgroundColor: "#355EE7 ",
    color: '#ffffff ',
    borderRadius: "8px ",
    fontSize: '16px ',
    fontWeight: 400,
    fontFamily: 'DM Sans ',
    width: '100%',
    textTransform:"none",
    boxShadow:'none',border:'none',
    padding:'16px',
    '&:hover': {
      border:'2px solid #B9C5FF',
      boxShadow:'none !important'
    },
  },
  "& .detailsbox": {
    display: 'flex',
    marginTop: '20px',
    borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 600px)": {
      display: 'block'
    },
  },
  "& .accountdetailsbox": {
    display: 'flex',
    marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block'
    },
  },
  "& .personaldetailtext": {
    width: '20%',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    color: '#8C8C8C',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '80%',
    "@media (max-width: 600px)": {
      width: '100%'
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    marginLeft: "0px"
  },
  "& .accountsbox": {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '30px',
    paddingBottom: '20px',
    borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 600px)": {
      display: 'block',
      marginTop: '10px',
    },
  },
  "& .accountdetailtext": {
    width: '20%',
    color: "#8C8C8C",
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .accountsactions": {
    width: '80%',
    display: 'flex', gap: "150px",
    "@media (max-width: 600px)": {
      width: '100%',
      display: 'block'
    },
  },
  "& .accountsactions1": {
    width: '80%',
    display: 'flex',
    gap: '80px',
    "@media (max-width: 850px)": {
      display: 'flex',
      justifyContent: 'space-between',
    },
    "@media (max-width: 600px)": {
      width: '100%',
      display: 'block'
    },
  },
  "& .logouttxt": {
    color: "#434343",
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      paddingBottom: '15px'
    },
  },
  "& .deletetxt": {
    color: "#434343",
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
    marginRight: '100px',
    "@media (max-width: 600px)": {
      width: '100%',
      marginRight: '10px',
    },
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '355px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '355px',
    width: 'calc(100% - 355px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  "& .uploadbox": {
    width: '220px',
    height: '150px',
    border: '1px dashed #355EE7',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    flexDirection: 'column',
    "@media (max-width: 1300px)": {
      width: '200px',
      padding: '10px',
    },
    "@media (max-width: 1100px)": {
      width: '170px',
      padding: '10px',
    },
  },
  "& .customtxt": {
    background: '#ECF1FF',
    color: '#595959',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    padding: '10px 8px',
    height: '28px',
    width: '230px',
    border: '1px solid #D9D9D9',
    borderRadius: '8px',
    margin: '10px 0px',
    "@media (max-width: 1300px)": {
      width: '200px',
      height: '28px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
    },
    "@media (max-width: 1100px)": {
      width: '170px',
      height: '28px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
    },
  },
  "& .roletxt": {
    border: "0px solid #D9D9D9",
    paddingLeft: '13px',
    borderLeft: 'none',
    width: "25%",
    height: '56px',
    display: 'flex', justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    color: '#000',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    borderBottomRightRadius: '8px !important',
    borderTopRightRadius: '8px !important',
    borderTopLeftRadius:"0px !important",
    borderBottomLeftRadius:"0px !important",
    "@media (max-width: 1200px)": {
      width: "30%",
    },
    "@media (max-width: 1000px)": {
      width: "35%",
    },
  },
  "& .invitebtn": {
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: 'DM Sans',
    borderRadius: '8px',
    marginLeft: "2%",
    height: '55px',
    width:"120px",
    textTransform:"none",
    fontWeight:"400",
    cursor:"pointer",
    border:"none",
    '&:hover': {
      border:'2px solid #B9C5FF',
      boxShadow:'none !important'
    },
    "&:disabled": {
      border: "none !important",
      boxShadow: "none",
      textTransform: "none",
      "&:hover":{
        border: 'none !important',
        boxShadow: 'none !important',
      }
    }
  },
  "& .lawboxinvite": {
    marginBottom: '15px',
    display: 'flex',
    width: '100%'

  },

  "& .statusError": {
    display: 'flex', alignItems: 'center',
    fontFamily: "DM Sans", fontWeight: "400", fontSize: "16px", textAlign: "left",
    color: "#DC2626", marginBottom: "20px"

  },
  "& .statusErrorLogin": {
    backgroundColor: "#FEE2E2",
    padding: "20px",
    marginBottom: "20px",
    borderLeft: "4px solid #F87171",
    color: "#F87171",
    borderRadius: '4px',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400

  },
  "& .statusErrorLoginAlert": {
    backgroundColor: "#FEE2E2",
    borderLeft: "4px solid #F87171",
    color: "#F87171",
    borderRadius: '4px',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    width: "404px",
    position: "absolute",
    left: "30px",
    bottom: "8px"


  },


  "& .greenInvite": {
    marginRight: '250px',
    "@media (max-width: 1100px)": {
      marginRight: '200px',
    },
    "@media (max-width: 1000px)": {
      marginRight: '150px',
    },
    "@media (max-width: 600px)": {
      marginRight: '100px',
    },
  }
})

const CustomSpan = styled('span')({
  width: '100%',
  backgroundColor: '#FFFFFF !important',
  color: '#595959 !important',
  fontFamily: "DM Sans !important",
  fontSize: "16px !important",
  fontWeight: 400,
  borderRadius: '4px !important',
  borderBottom:'1px solid wwhite',
  padding:'10px 10px',
  "&:hover": {
    padding:'10px 10px',
    backgroundColor: "#ECF1FF !important",
    color: '#595959 !important',
    borderBottom: '1px solid #E2E8F0 !important',
  },
})

const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "355px",
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px", marginTop: '20px',
    marginLeft: '20px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingtext": {
    color: '#262626', fontSize: '22px',
    marginLeft: '6px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
    cursor: 'pointer'
  },

  "& .lefticon":
  {
    marginTop: '4px',
    marginLeft: '30px',
  },
  "& .listbox": {
    height: '110px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .icondrawer3":
      {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .listtile": {
        color: "#355EE7",
      }
    },
  },
  "& .listbox1": {
    height: '110px',
    backgroundColor: '#ECF1FF',
    borderRadius: '8px',
  },
  "& .icondrawer1": {
    marginBottom: '45px',
    color: '#355EE7',
    marginLeft: '18px',
  },
  "& .icondrawer3": {
    marginLeft: '18px',
    marginBottom: '25px',
    color: '#434343',
  },
  "& .icondrawer2": {
    marginLeft: '18px',
    marginBottom: '42px',
    color: '#434343',
  },

  "& .listtext": {
    fontSize: '14px',
    color: '#334155',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  "& .listtile1": {
    fontSize: "16px",
    color: '#355EE7',
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer'
  },
  "& .listtile":
  {
    fontSize: "16px",
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer',
    color: '#434343',
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '355px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '355px',
    display: 'none',
  },
});

const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })
const ButtonBox2 = styled('button')({
  '&:hover': {
    border: '2px solid #B9C5FF !important',
    boxShadow: 'none !important'
  },
})
const NoScroll = styled('div')({
  width:"100vw",
  height:"100vh",
  fontFamily:'DM Sans',
  "& .noscroll" : {
    overflowY:"hidden",
    scrollbarWidth:"none",
  },
  "& .noscroll::-webkit-scrollbar": {
    display:"none",
  },
});
const NoScrollTxt = styled('div')({
  marginTop:"5px",
  height:"200px",
  overflowY:"scroll",
  scrollbarWidth:"none",
  fontFamily:'DM Sans',
  "&::-webkit-scrollbar": {
    display:"none",
  },
});

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '6px', 
  borderRadius: '5px', 
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px', 
    backgroundColor: '#355EE7',
  },
}));

const StyleTextField = styled(TextField)({
  marginTop:"3px",
  "& .MuiOutlinedInput-input":{
    padding: "12.5px 14px !important"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#C3D1FF !important",
    },
    "&.Mui-error fieldset": {
      borderColor: "#DC2626 !important",
    },
    
  },
})

const StyleLableTypography = styled(Typography)({
  fontSize: "14px",
  fontFamily: 'DM Sans',
  fontWeight: 700,
  color: '#434343',
})

// Customizable Area End
