import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { FolderData } from "./ChatController";
import { pdfI } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
  token?: string
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

interface ITaskData {
  id: string;
  type: string;
  attributes: {
    task_name: string;
    description: string;
    trademark_name: string;
    trademark_application_number: string;
    deadline: string;
    jurisdiction: string;
    state: string;
    status: string;
    account_id: number;
    parent_task_id: number;
    created_at: string;
    request: {
      data: {
        id: string;
        type: string;
        attributes: {
          status: string;
          rejection_reason: string | null;
          request_text: string | null;
          legel_service_type_id: number;
          account_id: number;
          price: string;
          project_template_id: number;
          assigned_lawyer_ids: number[];
          task_reject_reason_id: number | null;
          rejection_description: string | null;
          created_at: string;
          updated_at: string;
        };
      };
    };
    account: {
      data: {
        id: string;
        type: string;
        attributes: {
          activated: boolean;
          country_code: string;
          email: string;
          full_phone_number: string;
          first_name: string;
          last_name: string;
          phone_number: string;
          type: string;
          created_at: string;
          updated_at: string;
          device_id: string | null;
          unique_auth_id: string;
          firm: string;
          user_role: string;
        };
      };
    };
    legel_service_type: {
      id: number;
      name: string;
    };
    root_folder: {
      id: number;
      gallery_id: number | null;
      folder_name: string;
      folder_type: string | null;
      created_at: string;
      updated_at: string;
      is_archived: boolean;
      parent_id: number | null;
      account_id: number;
      task_id: number;
      archived_at: string | null;
      restore_id: string | null;
    };
    chat_rooms: {
      id: number;
      created_at: string;
      updated_at: string;
      name: string;
      chat_type: string;
      sender_id: number;
      sender_type: string;
      receiver_id: number;
      receiver_type: string;
      channel_sid: string;
      task_id: number;
      is_archived: boolean;
    }[];
    message_count: number;
  };
}


interface IChatRoom {
  chat_room_id: number;
  channel_sid: string;
  user_id: number;
  user_first_name: string;
  user_last_name: string;
  user_profile: {
    url: string;
  };
  user_jurisdiction: string;
  is_new_message: boolean;
  is_read_message: boolean;
  last_message: string;
  last_message_at: string;
  unread_count: number;
}

interface ITaskError {
  "errors":[{"token":string}]
}

export interface IMessageData {
  id: number;
  created_at: string; 

  sender: { 
    id: number;
    type: string;
    name: string;
    profile:{url:string | null}|null,
    message: string;
    created_at: string; 
    unreadCount: number;
    lastMessage: string;
    "attachments":{
      "id": number,
      "name":string,
      "byte_size": number,
      "record_type": string,
      "record_id": number,
      "created_at": string,
      "filename": string,
      "content_type": string,
      "url": string
  }[],
  };
  receiver: { 
    id: number;
    type: string;
    name: string;
    profile:{url:string | null}|null,
    message: string | null;
    created_at: string; 
    unreadCount: number;
    lastMessage: string;
    "attachments":{
      "id": number,
      "name":string,
      "byte_size": number,
      "record_type": string,
      "record_id": number,
      "created_at": string,
      "filename": string,
      "content_type": string,
      "url": string
  }[],
  };
  unreadCount: number;
  lastMessage: string;
  images?: { name: string; url: string }[];
  videos?: { name: string; url: string }[];
  pdfs?: { name: string; url: string }[];
  text: string;
  time: string;
}

interface IUserAttribute {
  first_name: string;
  last_name: string;
  website: string;
  firm: string | null;
  jurisdiction: string | null;
  full_phone_number: string;
  country_code: number;
  email: string;
  user_role: number;
  accomplishments: string | null;
  lawyer_status: string | null;
  legel_service_type: any[];
  years_of_experience: number;
  profile_image: string | null;
  attachment: {
    data: any[];
  };
  user_bank_details: any | null;
}

interface IUserProfile {
  message: string;
  user: {
    data: {
      id: string;
      type: string;
      attributes: IUserAttribute;
    };
  };
}


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IChatData | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  activeTab: string
  activeMessageTab: string
  taskListData: ITaskData[]
  chatRoomData: IChatRoom[]
  selectedTask: string | null
  selectedMessage: string
  taskDrawer:  boolean
  IsTaskLoading: boolean
  IsChatRoomLoading: boolean
  messageList: IMessageData[]
  isMessageLoding: boolean
  userdata:IUserAttribute | null;
  currentUserId: string;
  selectedChatRoom: number;
  newMessage: string,
  attachments:File[],
  sending:boolean,
  channelSID: string
  uploadedFile:FolderData[],
  uploadDialog: boolean,
  isSizeExceeded:boolean;
  isWrongFileMimeType: boolean
  selectedFiles:File[],
  filePreviews:string[],
  videoPreviews:string[],
  validFilesPreview:{
    image:string
  }[],
  pdfPreviews:{
    image:string
  }[],
  searchFiles:string,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;

  taskListAPICallId: string = ""
  chatRoomListAPICallId: string = ""
  messageListAPICallId: string = ""
  getUserProfileApiCallId: string = ""
  sendChatMessageApiCallId: string = ""
  getAllFoldersApiCallId: string = ""
  messagesEndRef = React.createRef<HTMLDivElement>();
  socket: WebSocket | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      activeTab: "Active",
      taskListData: [],
      chatRoomData: [],
      selectedTask: "",
      selectedMessage:"",
      taskDrawer: true,
      IsTaskLoading: false,
      messageList: [],
      isMessageLoding: false,
      userdata:null,
      selectedChatRoom: -1,
      newMessage: "",
      attachments: [],
      sending: false,
      channelSID: "",
      uploadedFile: [],
      uploadDialog: false,
      isSizeExceeded:false,
      isWrongFileMimeType: false,
      selectedFiles:[],
      filePreviews:[],
      videoPreviews:[],
      pdfPreviews:[],
      validFilesPreview: [],
      searchFiles: "",
      currentUserId: "",
      activeMessageTab: "active",
      IsChatRoomLoading: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserProfile()
    this.getTaskListByRole()
  }

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type,token } = data
    const header = {
      'Content-Type': contentType,
      'token': token ? token : ""
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
  }
 
  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) return;
  
    const fileArray = Array.from(files);
    const allowedTypes = [
      "application/pdf",
      "application/zip",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "image/jpeg",
      "image/png",
      "video/mp4",
    ];
  
    const validFiles = fileArray.filter((file) => allowedTypes.includes(file.type));
  
    if (validFiles.length === 0) {
      this.setState({
        isWrongFileMimeType: true,
        isSizeExceeded: false,
        uploadDialog: true
      });
      return;
    }
  
    const maxFileSize = 52428800;
    const isSizeExceeded = validFiles.some((file) => file.size > maxFileSize);
  
    if (isSizeExceeded) {
      this.setState({
        isSizeExceeded: true,
        isWrongFileMimeType: false,
        uploadDialog: true
      });
      return;
    }
  
    const fileNames = validFiles.map((file) => file.name).join(", ");
  
    this.setState((prevState) => ({
      isSizeExceeded: false,
      isWrongFileMimeType: false,
      newMessage: prevState.newMessage
        ? `${prevState.newMessage} (${fileNames})`
        : fileNames, 
    }));
  
    this.processFiles(files);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.getTaskListAPIResponse(apiRequestCallId,responseJson)
    this.getChatRoomListAPIResponse(apiRequestCallId,responseJson)
    this.getMessageListAPIResponse(apiRequestCallId,responseJson)
    this.handleGetUserProfile(apiRequestCallId,responseJson)
    this.getFolderAPIResponse(apiRequestCallId,responseJson)
  }

  handleTab = (tab: string) => {
    this.setState({ activeTab: tab,selectedTask: "",selectedMessage: "",messageList: [] },()=>{
      this.getTaskListByRole()
    });
  };

  handleMessageTab = (tab: string) => {
    this.setState({ activeMessageTab: tab,selectedMessage: "",messageList: [] },()=>{
      this.getChatRoomList(this.state.selectedTask as string)
    });
  };

  handleSelectedTask = (id:string) => {
    this.setState((prevState) => ({
      selectedTask: prevState.selectedTask === id ? null : id, selectedMessage: "",activeMessageTab:"active"
    }),()=>this.getChatRoomList(id));
  }

  handleSelectedMessage = (channelSid:string) => {
    this.setState({ selectedMessage: channelSid ,channelSID:channelSid},()=>this.getMessageList(channelSid))
  }

  handleTaskDrawer = () => {
    this.setState({ taskDrawer: !this.state.taskDrawer })
  }

  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string };user_role:string },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string };user_role:string }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string };user_role:string } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  getTokenUrlVal = async () => {
    const ctoken: string = await getStorageData("clientTokes");
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const mainNewToken =  maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token
    let newToken
    let newUrl
    if(ctoken){
      newToken = ctoken
      newUrl = configJSON.clientTaskListEndPoint + "?type=" + this.state.activeTab
    }else{
      switch(maintoken?.user_role){
        case "lawfirm_admin":
          newToken = mainNewToken
          newUrl = configJSON.lawfirmTaskListEndPoint
          break;
        case "lawyer":
          newToken = mainNewToken
          newUrl = configJSON.lawyerTaskListEndPoint
          break;
      }
    }
    return {newToken,newUrl}
  }
  

  getTaskListByRole = async() => {
    this.setState({IsTaskLoading: true})
    const headerVal = await this.getTokenUrlVal()
    this.taskListAPICallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      token: headerVal.newToken,
      method: configJSON.getApiMethod,
      endPoint: headerVal.newUrl,
    });
  }

  getTaskListAPIResponse = (apiRequestCallId:string, responseJson:{data:ITaskData[]} | {message:string}) => {
    if(apiRequestCallId === this.taskListAPICallId){
      if("message" in responseJson){
        toast.error(responseJson.message)
        this.setState({taskListData:[] as ITaskData[],IsTaskLoading:false})
      }else if(responseJson){
        this.setState({taskListData:responseJson.data,IsTaskLoading:false})
      }
    }
  }

  getChatRoomList = async(id:string) => {
    this.setState({IsChatRoomLoading: true})
    const headerVal = await this.getTokenUrlVal()
    const urlEndpoint =  this.state.activeMessageTab === "active" ? configJSON.chatRoomListEndPoint : configJSON.chatRoomListArchiveEndPoint
    this.chatRoomListAPICallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      token: headerVal.newToken,
      method: configJSON.getApiMethod,
      endPoint: urlEndpoint + '&task_id=' + id,
    });
  }

  getChatRoomListAPIResponse = (apiRequestCallId:string, responseJson:IChatRoom[] | {message:string}) => {
    if(apiRequestCallId === this.chatRoomListAPICallId){
      if ('message' in responseJson) {
        if (responseJson.message === "No chats found") {
          this.setState({chatRoomData:[],IsChatRoomLoading:false})
          return toast.error("No chats found");
        }
      }else{
        this.setState({chatRoomData:responseJson,IsChatRoomLoading:false})
      }
    }
  }

  getMessageList = async(channelSid:string) => {
    this.setState({isMessageLoding: true})
    const headerVal = await this.getTokenUrlVal()
    this.messageListAPICallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      token: headerVal.newToken,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getMessageListEndPoint + channelSid
    });
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  getMessageListAPIResponse = (apiRequestCallId:string, responseJson:{messages:IMessageData[]}) => {
    if(apiRequestCallId === this.messageListAPICallId){
      if (responseJson.messages) {
        this.setState({messageList:responseJson?.messages,isMessageLoding:false},()=>{
          this.initWebSocket()
           this.scrollToBottom()
        })
      }else{
      this.setState({messageList:[],isMessageLoding:false})
       return toast.error("Chat Not Found")
      }
    }
  }
  
  formatTime = (dateString: string | number | Date) => {
    const date = new Date(dateString);  
    return date.toLocaleTimeString([], {  
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true, 
    });
  };


  breakTextAfterWords = (text1: string, maxWords1 = 15) => {
    const words = text1.split(' ');
    let line: any[] = [];
    const result1: string[] = [];
  
    words.forEach((word: any, index: number) => {
      line.push(word);
      if (line.length === maxWords1 || index === words.length - 1) {
        result1.push(line.join(' ')); 
        line = [];  
      }
    });
  
    return result1.join(' \n'); 
  };

  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
  
    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const ctoken: string = await getStorageData("clientTokes");
    
    const header = {
      "Content-Type": "application/json",
      "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetalsEndPoint}${ maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetUserProfile=(apiRequestCallId: string,responseJson: IUserProfile)=>{
    if (apiRequestCallId === this.getUserProfileApiCallId) {
      if (responseJson) {
        this.setState({ userdata: responseJson?.user?.data?.attributes,currentUserId:responseJson.user.data.id })
      }}
  }


  initWebSocket = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
  
    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const ctoken: string = await getStorageData("clientTokes");

    const token = ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token

    this.socket = new WebSocket(`${configJSON.webSocketUrl}?token=${token}`);
    this.socket.onopen = () => {
      this.subscribeChannel();
    };

    this.socket.onmessage = (event) => {
     if (
        JSON.parse(event.data).type === undefined
      ) {
        const parsedData = JSON.parse(event.data);
        const socketMessage = parsedData.message;    
        if (!socketMessage) return;

        const {sender_id} = socketMessage
        if (String(sender_id) === String(this.state.currentUserId)) {
          return;
        }
     
        const newMessage: IMessageData = {
          id: socketMessage.task.id,
          created_at: socketMessage.timestamp,
          unreadCount: socketMessage.unread_count,
          lastMessage: socketMessage.body,
          text: socketMessage.body,
          time: new Date(socketMessage.timestamp).toLocaleTimeString(),
    
          sender: {
            id: socketMessage.sender_id,
            type: socketMessage.sender_type,
            name: socketMessage.sender_type,
            profile: { url: null },
            message: "",
            created_at: socketMessage.timestamp,
            unreadCount: socketMessage.unread_count,
            lastMessage: "",
            attachments: [],
          },
    
          receiver: {
            id: socketMessage.receiver_id,
            type: socketMessage.receiver_type,
            name: socketMessage.receiver_type,
            profile: { url: null },
            message: socketMessage.body,
            created_at: socketMessage.timestamp,
            unreadCount: socketMessage.unread_count,
            lastMessage: socketMessage.body,
            attachments: [],
          },
    
          images: [],
          videos: [],
          pdfs: [],
        };
    
        this.setState((prevState) => ({
          messageList: [...prevState.messageList, newMessage],
        }));        
      }
    };

    this.socket.onerror = (_error) => {};

    this.socket.onclose = () => {};
  };


  subscribeChannel = () => {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const subscribeCommand = JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "ChatChannel",
          id: this.state.selectedChatRoom,
        }),
        data: JSON.stringify({ id: this.state.selectedChatRoom }),
      });
      this.socket.send(subscribeCommand);
    }
  };

  getMessageText = (newMessage: string, nonMediaFiles: string[]) => {
    if (newMessage.trim()) {
      return nonMediaFiles.length > 0 
        ? `${newMessage} (${nonMediaFiles.join(", ")})` 
        : newMessage.trim();
    } else if (nonMediaFiles.length > 0) {
      return `(${nonMediaFiles.join(", ")})`;
    }
    return "";
  };

  handleSendMessage = () => {
      const {newMessage, messageList,attachments,userdata } = this.state;
      if (newMessage.trim() !== "" || attachments.length > 0) {
    
        const messageText = this.getMessageText(newMessage, [""]);
    
        const newSentMessage: IMessageData = {
          id: messageList.length + 1,
          created_at: new Date().toISOString(),
          sender: {
            id: 1,
            type: "user",
            profile: null,
            name: `${userdata?.first_name || ""} ${userdata?.last_name || ""}`.trim(),
            unreadCount: 0,
            message: newMessage.trim(),
            created_at: new Date().toISOString(),
            lastMessage: messageText.trim(),
            attachments: []
          },
          receiver: {
            id: 2,
            type: "lawyer",
            message: "",
            name: "Receiver Name",
            profile: null,
            created_at: new Date().toISOString(),
            lastMessage: messageText.trim(),
            unreadCount: 0,
            attachments: []
          },
          images: [],
          videos: [],
          pdfs: [],
          time: new Date().toLocaleTimeString(),
          text: messageText.trim(),
          unreadCount: 0,
          lastMessage: "",
        };
    
        this.setState(
          { sending: true },
          () => {
            this.setState(
              (prevState) => ({
                messageList: [...prevState.messageList, newSentMessage],
                newMessage: "",
                videoPreviews: [],
                filePreviews: [],
                validFilesPreview:[],
                pdfPreviews: [],
              }),
              () => {
                this.setState({ newMessage: "" });
                this.sendChatMessage(newMessage);
                this.scrollToBottom();
              }
            );
          }
        );
      }
  };
  
  sendChatMessage = async (newMessage: string) => {
    const { channelSID } = this.state;
  
    const encodedMessage = encodeURIComponent(newMessage);
  
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
  
    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const ctoken: string = await getStorageData("clientTokes");
  
    const header = {
      "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };
  
    const formData = new FormData();
    formData.append("message", newMessage);
    formData.append("channel_sid", channelSID);
  

    const url = `bx_block_chat/chat_rooms/send_message_to_channel?channel_sid=${channelSID}&message=${encodedMessage}`;
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sendChatMessageApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleMessageChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({newMessage: event.target.value})
  }

  getAllFolders= async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupTokens);
    const metaLoginTokens = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpTokens, metaLoginTokens);
    const ctoken: string = await getStorageData("clientTokes");
    const header = {
      "Content-Type": "application/json",
      "token": ctoken || maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllFoldersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chat_rooms/all_medias_of_task?task_id=${this.state.selectedTask}&search=${this.state.searchFiles}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFolderAPIResponse = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.getAllFoldersApiCallId){
      if(responseJson){
        this.setState({uploadedFile:responseJson});
      }
    }
  }

  handleOpenUploadDialog=()=>{
    this.setState({uploadDialog:true})
    this.getAllFolders()
  }

  handleClosUploadDialog=()=>{
    this.setState({uploadDialog:false})
  }

   handleDragOver = (event: React.DragEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
  
    handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
    
      const files = event.dataTransfer.files;
      const doesFileMimeTypeExist = this.isValidFileMimeType(files);
    
      if (!doesFileMimeTypeExist) {
        this.setState({ 
          isWrongFileMimeType: true, 
          isSizeExceeded: false,
          uploadDialog: false
        });
        return;
      }
    
      if (files && files.length > 0) {
        const maxFileSize = 52428800; 
        const isSizeExceeded = Array.from(files).some((file) => file.size > maxFileSize);
    
        if (isSizeExceeded) {
          this.setState({ 
            isSizeExceeded: true, 
            isWrongFileMimeType: false,
            uploadDialog: false
          });
          return;
        }
    
        this.setState({ isSizeExceeded: false, isWrongFileMimeType: false });
        this.processFiles(files);
      }
    };
    
    isValidFileMimeType = (files: FileList) => {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
        "image/jpeg",
        "image/png",
        "video/mp4",
      ];
      return Array.from(files).some((file) => allowedTypes.includes(file.type));
    };

     processFiles = (files: FileList) => {
        const fileArray = Array.from(files);
    
        const allowedTypes = [
          "application/pdf",
          "application/zip",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "image/jpeg",
          "image/png",
          "video/mp4",
        ];
        const previewableTypes = [
          "application/zip",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];
        
        const validFiles = fileArray.filter((file) => allowedTypes.includes(file.type));
    
        const imagePreviews = validFiles
          .filter((file) => file.type.startsWith("image/"))
          .map((file) => URL.createObjectURL(file)); 
    
        const videoPreviews = validFiles
          .filter((file) => file.type.startsWith("video/"))
          .map((file) => URL.createObjectURL(file));
    
        const pdfPreviews = validFiles
          .filter((file) => file.type === "application/pdf")
          .map((file) => ({
            image: pdfI, 
          }));
    
          const validFilesPreview = validFiles
          .filter((file) => previewableTypes.includes(file.type))
          .map((file) => ({
            image: URL.createObjectURL(file), 
          }));
    
        const documentFiles = validFiles
          .filter(
            (file) =>
              !file.type.startsWith("image/") &&
              !file.type.startsWith("video/") &&
              file.type !== "application/pdf"
          )
          .map((file) => file.name);
    
        this.setState((prevState) => ({
          newMessage: documentFiles.length > 0
            ? `${prevState.newMessage} (${documentFiles.join(", ")})`
            : prevState.newMessage,
          selectedFiles: [...prevState.selectedFiles, ...validFiles],
          filePreviews: [...prevState.filePreviews, ...imagePreviews],
          videoPreviews: [...prevState.videoPreviews, ...videoPreviews],
          pdfPreviews: [...prevState.pdfPreviews, ...pdfPreviews],
          validFilesPreview: [...prevState.validFilesPreview, ...validFilesPreview],
          uploadDialog:false
        }));
      };
    
  handleCloseErrorBox = () => {
    this.setState({
      isSizeExceeded: false,
      isWrongFileMimeType: false,
    });
  };

  handleSearchFiles = (event: { target: { value: string; }; }) => {
    const query = event.target.value;
    this.setState({  searchFiles: query }, () => {
      this.getAllFolders();
    });
  };

  // Customizable Area End
}
