import React from "react";

// Customizable Area Start
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  styled,
  TextField,
  Typography
} from "@material-ui/core";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Activeicon from "../../documentation/src/components/Activeicon";
import Closeicon from "../../documentation/src/components/Closeicon";
import { arrowpng, attachmentIcon, countryIcon, dateIcon, filterLiveIcon, leftarrowIcon, messageIcon, nochatIcon, nomessageIcon, pdfI, searchIcon, sendmessageIcon, taskIcon, threeDots, userIcon } from "./assets";
import { ChevronRight } from "@material-ui/icons";
import { ToastContainer } from "react-toastify";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';


// Customizable Area End

import ViewChatController, { IMessageData, Props } from "./ViewChatController";
import ActiveMsgIcon from "./components/ActiveMsgIcon";
import ArchiveMsgIcon from "./components/ArchiveMsgIcon";
import { CustomizeDialog2 } from "./Chat.web";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTabs = () => {
    return (
      <StyleTabBox>
        <button data-test-id="active-tab-button" onClick={() => this.handleTab("Active")}
          style={{
            width: '90px',
            fontWeight: 600,
            textAlign: 'center',
            background: 'none',
            border: 'none',
            color: this.state.activeTab === "Active" ? "#355EE7" : "gray",
            borderBottom: this.state.activeTab === "Active" ? "1px solid #355EE7" : "none",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            paddingBottom: '5px',
            cursor: 'pointer',
          }}
        >
          <Activeicon data-test-id="active" color={this.state.activeTab === "Active" ? "#355EE7" : "gray"} />
          Active
        </button>
        <button
          data-test-id="close-tab-button"
          onClick={() => this.handleTab("Closed")}
          style={{
            fontWeight: 600,
            width: '80px',
            textAlign: 'center',
            background: 'none',
            cursor: 'pointer',
            border: 'none',
            borderBottom: this.state.activeTab === "Closed" ? "1px solid #355EE7" : "none",
            color: this.state.activeTab === "Closed" ? "#355EE7" : "gray",
            display: "flex",
            justifyContent: 'center',
            paddingBottom: '5px',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Closeicon data-test-id="close" color={this.state.activeTab === "Closed" ? "#355EE7" : "gray"} />
          Close
        </button>
      </StyleTabBox>
    )
  }

  renderMessageTabs = () => {
    return (
      <Box style={{ display: "flex", justifyContent: 'start', alignItems: 'end', paddingLeft: '10px', gap: '20px', borderBottom: "1px solid #ddd", marginTop: '10px' }}>
        <button disabled={!this.state.selectedTask} data-test-id="active-message-tab-button" onClick={() => this.handleMessageTab("active")}
          style={{
            fontWeight: 600,
            width: '90px',
            textAlign: 'center',
            background: 'none',
            border: 'none',
            borderBottom: this.state.activeMessageTab === "active" ? "1px solid #355EE7" : "none",
            color: this.state.activeMessageTab === "active" ? "#355EE7" : "gray",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            paddingBottom: '5px',
            cursor: 'pointer',
          }}
        >
          <ActiveMsgIcon color={this.state.activeMessageTab === "active" ? "#355EE7" : "gray"} />
          Active
        </button>
        <button
          disabled={!this.state.selectedTask}
          data-test-id="archive-message-tab-button"
          onClick={() => this.handleMessageTab("close")}
          style={{
            fontWeight: 600,
            textAlign: 'center',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            borderBottom: this.state.activeMessageTab === "close" ? "1px solid #355EE7" : "none",
            color: this.state.activeMessageTab === "close" ? "#355EE7" : "gray",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            paddingBottom: '5px'
          }}
        >
          <ArchiveMsgIcon color={this.state.activeMessageTab === "close" ? "#355EE7" : "gray"} />
          Archived
        </button>
      </Box>
    )
  }

  getUserFullName = () => {
    const { userdata } = this.state;
    return `${userdata?.first_name || ""} ${userdata?.last_name || ""}`.trim();
  };

  getMessageAlignment = (senderName: string): "flex-end" | "flex-start" => {
    return senderName === this.getUserFullName() ? "flex-end" : "flex-start";
  };

  renderUserAvatar = (sender: { name: string; profile: any; }) => {
    if (sender?.name !== this.getUserFullName()) {
      return (
        <ListItemAvatar style={{ alignSelf: "flex-start" }}>
          <Avatar style={{ width: "44px", height: "44px" }}>
            {sender?.profile?.url || sender?.name.charAt(0)}
          </Avatar>
        </ListItemAvatar>
      );
    }
    return null;
  }

  renderSenderDetails = (msg: IMessageData) => {
    const timeFormatted = this.formatTime(msg.sender?.created_at);
    const isCurrentUser = msg.sender?.name !== this.getUserFullName();

    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "12px",
          whiteSpace: "nowrap",
          fontFamily: "DM Sans",
          color: "#8C8C8C",
          display: "flex",
          textTransform: "capitalize",
          gap: "4px",
        }}
      >
        {isCurrentUser ? (
          <>
            <span data-test-id="sender_name_right" style={{ marginRight: "8px" }}>{timeFormatted}</span>
            {msg.sender?.name}
          </>
        ) : (
          <>
            {msg.sender?.name}
            <span data-test-id="sender_name_left" style={{ marginLeft: "8px" }}>{timeFormatted}</span>
          </>
        )}
      </Typography>
    );
  };

  renderMessageItem = (msg: IMessageData, index: number) => {
    return (
      <ListItem
        key={msg.id || index}
        style={{
          gap: msg.sender?.name === this.getUserFullName() ? "16px" : "3px",
          display: "flex",
          justifyContent: this.getMessageAlignment(msg.sender?.name),
          alignItems: "flex-start",
        }}
      >
        {this.renderUserAvatar(msg.sender)}

        <div style={{ display: "flex", gap: "4px", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: this.getMessageAlignment(msg.sender?.name),
              alignItems: "center",
              gap: "8px",
            }}
          >
            {this.renderSenderDetails(msg)}
          </div>

          <div
            style={{
              justifyContent: this.getMessageAlignment(msg.sender?.name),
              display: "flex",
            }}
          >
            <Typography
              style={{
                padding: "6px 12px",
                borderRadius: "15px",
                backgroundColor:
                  msg.sender?.name === this.getUserFullName()
                    ? "#F5F5F5"
                    : "#ECF1FF",
                display: "inline-flex",
                alignItems: "start",
                textAlign: "left",
                gap: "10px",
                wordBreak: "break-word",
                marginTop: 1,
                whiteSpace: "pre-wrap",
                maxWidth: "91%",
                width: "auto",
                flexDirection: "column",
              }}
              className="messagestext"
            >
              {this.breakTextAfterWords(
                `${msg.sender?.message || ""} ${msg.receiver?.message || ""}`
              )}

            </Typography>
          </div>
        </div>

        {this.renderCurrentUserAvatar(msg.sender)}
      </ListItem>
    );
  };

  renderCurrentUserAvatar = (sender: { name: string; profile: any; }) => {
    if (sender?.name === this.getUserFullName()) {
      return (
        <ListItemAvatar style={{ alignSelf: "flex-start" }}>
          <Avatar>{sender?.profile || sender?.name.charAt(0)}</Avatar>
        </ListItemAvatar>
      );
    }
    return null;
  }

  accordianTaskMessageList = () => {
    return (
      <>
        {!this.state.taskDrawer && this.state.taskListData.map((item) => (
          <StyledAccordion
            key={item.id}
            expanded={this.state.selectedTask === item.id}
            onChange={() => this.handleSelectedTask(item.id)}
            data-test-id="select_accordian_task"
          >
            <AccordionSummary>
              <div className="tasklistcontainer" style={this.state.selectedTask === item.id ? { background: "#ECF1FF", width: "100%", marginBottom: "-30px" } : { width: "100%" }}>
                <div className="listrow">
                  <Typography className="msg_name" style={{ width: "185px" }}>{item.attributes.task_name}</Typography>
                  <div>
                    <Typography className="msg_count" style={{ width: "14px", padding: "3px" }}>{item.attributes.message_count}</Typography>
                  </div>
                  <ChevronRight fontSize="small" style={{ color: "#355EE7" }} />
                </div>
                <Divider style={{ marginTop: "20px" }} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              
              {this.state.selectedTask === item.id && <div style={{ marginTop: "0", width: "100%" }} className="tasksection">
                {this.state.selectedTask && this.state.chatRoomData.length > 0 ? this.state.chatRoomData.map((item) => (
                  <div data-test-id="message-selected" onClick={() => this.handleSelectedMessage(String(item.channel_sid))} className="tasklistcontainer" style={this.state.selectedMessage === String(item.chat_room_id) ? { background: "#ECF1FF" } : {}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                      >
                        <Avatar alt="Remy Sharp" src={item.user_profile?.url} style={{ borderColor: "#355EE7" }} />
                      </StyledBadge>
                      <div className="messagelistrow">
                        <div>
                          <Typography className="msg_name">{item.user_first_name} {item.user_last_name}</Typography>
                          <Typography className="msg_desc">{item.last_message}</Typography>
                        </div>
                        <div>
                          <Typography className="msg_hrs">1h</Typography>
                          <Typography className="msg_count" style={{ fontSize: "12px" }}>{item.unread_count}</Typography>
                        </div>
                      </div>
                    </div>
                    <Divider style={{ marginTop: "20px" }} />
                  </div>
                )) :
                  <NoChatDiv>
                    <div>
                      <div style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <img src={nochatIcon} />
                      </div>
                      <Typography className="nochattext" >
                        There is not inactive <br /> chats for this Task</Typography>
                    </div>
                  </NoChatDiv>
                }

              </div>}
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </>
    )
  }

  renderTaskColumn = () => {
    return (
      <>
        <MainTaskColumnDiv>
          <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
            <img data-test-id="drawer-toggle" src={this.state.taskDrawer ? arrowpng : leftarrowIcon} onClick={this.handleTaskDrawer} />
          </div>
          <div className="titleHeader" style={{ paddingTop: 0 }}>
            <img src={taskIcon} />
            <span className="maintitle">Tasks</span>
          </div>
          {this.renderTabs()}
          <div className="filterSection">
            <StyleTextField
              inputProps={{ "data-test-id": "account_number" }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
              name="account_number"
              type="text"
              fullWidth
              variant="outlined"
              placeholder="Task Name"
            />
            <img src={filterLiveIcon} />
          </div>
          <div className="tasksection">
            {this.state.IsTaskLoading &&
              <div style={{ textAlign: "center", padding: "30px" }}>
                <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }} />
              </div>}
            {!this.state.IsTaskLoading && this.state.taskListData.length === 0 &&
              <NoChatDiv>
                <div>
                  <div style={{
                    justifyContent: "center",
                    display: "flex",
                  }}>
                    <img src={nochatIcon} />
                  </div>
                  <Typography className="nochattext" >
                    There is not inactive Task</Typography>
                </div>
              </NoChatDiv>}
            {!this.state.IsTaskLoading && this.state.taskListData.map((item) => (
              <div data-test-id="task-selected" onClick={() => this.handleSelectedTask(item.id)} className="tasklistcontainer" style={this.state.selectedTask === item.id ? { background: "#ECF1FF" } : {}}>
                <div className="listrow">
                  <Typography className="msg_name" style={item.attributes.message_count > 0 ? { width: "185px", color: "#355EE7" } : { width: "185px" }}>{item.attributes.task_name}</Typography>
                  <div>
                    <Typography className="msg_hrs">1h</Typography>
                    <Typography className="msg_count" style={{ width: "14px", padding: "3px" }}>{item.attributes.message_count}</Typography>
                  </div>
                  <ChevronRight fontSize="small" style={{ color: "#355EE7" }} />
                </div>
                <Divider style={{ marginTop: "20px" }} />
              </div>
            ))
            }

            {this.accordianTaskMessageList()}

          </div>
        </MainTaskColumnDiv>
      </>
    )
  }

  noChatDivRender = () => {
    if(this.state.IsChatRoomLoading) return
    return (
      <NoChatDiv>
        <div>
          <div style={{
            justifyContent: "center",
            display: "flex",
          }}>
            <img src={nochatIcon} />
          </div>
          <Typography className="nochattext" >
            There is not inactive <br /> chats for this Task</Typography>
        </div>
      </NoChatDiv>
    )
  }

  renderMessagedColumn = () => {

    return (
      <>
        <MainTaskColumnDiv>
          <div className="titleHeader">
            <img src={messageIcon} />
            <span className="maintitle">Message</span>
          </div>
          {this.renderMessageTabs()}
          <div className="filterSection">
            <StyleTextField
              inputProps={{ "data-test-id": "account_number" }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
              name="account_number"
              type="text"
              fullWidth
              variant="outlined"
              placeholder="Search by Lawyer name"
            />
          </div>
          <div className="tasksection">
            {this.state.IsChatRoomLoading &&
              <div style={{ textAlign: "center", padding: "30px" }}>
                <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }} />
              </div>}
            {!this.state.IsChatRoomLoading && this.state.selectedTask && this.state.chatRoomData.length > 0 ? this.state.chatRoomData.map((item) => (
              <div onClick={() => this.handleSelectedMessage(String(item.channel_sid))} data-test-id="message-selected" className="tasklistcontainer" style={this.state.selectedMessage === String(item.chat_room_id) ? { background: "#ECF1FF" } : {}}>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <StyledBadge
                    overlap="circular"
                    variant="dot"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  >
                    <Avatar src={item.user_profile?.url} alt="Remy Sharp" style={{ borderColor: "#355EE7" }} />
                  </StyledBadge>
                  <div className="messagelistrow">
                    <div>
                      <Typography data-test-id="msg_name" className="msg_name">{item.user_first_name} {item.user_last_name}</Typography>
                      <Typography data-test-id="msg_desc" className="msg_desc">{item.last_message}</Typography>
                    </div>
                    <div data-test-id="msg_count">
                      <Typography className="msg_hrs">1h</Typography>
                      <Typography className="msg_count" style={{ fontSize: "12px" }}>{item.unread_count}</Typography>
                    </div>
                  </div>
                </div>
                <Divider data-test-id="divider" style={{ marginTop: "20px" }} />
              </div>
            )) :
              this.noChatDivRender()
            }

          </div>
        </MainTaskColumnDiv>
      </>
    )
  }

  renderChatScreen = () => {

    return (
      <MainChatDiv>
        <div className="headersection">
          <div className="titlesection">
            <Typography className="mainchattitle">Conduct Trademark Search</Typography>
            <StyleBadge data-state="digip"  >In progress</StyleBadge>
          </div>
          <FlexDiv>
            <div>
              <StyleBadgeMeet >
                <img src={dateIcon} />Schedule meeting
              </StyleBadgeMeet>
            </div>
            <FlexDiv>
              <Avatar
                src={userIcon}
                style={{ width: 39, height: 38, marginRight: '8px' }}
              />
              <FlexDiv style={{ flexDirection: "column", alignItems: "start" }}>
                <Typography className="username">Anita John</Typography>
                <Typography className="countryname"><img src={countryIcon} />USA</Typography>
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
          <div>
            <img src={threeDots} />
          </div>
        </div>

        {!this.state.isMessageLoding && !this.state.selectedMessage && <NoMessageDiv>
          <div>
            <div style={{
              display: "flex",
              justifyContent: "center"
            }}>
              <img src={nomessageIcon} />
            </div>
            <Typography className="nomessagetext" >
              Task Chat
            </Typography>
            <Typography className="nomessagedesc" >
              In this section you will be to see the communication between the lawyers and the clients.
            </Typography>
          </div>
        </NoMessageDiv>}
        {!this.state.isMessageLoding && this.state.selectedMessage ? <>
          <StyleMessgeDiv>
            {this.state.messageList.map((msg, index) => this.renderMessageItem(msg, index))}
            <div ref={this.messagesEndRef} />
          </StyleMessgeDiv>

          <Box
            style={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '8px',
              backgroundColor: '#fff',
            }}
          >
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleMessageChange(event)}
              value={this.state.newMessage}
              data-test-id='new_message1'
              inputProps={{ 'data-test-id': 'new_message' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment onClick={this.handleOpenUploadDialog} position="start" style={{ marginRight: "-40px" }}>
                    <IconButton style={{ marginTop: "40px" }}>
                      <img src={attachmentIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton data-test-id="send_message" onClick={this.handleSendMessage} style={{ marginTop: "40px", backgroundColor: '#355EE7', borderRadius: '8px', color: '#fff', width: "40px", height: "36px" }}>
                      <img src={sendmessageIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '12px',
                  background: "#FAFAFA"
                },
              }}
              name="new_message"
              type="text"
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              placeholder="Send a Message to Maya Diab"
            />
          </Box>
        </> :
          <div style={{ textAlign: "center", padding: "30px" }}>
            <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }} />
          </div>}
      </MainChatDiv>
    )
  }

  getErrorState = () => {
    return this.state.isSizeExceeded || this.state.isWrongFileMimeType;
  };

  renderFileUploadSection = () => {
    return (
      <>
        <Box style={{ alignItems: "center", width: "26%", display: "flex", gap: "8px" }}>
          <span style={{ borderBottom: "1px solid #9AB1FF", display: "block", width: "100%" }}></span>
          <Typography style={{ justifyContent: "space-between", display: "flex", }}>Or</Typography>
          <span style={{ width: "100%", display: "block", borderBottom: "1px solid #9AB1FF" }}></span>
        </Box>

        <Box>
          <label style={{ position: "relative", display: "inline-block" }} htmlFor="fileUploadInput">
            <Button
              component="span"
              style={{
                borderRadius: "8px",
                width: "150px",
                height: "36px",
                backgroundColor: "#355EE7",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 500,
                fontFamily: "Dm sans",
                textTransform: "none",
                cursor: "pointer",
              }}
            >
              Browse Files
            </Button>
            <input
              style={{
                top: 0,
                position: "absolute",
                left: 0,
                height: "100%",
                width: "100%",
                cursor: "pointer",
                opacity: 0,
              }}
              accept="image/png, image/jpg, image/jpeg, video/mp4, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
              data-test-id="fileUploadInputId"
              multiple
              type="file"
              id="fileUploadInput"
              onChange={this.handleFileChange}
            />
          </label>
        </Box>
      </>
    );
  };

  uploadModalRender = () => {
    const { isSizeExceeded, isWrongFileMimeType } = this.state;

    return (
      <>
        <CustomizeDialog2 data-test-id="customize1" >
          <Box className="uploadBox1">
            <Box style={{ justifyContent: 'space-between', display: 'flex', alignItems: "center" }}>

              <Box className="uploadHeading1" >Upload Files</Box>
              <IconButton
                style={{
                  height: "24px",
                  width: "24px",
                }}
                className="close_dialogue"
                onClick={this.handleClosUploadDialog}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '12px', marginTop: "21px" }}>
              <Box
                className="uploadDragZone"
                data-error={this.getErrorState()}
                data-test-id="uploadDragZoneId"
                onDrop={this.handleDrop}
                id="fileUploadInput"
                onDragOver={this.handleDragOver}
                hidden
              >
                <svg height="44" width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.1245 7.16992V6.04492C16.1245 5.9418 16.0401 5.85742 15.937 5.85742H6.93701C6.83389 5.85742 6.74951 5.9418 6.74951 6.04492V7.16992C6.74951 7.27305 6.83389 7.35742 6.93701 7.35742H15.937C16.0401 7.35742 16.1245 7.27305 16.1245 7.16992ZM6.93701 9.23242C6.83389 9.23242 6.74951 9.3168 6.74951 9.41992V10.5449C6.74951 10.648 6.83389 10.7324 6.93701 10.7324H11.2495C11.3526 10.7324 11.437 10.648 11.437 10.5449V9.41992C11.437 9.3168 11.3526 9.23242 11.2495 9.23242H6.93701ZM11.2495 19.9668H4.87451V3.4668H17.9995V10.4043C17.9995 10.5074 18.0839 10.5918 18.187 10.5918H19.4995C19.6026 10.5918 19.687 10.5074 19.687 10.4043V2.5293C19.687 2.11445 19.3519 1.7793 18.937 1.7793H3.93701C3.52217 1.7793 3.18701 2.11445 3.18701 2.5293V20.9043C3.18701 21.3191 3.52217 21.6543 3.93701 21.6543H11.2495C11.3526 21.6543 11.437 21.5699 11.437 21.4668V20.1543C11.437 20.0512 11.3526 19.9668 11.2495 19.9668Z" fill="currentColor" />
                  <path clipRule="evenodd" fillRule="evenodd" d="M16.8745 13.3105C14.907 13.3105 13.312 14.9055 13.312 16.873C13.312 18.8406 14.907 20.4355 16.8745 20.4355C18.842 20.4355 20.437 18.8406 20.437 16.873C20.437 14.9055 18.842 13.3105 16.8745 13.3105ZM12.187 16.873C12.187 14.2842 14.2857 12.1855 16.8745 12.1855C19.4633 12.1855 21.562 14.2842 21.562 16.873C21.562 19.4619 19.4633 21.5605 16.8745 21.5605C14.2857 21.5605 12.187 19.4619 12.187 16.873Z" fill="currentColor" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.312 16.873C13.312 14.9055 14.907 13.3105 16.8745 13.3105C18.842 13.3105 20.437 14.9055 20.437 16.873C20.437 18.8406 18.842 20.4355 16.8745 20.4355C14.907 20.4355 13.312 18.8406 13.312 16.873ZM19.1007 16.0588L18.3979 16.9373L17.4368 16.1684V18.7481H16.3118V16.1684L15.3507 16.9373L14.6479 16.0588L16.8743 14.2777L19.1007 16.0588Z" fill="currentColor" />
                </svg>
                <DragDropText data-error={this.getErrorState()} component="p" >Drag and drop your videos here to start uploading</DragDropText>

                {!isSizeExceeded && !isWrongFileMimeType && this.renderFileUploadSection()}
              </Box>
            </Box>
            <Box style={{
              justifyContent: "space-between",
              display: "flex",
            }}>
              <Typography className="customStyleUpload">Supported formats: PDF, DOC, XLS, PPT, JPEG, PNG, MP4</Typography>
              <Typography className="customStyleUpload">Maximum file size: 50MB </Typography>
            </Box>
          </Box>

          <Box style={{
            padding: "0px !important",
            overflow: "visible",
            position: "relative",
            top: "4%"
          }} >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <Typography style={{
                fontSize: "14px",
                fontWeight: 700,
                fontFamily: "Dm sans",
                marginBottom: "4px",
              }}>
                Task Folder Files
                <span style={{ color: "red" }}>*</span>
              </Typography >


            </Box>
            <Box >
              <Box>

                <Box style={{
                  position: "relative"
                }}>
                  <TextField
                    onChange={this.handleSearchFiles}
                    placeholder="Search by File Name"
                    data-test-id="search_file"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{
                            color: "DADADA",
                            width: "24px",
                            height: "24px"
                          }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      inputProps: {
                        style: {

                        },
                      },
                    }}
                    value={this.state.searchFiles}
                    className="selectBox"
                    inputProps={{
                      "data-test-id": "search_file",
                      style: {
                        color: "black",
                      },
                    }}
                  />

                </Box>

                <Box style={{
                  border: "1px solid #F0F0F0",
                  width: "873px",
                  height: "240px",
                  padding: "12px",
                  borderRadius: "8px",
                  marginTop: "13px"
                }}>
                  <Box style={{
                    border: "1px solid #9AB1FF",
                    borderRadius: "8px",
                    flexDirection: "column",
                    width: "150px",
                    height: "158px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "5px",
                    padding: "0px 0px 1px 0px",

                  }}> <Typography style={{
                    borderRadius: "8px",
                    width: "126px",
                    border: "1px solid #D9D9D9",
                    height: "110px",
                    background: '#F5F5F5',
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}>
                      <img alt="" style={{
                        width: "55px", height: "55px"
                      }} src={pdfI} />

                    </Typography>
                    <Typography style={{
                      fontWeight: 400,
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "83%"
                    }}>pdf</Typography></Box>
                </Box>
              </Box>
            </Box>
            <Box>

            </Box>
          </Box>
          <Box style={{
            width: "100%",
            top: "6%",
            display: "flex",
            position: "relative",
            justifyContent: "flex-end",
            gap: "5px"
          }}>
            <Button
              onClick={this.handleClosUploadDialog}
              data-test-id="cancelBtn3"
              style={{
                width: "150px",
                border: "1px solid #8C8C8C",
                alignItems: "center",
                height: "44px",
                display: "flex",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: 700,
                fontFamily: "Dm sans",
                color: "#8C8C8C",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              data-test-id="reject1"
              style={{
                backgroundColor: "#F0F0F0",
                borderRadius: "8px",
                width: "150px",
                height: "44px",
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
                fontFamily: "Dm sans",
                textTransform: "none",
                color: "8C8C8C"
              }}
            >
              Save Files
            </Button>
          </Box>

        </CustomizeDialog2>
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainer>
        <ToastContainer />
        <Box style={{ display: "flex", justifyContent: "start", gap: "10px", alignItems: "start" }}>
          <Box className="navigationstyle"  >
            <NavigationMenu navigation={this.props.navigation} id={""} />
          </Box>
          <Box
            className="repsonsive"
          >
            <MainDiv >
              <div className="gridDiv1" >
                {this.renderTaskColumn()}
                <Divider orientation="vertical" flexItem style={{ marginLeft: '16px' }} />
              </div>
              {this.state.taskDrawer && <div className="gridDiv2">
                {this.renderMessagedColumn()}
                <Divider orientation="vertical" flexItem style={{ marginLeft: '16px' }} />
              </div>}
              <div className="gridDiv3" style={!this.state.taskDrawer ? {
                width: "70%"
              } : {}}>
                {this.renderChatScreen()}
              </div>
            </MainDiv>
          </Box>
        </Box>
        {this.state.uploadDialog && this.uploadModalRender()}
      </MainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled('div')({
  backgroundColor: '#fff',
  fontFamily: 'DM Sans',
  "& .repsonsive": {
    height: '100vh',
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 1280px)": {
      padding: '50px',
      height: '100vh',
      overflowY: 'scroll',
      overflowX: 'hidden'
    },
    "@media (max-width: 1366px)": {
      padding: '50px',
      height: '100vh',
      overflowY: 'scroll',
      overflowX: 'hidden'
    }
  },
  "& .uploadBox1": {
    width: '100%',
    display: "flex",
    gap: 12,
    flexDirection: 'column',
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    boxSizing: "border-box",
  },
  "& .uploadHeading1": {
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "23.44px",
    color: "#262626",
  },
});

const MainDiv = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  height: "100vh",
  '@media (max-width: 768px)': {
    gridTemplateColumns: '1fr',
  },
  "& .gridDiv1": {
    display: 'flex',
    width: "30%",
    justifyContent: "space-between"
  },
  "& .gridDiv2": {
    display: 'flex',
    width: "30%",
    justifyContent: "space-between"
  },
  "& .gridDiv3": {
    display: 'flex',
    width: "60%",
    justifyContent: "space-between",
    flexDirection: 'column',
  }
});

const MainTaskColumnDiv = styled('div')({
  paddingTop: "20px",
  width: "100%",
  "& .titleHeader": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "15px",

  },
  "& .maintitle": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 18,
    lineHeight: "14.4px",
    color: "#0F172A",
  },
  "& .filterSection": {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  '& .tasksection': {
    marginTop: "30px",
    border: "1px solid #F0F0F0",
    borderRadius: 8,
    padding: 12,
    marginLeft: "10px",
    height: "calc(100vh - 260px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#F5F5F5",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#91AAFF",
      borderRadius: 10,
    },
  },
  "& .tasklistcontainer": {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    cursor: "pointer",
  },
  "& .listrow": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  "& .messagelistrow": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  "& .msg_desc": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    marginLeft: "10px",
    marginTop: "5px"
  },
  "& .msg_hrs": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    marginLeft: "10px",
    marginBottom: "3px"
  },
  "& .msg_name": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#0F172A",
    marginLeft: "10px"
  },
  "& .msg_count": {
    background: "#355EE7",
    borderRadius: "100px",
    fontWeight: 700,
    fontFamily: "DM Sans",
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#FFFFFF",
    width: "16px",
    padding: "4px",
    textAlign: "center"
  }
})

const StyleTextField = styled(TextField)({
  marginTop: "3px",
  "& .MuiOutlinedInput-input": {
    padding: "12.5px 0 !important"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#C3D1FF !important",
    },
    "&.Mui-error fieldset": {
      borderColor: "#DC2626 !important",
    },

  },
})

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#355EE7',
    color: '#355EE7',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));


const MainChatDiv = styled('div')({
  width: "100%",
  "& .headersection": {
    background: "#F0F0F0",
    padding: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .mainchattitle": {
    fontWeight: 600,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#262626",
  },
  "& .titlesection": {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  }
})

const StyleBadge = styled(Badge)({
  width: 96,
  display: "inline-block",
  borderRadius: 12,
  padding: "4px 0px",
  textAlign: "center",
  fontFamily: "DM Sans",
  fontWeight: 700,
  fontSize: 12,
  lineHeight: "15.62px",
  '&[data-state="digip"]': {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
  },
  '&[data-state="pending"]': {
    backgroundColor: "#DCECFF",
    color: "#355EE7",
  },
  '&[data-state="paid"]': {
    backgroundColor: "#D1FAE5",
    color: "#059669",
  },
});

const StyleBadgeMeet = styled(Badge)({
  width: 160,
  borderRadius: "500px",
  padding: "10px",
  textAlign: "center",
  fontFamily: "DM Sans",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "15.62px",
  color: "#4872FF",
  background: "#ECF1FF",
  display: "flex",
  gap: "6px",
  alignItems: "center"
});

const StyleMessgeDiv = styled("div")({
  flex: 1,
  overflowY: 'auto',
  padding: '25px',
  backgroundColor: '#fafafa',
  height: "calc(100vh - 240px)",
  "& .messagestext": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#0F172A",
  },
  "& .messagedesc": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    marginBottom: '4px',
    textAlign: "end"
  }
})

const FlexDiv = styled('div')({
  display: "flex",
  alignItems: "center",
  gap: "3px",
  "& .username": {
    fontWeight: 600,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#262626",
  },
  "& .countryname": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    display: "flex",
    alignItems: "center",
    gap: "4px"
  }
})

const StyledAccordion = styled(Accordion)({
  '&::before': {
    display: 'none',
  },
});

const StyleTabBox = styled(Box)({
  display: "flex",
  justifyContent: 'start',
  alignItems: 'end',
  paddingLeft: '10px',
  gap: '20px',
  borderBottom: "1px solid #ddd",
  marginTop: '10px'
})

const NoChatDiv = styled('div')({
  backgroundColor: "#FAFAFA",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .nochattext": {
    padding: "15px",
    width: "164px",
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "DM Sans",
    fontSize: 16,
    lineHeight: "14.4px",
    color: "#595959",
  }
})

const NoMessageDiv = styled('div')({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "80px",
  "& .nomessagetext": {
    padding: "15px",
    width: "543px",
    textAlign: "center",
    fontWeight: 600,
    fontFamily: "DM Sans",
    fontSize: 22,
    lineHeight: "14.4px",
    color: "#262626",
  },
  "& .nomessagedesc": {
    padding: "8px",
    width: "543px",
    textAlign: "center",
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 16,
    lineHeight: "14.4px",
    color: "#8C8C8C",
  }
})

const DragDropText = styled(Box)({
  fontSize: 12,
  margin: 0,
  fontFamily: 'DM Sans',
  color: '#434343',
  fontWeight: 500,
  lineHeight: '15.62px',
  '&[data-error="true"]': {
    color: "#DC2626",
  },
})

// Customizable Area End
